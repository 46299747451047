import * as THREE from 'three';
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { TGALoader } from "three/examples/jsm/loaders/TGALoader";
import { JSONLoader } from "./JSONLoader";
import { LASLoader } from "./LASLoader";
import { GLTFLoaderEx } from "./GLTFLoaderEx";


export class LoaderFactory {

  create(type, kind) {

    if (!type) return null;
    type = type.toLowerCase();

    const manager = new THREE.LoadingManager();
    manager.addHandler( /\.tga$/i, new TGALoader() );

    if (['bfo', 'bfox', 'ifc', 'p21', 'sfc', 'dwg', 'dxf'].includes(type)
        || (type === 'xml' && kind === 'LandXML')) {
      return new JSONLoader(manager);
    } else if (['gltf', 'glb'].includes(type)) {
      const loader = new GLTFLoaderEx(manager);
      loader.isModelUpYAxis = true;
      return loader;
    } else if (type === 'fbx') {
      const loader = new FBXLoader(manager);
      loader.isModelUpYAxis = true;
      return loader;
    } else if (type === 'obj') {
      return new OBJLoader(manager);
    } else if (["las", "laz"].includes(type)) {
      return new LASLoader(manager);
    } else if (type === 'stl') {
      return new STLLoader(manager);
    }
    return null;
  }
}
