<template>
  <div ref="filelist" class="filelist" v-show="isOpen" :class="isOpen ? 'open' : null">
    <div class="filelist__inner">
      <div class="filelist__item" v-for="file in item.files" :key="file.file_id">
        <div
          @click="
            showDetail({
              ap_file_id: file.id,
              file_id: file.file_id,
              image_type: file.image_type,
              file_name: file.file_name,
              size: file.size
            })
          "
        >
          <template v-if="file.image_type === POST_IMAGE_TYPE.JPG.value">
            <FileImage :file_id="file.file_id" :name="file.file_name" :cp_around_photograph_id="item.cp_around_photograph_id" />
          </template>
          <template v-if="file.image_type === POST_IMAGE_TYPE.PDF.value">
            <FilePdf :file_id="file.file_id" :name="file.file_name" :cp_around_photograph_id="item.cp_around_photograph_id" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import FileImage from "./FileImage";
import FilePdf from "./FilePdf";
import { POST_IMAGE_TYPE } from "@/util/constants";
export default {
  data: () => {
    return {
      POST_IMAGE_TYPE,
    };
  },
  components: {
    FileImage,
    FilePdf,
  },
  props: {
    item: Object,
    isOpen: Boolean,
},
  watch: {
    isOpen: {
      handler(nval, oval) {
        if (nval !== oval && nval) {
          this.bindOutsideClickEvent();
        }
        if (nval !== oval && !nval) {
          this.removeOutsideClickEvent();
        }
      },
    },
  },
  methods: {
    showDetail({ ap_file_id, file_id, image_type, file_name, size }) {
      this.$store.dispatch("FileViewer/showImages", {
        cp_around_photograph_id: this.item.cp_around_photograph_id,
        files:[
        {
          ap_file_id,
          file_id,
          image_type,
          file_name,
          size,
          type: "file",
        },
      ]});
    },
    bindOutsideClickEvent() {
      document.body.addEventListener("click", this.hitArea);
    },
    removeOutsideClickEvent() {
      document.body.removeEventListener("click", this.hitArea);
    },
    hitArea(e) {
      const pointerPosition = {
        x: e.clientX,
        y: e.clientY,
      };
      const rect = this.$refs["filelist"].getBoundingClientRect();
      if (
        !(
          rect.left < pointerPosition.x &&
          rect.top < pointerPosition.y &&
          rect.left + rect.width > pointerPosition.x &&
          rect.top + rect.height > pointerPosition.y
        )
      ) {
        this.$emit("onClose");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filelist {
/*  position: absolute;
  top: 64px;
  bottom: 64px;
  right: -256px;
  width: 256px;
  height: calc(100% - 128px);*/
  box-sizing: border-box;
  background-color: white;
  //transform: translateX(0);
  //transition: all 0.2s ease-in-out;
  //z-index: 1;
  //
  //&.open {
  //  transform: translateX(-256px);
  //}
}

.filelist__inner {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: left;
}

.filelist__item {
  display: inline-block;
  width: 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 8px;
}
</style>
