import {Group} from 'three';
import {proj4} from '@/util/proj4.js';

/**
 * 四捨五入して{base}に指定した小数点以下の桁数とする
 * @param {Number} value 値
 * @param {Number} base 小数点以下の桁数
 * @returns Number 四捨五入した値
 */
const round = (value, base) => {
  const p = Math.pow(10, base);
  return Math.round(value * p) / p;
};

/**
 * 地理座標でモデル配置するためのグループ
 */
class GeographicGroup extends Group {

  /**
   * コンストラクタ
   * @constructor
   */
  constructor() {
    super();
    this.isGeographicGroup = true;
  }

  addBase(base) {
    super.add(base);
    this._base = base;
  }

  /**
   * モデル追加
   * @param {Model3D} object
   */
  add(object) {
    super.add(object);
  }

  /**
   * 各モデルの位置を更新
   */
  updatePositions() {
    this._computePosition(this._base, this.children);
  }

  /**
   * 座標の計算
   * @param base 座標の基準となるモデル
   * @param objs {Array} 計算対象の3Dモデル
   * @returns {null|{east: *, north: *}}
   */
  _computePosition(base, objs) {
    if (base && base.isModel3D && base.longitude && base.latitude) {
      const baseEpsgKey = base.planeRectCoordSystem?.key || "EPSG:6676";
      const baseCoords = proj4("EPSG:6668", baseEpsgKey, [base.longitude, base.latitude]);
      if (baseCoords) {
        objs.forEach(obj => {
          if (obj !== base && obj.isModel3D && obj.longitude && obj.latitude) {
            const coords = proj4("EPSG:6668", baseEpsgKey, [obj.longitude, obj.latitude]);
            const east = round(coords[0] - baseCoords[0], 6);
            const north = round(coords[1] - baseCoords[1], 6);
            obj.position.set(east, 0, -north);
            obj.updateMatrixWorld();
          }
        });
      }
    }
  }
}

export {GeographicGroup};
