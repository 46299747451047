<template>
  <div class="popup-info">
    <div v-show="showFlg" id="popup-info" ref="popup-info" class="background">
      <div class="popup elevation-2">
        <div class="popup-info__header">
          <div class="header__title">
            <div class="title-username">記入者：{{ writername }}</div>
            <div class="title-date">登録日：{{ createAt }}</div>
          </div>
          <!-- 指摘・連絡事項 -->
          <div class="header__indication flex-shrink-1 flex-grow-1">
            <div v-for="(item, index) in CONST.INDICATION_INFO" :key="index">
              {{ item.name }}
              <input
                name="indication"
                :value="item.value"
                type="radio"
                :checked="index === indication ? 'checked' : ''"
                @change="setIndication(index)"
              />
            </div>
          </div>
          <!-- 緊急度 -->
          <div class="header__selectbox">
            <div class="header__selectbox-item-wrap">
              緊急度
              <select
                class="header__selectbox-option"
                @change="setUrgency($event.target.selectedIndex)"
              >
                <option value="-1" hidden>選択してください</option>
                <option
                  v-for="(item, index) in CONST.INDICATION_URGENCY"
                  :value="item.value"
                  :key="index"
                  :selected="index === urgency"
                >
                  {{ item.name }}
                </option>
              </select>
              <div v-if="msg_urgency" class="selectbox-urgency__alert-message">
                {{ msg_urgency }}
              </div>
            </div>
            <v-spacer/>
            <!-- ステータス -->
            <div class="header__selectbox-item-wrap">
              ステータス
              <select
                class="header__selectbox-option"
                @change="setCorrect($event.target.selectedIndex)"
              >
                <option value="-1" hidden>選択してください</option>
                <option
                  v-for="(item, index) in CONST.INDICATION_CORRECT"
                  :value="item.value"
                  :key="index"
                  :selected="index === correct"
                >
                  {{ item.name }}
                </option>
              </select>
              <div v-if="msg_correct" class="selectbox-correct__alert-message">
                {{ msg_correct }}
              </div>
            </div>
          </div>
          <v-spacer/>

          <!-- 閉じるボタン -->
          <TooltipBtn label="閉じる">
            <div class="header__close">
              <v-icon class="close-icon" color="black" @click="close">fa-regular fa-times</v-icon>
            </div>
          </TooltipBtn>
        </div>
        <div class="popup-info__header-bottom"></div>

        <div class="popup-info__body">
          <!-- 添付画像 -->
          <div>
            <div v-show="images.length === 0" class="body__card-imgs-area">
              <div class="body__card-img-area"></div>
            </div>
            <div
              v-show="images.length > 0"
              ref="body__card-imgs-area"
              class="body__card-imgs-area align-content-end"
            >
              <div
                v-for="(item, index) in images"
                :key="index"
                :value="item"
                class="body__card-img-area"
                @mouseover="showCloseBtn(index)"
                @mouseleave="hideCloseBtn(index)"
              >
                <img
                  :class="
                    item.imgWidthGtHeightFlg
                      ? 'body__card-img-item--leading-width'
                      : 'body__card-img-item--leading-height'
                  "
                  :src="item.image"
                />

                <div v-if="item.showCloseBtnFlg" class="body__card-img-close">
                  <TooltipBtn label="削除">
                    <div class="close-icon__wrapper">
                      <v-icon class="close-icon" color="white" @click="delImage(index)">fa-regular fa-times</v-icon>
                    </div>
                  </TooltipBtn>
                </div>
              </div>
            </div>
            <div class="add-file__area">
              <ButtonFileUpload
                v-if="showFlg"
                :id="'popupinfo'"
                :type="BTN_OPTION.SQUARE_TYPE.LINK"
                label="追加する"
                accept="image/jpg"
                :isDisabled="images.length >= 5"
                @onClick="addImage"
              />
            </div>
          </div>
          <div class="body__center-vertical"></div>
          <!-- 内容 -->
          <div>
            <div class="body__comment-area">
              <textarea
                class="body__comment"
                name="comment"
                v-model="comment"
                placeholder="内容"
              />
              <div v-if="msg_comment" class="comment__alert-message">
                {{ msg_comment }}
              </div>
            </div>
          </div>
        </div>

        <div class="popup-info__footer">
          <div class="footer__buttons">
            <button hidden :id="html.ID_CLOSE" class="button" />
            <button hidden :id="html.ID_SUBMIT" class="button" />
            <div v-if="newInfoFlg">
              <ButtonSquare
                :label="'登録'"
                :type="BTN_OPTION.SQUARE_TYPE.POSITIVE_BLUE"
                :isDisabled="!enableSubmitFlg"
                @onClick="registIndication"
              />
            </div>
            <template v-else>
              <div class="footer__buttos-finish">
                <ButtonSquare
                  :label="'完了'"
                  :type="BTN_OPTION.SQUARE_TYPE.POSITIVE_BLUE"
                  :isDisabled="!enableSubmitFlg"
                  @onClick="updateIndication"
                />
              </div>
              <div>
                <ButtonSquare
                  :label="'削除'"
                  :type="BTN_OPTION.SQUARE_TYPE.POSITIVE_RED"
                  :isDisabled="!enableSubmitFlg"
                  @onClick="deleteIndication"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { format } from "date-fns";
import {
  isProduction,
  URL_LOCALHOST,
  URL_PRODUCTION,
  INDICATION_INFO,
  INDICATION_URGENCY,
  INDICATION_CORRECT,
} from "@/util/constants.js";
import { close, mail } from "@/util/icon.js";
import * as Uimg from "@/util/img.js";
import * as Uobj from "@/util/obj.js";
import { MAIL_INDICATION } from "@/util/constmail.js";
import { makeMail } from "@/util/mail.js";
import { bindNest } from "@/util/str.js";
import SingleSubmit from "@/components/controller/SingleSubmit.vue";
import ButtonFileUpload from "@/components/button/ButtonFileUpload.vue";
import { SQUARE_TYPE } from "@/components/button/ButtonFileUpload.vue";
import ButtonSquare from "@/components/button/ButtonSquare.vue";
import TooltipBtn from "../tooltip/TooltipBtn.vue";

export default {
  components: { SingleSubmit, ButtonFileUpload, ButtonSquare, TooltipBtn },
  data: () => {
    return {
      icon: { close, mail },
      CONST: {
        INDICATION_INFO,
        INDICATION_URGENCY,
        INDICATION_CORRECT,
        IMAGE: { WIDTH: 400, HEIGHT: 300 },
      },
      BTN_OPTION: { SQUARE_TYPE },
      VALIDATE: {
        urgency: {
          required: {
            message: "必須入力です",
          },
        },
        correct: {
          required: {
            message: "必須入力です",
          },
        },
        comment: {
          length: {
            maxLength: 20000,
            message: "内容は20000文字以下で入力してください",
          },
        },
      },
      html: {
        ID_CLOSE: "popupInfoBtnClose",
        ID_SUBMIT: "popupInfoBtnSubmit",
      },
      showFlg: false, // モーダル表示
      newInfoFlg: true, // 指摘・連絡 新規フラグ
      watchCnt: 0,
      enableSubmitFlg: true,
      // パラメータ
      infomation: null,
      ids: null,
      // フィールド
      writername: "", // 記入者 // TODO 仕様未確定 Phase3以降
      createAt: "‐", // 登録日
      images: [], // 添付画像
      indication: 0, // 指摘・連絡事項
      urgency: -1, // 緊急度
      correct: -1, // ステータス（是正）
      comment: "", // 内容
      // バリデート
      msg_urgency: null,
      msg_correct: null,
      msg_comment: null,
    };
  },
  props: {},
  mounted() {},
  methods: {
    // 画面遷移
    close() {
      let cancel = document.getElementById(this.html.ID_CLOSE);
      cancel.click();
    },
    async call(infomation, ids) {
console.log("information called.");
      // 定数、および変数の置換
      let res = { result: false };
      if (!this.hasIds(ids)) return res;
      this.ids = ids;
      this.setInfo(infomation);
      this.showFlg = true;
      // ポップアップのボタンが押下されるまで待機
      const awaitForClick = () => {
        return new Promise((resolve) => {
          const listener = resolve;
          let add = document.getElementById(this.html.ID_SUBMIT);
          add.addEventListener("click", listener, { once: true });
          let cancel = document.getElementById(this.html.ID_CLOSE);
          cancel.addEventListener("click", listener, { once: true });
        });
      };
      // ポップアップを閉じる
      let result = await awaitForClick();
      if (result.target.id === this.html.ID_SUBMIT) {
        res.result = true;
        this.init();
      } else {
        res.result = false;
        this.init();
      }
      if (this.res) {
        res.action = this.res.action;
        res.data = this.res.res;
      }
      return res;
    },
    // 初期化
    init() {
      this.msg_urgency = null;
      this.msg_correct = null;
      this.msg_comment = null;
      this.enableSubmitFlg = true;
      this.watchCnt = 0;
      this.showFlg = false;
      this.writername = "";
      this.createAt = "ー";
      this.indication = 0;
      this.urgency = -1;
      this.correct = -1;
      this.comment = "";
      this.images = [];
    },
    // 項目設定
    async setInfo(infomation) {
      this.infomation = _.cloneDeep(infomation);
      // 新規作成フラグ
      if (infomation["id"]) {
        this.newInfoFlg = false;
      } else {
        this.newInfoFlg = true;
      }
      // 記入者
      this.writername = infomation["created_by"];

      // 登録日
      let createAt = infomation["created_at"];
      if (createAt) {
        this.createAt = format(new Date(createAt), "yyyy-MM-dd");
      }
      // 添付写真
      let fileIds = infomation["file_ids"];
      if (Uobj.hasList(fileIds)) {
        for (let index = 0; index < fileIds.length; index++) {
          let file_id = fileIds[index];
          let cp_around_photograph_id = infomation['cp_around_photograph_id']
          let image = "";
          let image_type = "";
          let res = await this.getApFileImage(file_id, cp_around_photograph_id);
          if (res) {
            image = res["image"];
            image_type = res["image_type"];
          }
          let imgWidthGtHeightFlg = await Uimg.isWidthGtHeight(
            image,
            this.CONST.IMAGE.WIDTH,
            this.CONST.IMAGE.HEIGHT
          );
          this.images.push({
            file_id,
            imgWidthGtHeightFlg,
            image_type,
            image,
            showCloseBtnFlg: false,
          });
        }
      }
      // その他
      if (!_.isNil(infomation["indication"]))
        this.indication = infomation["indication"];
      if (!_.isNil(infomation["urgency"])) this.urgency = infomation["urgency"];
      if (!_.isNil(infomation["correct"])) this.correct = infomation["correct"];
      if (!_.isNil(infomation["comment"])) this.comment = infomation["comment"];
    },
    // バリデート
    hasIds(ids) {
      let checkFlg = Uobj.hasKey(
        ids,
        "construction_site_id",
        "zumen_id",
        "captcha_point_id"
      );
      return checkFlg;
    },
    isValidAll() {
      let validFlg = this.isValidUrgency();
      validFlg = this.isValidCorrect() && validFlg;
      validFlg = this.isValidComment() && validFlg;
      return validFlg;
    },
    isValidUrgency() {
      let validFlg = true;
      if (this.urgency == -1) {
        this.msg_urgency = this.VALIDATE.urgency.required.message;
        validFlg = false;
      } else {
        this.msg_urgency = null;
      }
      return validFlg;
    },
    isValidCorrect() {
      let validFlg = true;
      if (this.correct == -1) {
        this.msg_correct = this.VALIDATE.correct.required.message;
        validFlg = false;
      } else {
        this.msg_correct = null;
      }
      return validFlg;
    },
    isValidComment() {
      let validFlg = true;
      if (this.comment.length > this.VALIDATE.comment.length.maxLength) {
        this.msg_comment = this.VALIDATE.comment.length.message;
        validFlg = false;
      } else {
        this.msg_comment = null;
      }
      return validFlg;
    },
    // メール：リンク共有
    mailContact() {
      let makeUrl = () => {
        let url = "";
        if (!this.ids) return url;
        let construction_site_id = this.ids["construction_site_id"]; // 現場ID
        let zumen_id = this.ids["zumen_id"]; // 図面ID
        let captcha_point_id = this.ids["captcha_point_id"]; // 撮影ポイントID
        let cp_around_photograph_id = this.infomation[
          "cp_around_photograph_id"
        ]; // 360°画像ID
        let ap_infomation_id = this.infomation["id"]; // 360°画像情報ID

        const port = location.port ? ":" + location.port : "";
        const baseURL =
          (isProduction ? URL_PRODUCTION : URL_LOCALHOST + port) + "/";
        let getPrm =
          `&construction_site_id=${construction_site_id}&zumen_id=${zumen_id}` +
          `&captcha_point_id=${captcha_point_id}&cp_around_photograph_id=${cp_around_photograph_id}` +
          `&ap_infomation_id=${ap_infomation_id}`;
        url =
          `${baseURL}` + `?getCaptchaPoint=true` + encodeURIComponent(getPrm);
        return url;
      };

      let comment = "";
      if (!_.isNil(this.comment)) {
        comment = this.comment.replaceAll("&", "＆");
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          makeMail(
            bindNest(MAIL_INDICATION, {
              body: { $$url: makeUrl(), $$message: comment },
            })
          );
          resolve();
        }, 1000);
      });
    },
    // 追加：画像
    async addImage(param) {
      if (!Uobj.getNest(param, "data")) {
        alert("ファイルが取得できませんでした");
        return;
      }
      if (Uobj.getNest(param, "size_over_flg")) {
        alert("ファイルサイズは5MB以下にしてください");
        return;
      }
      let imgWidthGtHeightFlg = await Uimg.isWidthGtHeight(
        param.data,
        this.CONST.IMAGE.WIDTH,
        this.CONST.IMAGE.HEIGHT
      );
      this.images.push({
        file_id: null,
        imgWidthGtHeightFlg,
        image: param.data,
        size: param.size,
        showCloseBtnFlg: false,
      });
      this.scrollToEnd();
    },
    scrollToEnd() {
      let ref = this.$refs["body__card-imgs-area"];
      this.$nextTick(() => {
        ref.scrollTop = ref.scrollHeight;
      });
    },
    // 削除：画像
    delImage(index) {
      this.images.splice(index, 1);
      this.$store.dispatch("Tooltip/close");
    },
    // 表示：画像削除ボタン
    showCloseBtn(index) {
      this.images[index]["showCloseBtnFlg"] = true;
    },
    // 非表示：画像削除ボタン
    hideCloseBtn(index) {
      this.images[index]["showCloseBtnFlg"] = false;
    },
    // 設定：指摘・連絡事項
    setIndication(index) {
      this.indication = this.CONST.INDICATION_INFO[index].value;
    },
    // 設定：緊急度
    setUrgency(index) {
      this.urgency = this.CONST.INDICATION_URGENCY[index - 1].value;
      this.isValidUrgency();
    },
    // 設定：緊急度
    setCorrect(index) {
      this.correct = this.CONST.INDICATION_CORRECT[index - 1].value;
      this.isValidCorrect();
    },
    // 登録：添付
    async registAttachedFiles() {
      let file_ids = [];
      if (Uobj.hasList(this.images)) {
        for (let index = 0; index < this.images.length; index++) {
          let image = this.images[index];
          let file_id = image["file_id"];
          if (!file_id) {
            let res = await this.postApFileImage(this.images[index]);
            file_id = res["file_id"];
            // 登録中断：APIがエラーを返した場合
            if (!res) {
              this.enableSubmitFlg = true;
              return;
            }
          }
          file_ids.push(file_id);
        }
      }
      return file_ids;
    },
    // 登録：指摘・連絡事項
    async registIndication() {
      if (!this.isValidAll()) return;
      this.enableSubmitFlg = false;
      let file_ids = await this.registAttachedFiles();
      let res = await this.postApInfo(file_ids);
      this.res = { action: "add", res };
      if (!res) {
        this.enableSubmitFlg = true;
        return;
      }
      let submit = document.getElementById(this.html.ID_SUBMIT);
      submit.click();
      this.enableSubmitFlg = true;
      await this.storeCaptchaPointUpdate();
    },
    // 更新：指摘・連絡事項
    async updateIndication() {
      if (!this.isValidAll()) return;
      this.enableSubmitFlg = false;
      let file_ids = await this.registAttachedFiles();
      let res = await this.postUpdateApInfo(file_ids);
      this.res = { action: "update", res };
      if (!res) {
        this.enableSubmitFlg = true;
        return;
      }
      let submit = document.getElementById(this.html.ID_SUBMIT);
      submit.click();
      this.enableSubmitFlg = true;
      // await this.storeCaptchaPointUpdate();
    },
    // 削除：指摘・連絡事項
    async deleteIndication() {
      this.enableSubmitFlg = false;
      if (window.confirm("指摘・連絡事項を削除しますか？")) {
        let res = await this.deleteApInfo();
        this.res = { action: "delete", res };
        if (!res) {
          this.enableSubmitFlg = true;
          return;
        }
        let submit = document.getElementById(this.html.ID_SUBMIT);
        submit.click();
        this.enableSubmitFlg = true;
        await this.storeCaptchaPointUpdate();
      }
    },
    // API取得：画像
    async getApFileImage(file_id, cp_around_photograph_id) {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = { api_token, file_id, cp_around_photograph_id };
      let res = await this.$store.dispatch("ViewImageApi/getApFileImage", params);
      if (res["status"] != 200) return "";
      return res["data"];
    },
    // API登録：画像
    async postApFileImage(image) {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = {
        api_token,
        cp_around_photograph_id: this.infomation["cp_around_photograph_id"],
        point_x: this.infomation["point_x"],
        point_y: this.infomation["point_y"],
        point_z: this.infomation["point_z"],
        image_type: image["image_type"],
        image: image["image"],
      };
      let res = await this.$store.dispatch("ApDetailApi/postApPhoto", params);
      if (res["status"] != 200) return null;
      return res["data"];
    },
    // API登録：指摘・連絡事項
    async postApInfo(file_ids) {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = {
        api_token,
        cp_around_photograph_id: this.infomation["cp_around_photograph_id"],
        title: "title",
        comment: this.comment,
        point_x: this.infomation["point_x"],
        point_y: this.infomation["point_y"],
        point_z: this.infomation["point_z"],
        indication: this.indication,
        urgency: this.urgency,
        correct: this.correct,
        file_ids,
        created_by: this.infomation["created_by"],
      };
      let res = await this.$store.dispatch("ApDetailApi/postApInfo", params);
      if (res["status"] != 200) return null;
      return res["data"];
    },
    // API更新：指摘・連絡事項
    async postUpdateApInfo(file_ids) {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = {
        api_token,
        ap_infomation_id: this.infomation["id"],
        cp_around_photograph_id: this.infomation["cp_around_photograph_id"],
        title: "title",
        comment: this.comment,
        point_x: this.infomation["point_x"],
        point_y: this.infomation["point_y"],
        point_z: this.infomation["point_z"],
        indication: this.indication,
        urgency: this.urgency,
        correct: this.correct,
        file_ids,
      };
      let res = await this.$store.dispatch("ApDetailApi/postUpdateApInfo", params);
      if (res["status"] != 200) return null;
      return res["data"];
    },
    // API削除：指摘・連絡事項
    async deleteApInfo() {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = {
        api_token,
        ap_infomation_id: this.infomation["id"],
        cp_around_photograph_id: this.infomation["cp_around_photograph_id"],
      };
      let res = await this.$store.dispatch("ApDetailApi/deleteApInfo", params);
      if (res["status"] != 200) return null;
      return res["data"];
    },
    // API取得：撮影ポイントを再読み込み
    async storeCaptchaPointUpdate() {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = {
        api_token,
        zumen_id: this.ids["zumen_id"],
        captcha_point_id: this.ids["captcha_point_id"],
      };
      const response = await this.$store.dispatch(
        "CaptchaPointApi/getCaptchaPoint",
        params
      );
      if (response.result == false) {
        alert(response.message);
        return;
      }
    },
  },
  watch: {
    comment: {
      deep: true,
      immediate: false,
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue && this.watchCnt > 0) {
          this.validFlg = this.isValidComment();
        }
        this.watchCnt++;
      },
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";

.popup-info {
  @mixin alert-message {
    height: 16px;
    color: $col-alert;
    font-size: $font-size-20;
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup {
    width: 735px;
    height: 532px;
    padding: 16px;
    border-radius: 4px;
    background-color: white;
    font-size: $font-size-40;

    .popup-info__header {
      display: flex;
      .header__title {
        width: 140px;
        text-align: left;
        margin-right: $margin-10;
        .title-username {
          font-size: $font-size-30;
          margin-bottom: 4px;
          font-weight: bold;
        }
        .title-date {
          font-size: $font-size-20;
        }
      }
      .header__indication {
        margin-right: $margin-10;
        font-size: $font-size-30;
        font-weight: bold;
      }
      .header__selectbox {
        display: flex;
        justify-content: space-between;
        width: 380px;
        padding: $padding-15 $padding-10 $padding-5 0;
        font-size: $font-size-30;
        font-weight: bold;
        //border-right: 1px solid $col-border;
        .header__selectbox-item-wrap {
          position: relative;
          .selectbox-urgency__alert-message {
            @include alert-message;
            position: absolute;
            font-weight: normal;
            top: 22px;
            left: 50px;
          }
          .selectbox-correct__alert-message {
            @include alert-message;
            position: absolute;
            font-weight: normal;
            top: 22px;
            left: 78px;
          }
        }
        .header__selectbox-option {

          background-color: white !important;
          font-size: calc(40px / 3);
          font-weight: 400;
          color: #666;
          text-align: -webkit-center;
          appearance: none;
          min-width: 72px;
          height: 22px;
          border-style: solid;
          border-width: 1px;
          border-radius: 8px;
          padding: 2px 6px;
          margin: 0 2px;
          outline: none;
          //デフォルトの矢印を消す
          -webkit-appearance: none;
          -moz-appearance: none;
          &:hover {
            color: #000;
            cursor: pointer;
          }
        }
      }
      .header__email {
        width: 38px;
        padding: 12px 8px 8px 8px;
        border-right: 1px solid $col-border;
        .email-icon {
          height: 18px;
          width: auto;
          cursor: pointer;
        }
      }
      .header__close {
        .close-icon {
          height: 16px;
          width: 16px;
          cursor: pointer;
        }
      }
    }
    .popup-info__header-bottom {
      height: 10px;
      margin-bottom: $margin-20;
      border-bottom: 1px solid $col-border;
    }

    .popup-info__body {
      display: flex;
      margin-bottom: $margin-15;
      .body__card-imgs-area {
        min-height: 340px;
        max-height: 340px;
        overflow-y: scroll;
      }
      .body__card-img-area {
        position: relative;
        width: 400px;
        height: 300px;
        border: 1px solid $color-base-25;
        background: $color-base-30;
        margin-bottom: $margin-10;
        margin-right: $margin-20;
        .body__card-img-item--leading-width {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 100%;
          height: auto;
        }
        .body__card-img-item--leading-height {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 100%;
          width: auto;
        }
        .body__card-img-close {
          position: absolute;
          top: 0;
          right: 0;
          .close-icon__wrapper {
            padding: 4px 8px 4px 8px;
            .close-icon {
              height: 16px;
              width: 16px;
            }
            color: white;
            background-color: rgba(0,0,0,0.5);
          }
        }
      }

      .body__center-vertical {
        min-height: 374px;
        max-height: 374px;
        border-right: 1px solid $color-base-30;
        margin-right: $margin-20;
      }

      .body__comment-area {
        position: relative;
        .body__comment {
          max-height: 368px;
          min-height: 368px;
          min-width: 260px;
          max-width: 260px;
          border: 1px solid $color-base-25;
          border-radius: 4px;
          outline: none;
          padding: 2px;
          margin: 0;
          resize: none;
        }
        .comment__alert-message {
          @include alert-message;
          position: absolute;
          top: 374px;
        }
      }
    }
    .popup-info__footer {
      .footer__buttons {
        display: flex;
        justify-content: flex-end;
        .footer__buttos-finish {
          margin-right: $margin-15;
        }
      }
    }
  }

  .body {
    padding: $padding-20 0;
  }

  .inner {
    display: flex;
    justify-content: space-around;
    padding: $padding-20 0;
  }

  .input {
    font-size: $font-size-30;
    padding: 4px;
    width: 100%;
    border: 1px solid;
    transition: all 0.2s ease-in;
    border-bottom: 1px solid $col-border;
    &:focus {
      background-color: #f8f8f8;
    }
  }
  .add-file__area {
    display: flex;
    justify-content: center;
    padding: $padding-10 0;
    margin: 0 $padding-20 0 0;
    font-size: $font-size-20;
  }

  .button {
    border: none;
    background: none;
    font-size: 14px;
    color: $col-btn-positive;
    margin-left: 8px;
    min-width: 120px;
  }
}
</style>
