<template>
  <div class="tooltip_wrapper">
    <div class="tooltip inner"
      :class="isShow?'show':null"
      :style="getPosition"
    >
      <div class="message">{{message}}</div>
    </div>
  </div>
</template>
<script>

export default {
  computed:{
    isShow(){
      return this.$store.getters["Tooltip/getIsShow"];
    },
    message(){
      return this.$store.getters["Tooltip/getMessage"];
    },
    getPosition(){
      return this.$store.getters["Tooltip/getPosition"];
    },
  }
}
</script>
<style lang="scss" scoped>
.tooltip_wapper{
  position: fixed;
  z-index: 9999;
  width:100vw;
  height:100vh;
  pointer-events: none;
}
.tooltip {
  font-size:8px;
  padding:4px 8px;
  background:#eee;
  border-radius: 4px;
  display:inline-block;
  pointer-events: none;
  opacity:0;
  position: fixed;
  z-index: 9999;
  transition: opacity .3s ease-in-out;
  &.show{
    opacity: 1;
  }
}
</style>
