<template>
  <div>
    <input
      :ref="'file-upload_' + id"
      style="display:none"
      type="file"
      :accept="accept"
      @change="encodeFile"
    />
    <button :class="'square--' + type" :disabled="isDisabled" @click="btnClick">
      {{ label }}
    </button>
  </div>
</template>

<script>
import { MAX_FILE_SIZE } from "@/util/constants.js";

export const SQUARE_TYPE = {
  LINK: "link",
  NEGATIVE: "negative",
  POSITIVE_BLUE: "positive--blue",
  POSITIVE_RED: "positive--red",
};

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "positive",
    },
    label: {
      type: String,
      default: "ファイルを選択",
    },
    accept: {
      type: String,
      default: "",
    },
  },
  methods: {
    btnClick(e) {
      this.$refs["file-upload_" + this.id].click();
    },
    clear() {
      this.$refs["file-upload_" + this.id].value = "";
    },
    async encodeFile(e) {
      const file = e.target.files[0] || {};
      console.log("- file:", file);

      this.clear();

      function getBase64(file) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = (ev) => resolve(ev.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsDataURL(file);
        });
      }

      let base64;
      try {
        base64 = await getBase64(file);
      } catch (e) {
        base64 = null;
      }
      const chkFileSizeOver = async (file) => {
        if (!file || file.size >= MAX_FILE_SIZE * 1000) {
          return true;
        } else {
          return false;
        }
      };
      let size_over_flg = await chkFileSizeOver(file);
      this.$emit("onClick", {
        data: base64,
        type: file.type,
        name: file.name,
        size: file.size,
        size_over_flg,
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";
@import "./button.scss";
@import "./button-square.scss";

.square--link {
  @include global-button;
  @include link-button();
}

.square--negative {
  @include global-button;
  @include square-button($color-base-25);
}

.square--positive--blue {
  @include global-button;
  @include square-button($col-btn-positive);
}

.square--positive--red {
  @include global-button;
  @include square-button($col-btn-advice);
}

.disabled {
  opacity: 0.5;
}
</style>
