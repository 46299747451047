import * as _ from "lodash";
import api from "@/api/api.js";

export const LoginApi = {
  namespaced: true,

  state: {
    login: {},
  },
  mutations: {
    LOGIN(state, payload) {
      state.login = _.cloneDeep(payload);
    },
  },
  actions: {
    async login(context, payload) {
      const service = "login";

      console.log("- API:", service, " prm:", payload);
      let response = await api.post(service, payload);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data; // API Original DATA
        context.commit("LOGIN", response.data.data);
      } else {
        res["data"] = response.data; // API Original DATA
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getStateLogin: (state) => {
      return state.login;
    },
    getApiToken: (state) => {
      return state.login["api_token"];
    },
  },
};
