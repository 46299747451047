<template>
  <div ref="eventPanel" class="eventPanel">
    <TooltipBtn label="指摘連絡追加">
      <button class="btn" @click.stop="onClick($event, EVENT_TYPE.NEW)">
        <v-icon color="black" size="20">fa-regular fa-comment-plus</v-icon>
      </button>
    </TooltipBtn>
    <div class="spacer" />
    <TooltipBtn label="指摘連絡一覧">
      <button class="btn" @click.stop="onClick($event, EVENT_TYPE.INDICATION)">
        <v-icon color="black" size="20">fa-regular fa-clipboard-list</v-icon>
      </button>
    </TooltipBtn>
    <div class="spacer" />
   <div class="spacer" />
    <TooltipBtn label="ファイル追加">
      <button class="btn" @click.stop="onClick($event, EVENT_TYPE.CAMERA)">
        <v-icon color="black" size="20">fa-regular fa-camera</v-icon>
      </button>
    </TooltipBtn>
    <div class="spacer" />
    <TooltipBtn label="ファイル一覧">
      <button class="btn" @click.stop="onClick($event, EVENT_TYPE.FILE)">
        <v-icon color="black" size="20">fa-regular fa-rectangle-vertical-history</v-icon>
      </button>
    </TooltipBtn>
    <div class="spacer" />
    <TooltipBtn v-if="!isViewPlane" label="3Dモデル">
      <button class="btn" @click.stop="onClick($event, EVENT_TYPE.MODEL)">
        <v-icon color="black" size="20">fa-regular fa-cubes</v-icon>
      </button>
    </TooltipBtn>
    <div v-if="!isViewPlane" class="spacer" />
    <TooltipBtn v-if="!isViewPlane" label="方位調整">
      <button class="btn" @click.stop="onClick($event, EVENT_TYPE.NORTH)">
        <v-icon color="black" size="20">fa-regular fa-location-dot</v-icon>
      </button>
    </TooltipBtn>

  </div>
</template>
<script>
import {PANEL_STATUS, ViewType} from "@/util/constants";
import TooltipBtn from "@/components/tooltip/TooltipBtn.vue";

const EVENT_TYPE = {
  NEW: "new",
  INDICATION: "indication",
  NOTE: "note",
  CAMERA: "camera",
  FILE: "file",
  MODEL: "model",
  NORTH: "north",
  DELETE: "delete",
};
export default {
  data: () => {
    return {
      EVENT_TYPE,
    };
  },
  components: {
    TooltipBtn,
  },
  props: {
    isViewPlane: {
      type: Boolean,
    },
  },
  methods: {
    onClick(e, type) {
      e.stopPropagation();
      if (type === EVENT_TYPE.NEW)
        this.$emit("onChangePanelStatus", PANEL_STATUS.INDICATION);
      if (type === EVENT_TYPE.INDICATION)
        this.$emit("openIndicationList")
      if (type === EVENT_TYPE.CAMERA)
        this.$emit("onChangePanelStatus", PANEL_STATUS.FILEUPLOAD);
      if (type === EVENT_TYPE.FILE)
        this.$emit("openFileList", PANEL_STATUS.FILELIST);
      if (type === EVENT_TYPE.MODEL)
        this.$emit("openModelList");
      if (type === EVENT_TYPE.NORTH)
        this.$emit("onChangePanelStatus", PANEL_STATUS.ADJUST_NORTH);
      if (type === EVENT_TYPE.DELETE)
        this.EventDeletePicture();
    },
    //画像を削除
    EventDeletePicture() {
      const result = window.confirm(
        "削除しますか？※この操作は取り消せません。"
      );
      if (result) {
        this.$emit("onDelete");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.eventPanel {
  position: absolute;
  bottom: 128px;
  right: 64px;
  width: 32px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .btn {
    padding: 0;
    margin: 8px 0;
    background: none;
    border: none;
    width: inherit;
    opacity: 0.6;
    outline: none;

    img {
      width: 60%;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:disabled {
      opacity: 0.2;
      &:hover {
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  .spacer {
    height: 1px;
    width: 60%;
    margin: 0 20%;
    border-top: 1px solid #ccc;
  }
}
</style>
