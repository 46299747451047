/**
 *
 */
export const FileViewer = {
  namespaced: true,
  state: {
    isShow: false,
    file_id: null,
    image_type: null,
    file_name: null,
    delete_file_id: null,
    ap_file_id: null,
    cp_around_photograph_id: null,
    files: [],
  },
  mutations: {
    SHOW_IMAGES(state, params) {
      state.cp_around_photograph_id = params.cp_around_photograph_id;
      state.files = params.files.map((file) => {
        return {
          ...file,
        };
      });
      state.isShow = params.isShow;
    },
    DELETE(state, { file_id }) {
      state.delete_file_id = file_id;
    },
    CLOSE(state) {
      state.cp_around_photograph_id = null;
      state.files = [];
      state.isShow = false;
    },
  },
  actions: {
    showImages(context, payload) {
      const filetypes = payload.files.filter((file) => file.type === "file");
      let param = { isShow: !!filetypes.length, ...payload };
      context.commit("SHOW_IMAGES", param);
    },
    deleteImage(context, payload) {
      context.commit("DELETE", payload);
    },
    close(context) {
      context.commit("CLOSE");
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getIsShow: (state) => {
      return state.isShow;
    },
    getDeleteFileId: (state) => {
      return state.delete_file_id;
    },
  },
};
