<template>
  <div class="modal-map">
    <transition name="fade">
      <div v-show="showModalFlg" class="background" @click.self="close">
        <div class="popup">
          <div class="modal-map_body">
            <div>
              <!-- タブ -->
              <div class="modal-map_tab-wrapper">
                <div
                  v-for="(tab, index) in tabs"
                  :key="tab.id"
                  :class="
                    currentTab === index
                      ? `modal-map_tab--active`
                      : `modal-map_tab`
                  "
                  @click="selTab(index)"
                >
                  {{ tab.tabName }}
                </div>
              </div>

              <!-- 図面 -->
              <div
                v-show="currentTab === 0"
                class="modal-map_img-wrapper"
                id="modal-map_img-wrapper"
              >
                <template v-if="showImgFlg">
                  <div>
                    <img
                      :class="
                        imgWidthGtHeightFlg
                          ? 'img-item--leading-width'
                          : 'img-item--leading-height'
                      "
                      :src="mapImg"
                      alt=""
                    />
                  </div>
                </template>
              </div>

              <!-- 地図 -->
              <div v-if="currentTab === 1" class="modal-map_atlas-wrapper">
                <div :style="`height:${CONST.IMAGE.HEIGHT}px; width: 100%`">
                  <l-map
                    ref="lmap"
                    :zoom="zoom"
                    :center="center"
                    :options="mapOptions"
                    :bounds="bounds"
                    style="height: 100%"
                    @update:center="centerUpdate"
                    @update:zoom="zoomUpdate"
                    @click="addLatlng"
                  >
                    <!-- 開始地点 -->
                    <l-control position="bottomright">
                      <a href="#" @click="startLocation()" title="開始地点">
                        <div class="leaflet-crosshair">
                          <img :src="icon.crosshair" alt="" />
                        </div>
                      </a>
                    </l-control>
                    <!-- 拡大／縮小 -->
                    <l-control-zoom
                      position="bottomright"
                      zoomInTitle="拡大"
                      zoomOutTitle="縮小"
                    ></l-control-zoom>
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-marker
                      v-for="(item, index) in items"
                      :key="index"
                      :visible="hasLatlng(item)"
                      :lat-lng="cnvLatlng(item)"
                      @click="selPicturePoint(item.point_id)"
                    >
                      <l-popup>
                        <div class="l-popup_label-wrapper">
                          <div class="l-popup_label">
                            {{ item["point_name"] }}
                          </div>
                          <div @click="delLatLng(index)">
                            <a href="#">
                              <img
                                class="l-popup_label--del-icon"
                                :src="icon.delete"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </l-popup>
                    </l-marker>
                  </l-map>
                </div>
              </div>
            </div>

            <!-- 図面：ポップアップ -->
            <div
              class="modal-map_point-name"
              id="modal-map_point-name"
              :style="
                (showCommentFlg ? '' : 'visibility:hidden; ') + pointNameStyle
              "
            >
              <div @click="closePointNamePopup()">
                <a href="#" class="point-name-popup-close-button">×</a>
              </div>
              <div class="point-name-popup-label-wrapper">
                <div class="point-name-popup-label">
                  {{ pointName }}
                </div>
                <div
                  class="point-name-popup-del-button-wrapper"
                  @click="delPoint()"
                >
                  <a href="#">
                    <img class="point-name-popup-del-button" :src="icon.delete" alt=""/>
                  </a>
                </div>
              </div>
              <div
                class="tail"
                :style="
                  (showCommentFlg ? '' : 'visibility:hidden; ') +
                    pointNameTailStyle
                "
              ></div>
            </div>
          </div>

          <!-- bottom -->
          <div class="modal-map_bottom">
            <div
              class="bottom-toast-message"
              :class="isShowToastMessage ? 'show' : null"
            >
              <div class="toast-message-wrapper">
                <div class="toast-message">
                  {{ toastMessage }}
                </div>
              </div>
            </div>

            <!-- 図面 -->
            <div v-if="currentTab === 0" class="bottom-map">
              <div class="bottom-map_text">
                始点終点設定
              </div>
              <div class="bottom-map_str-end-point">
                <a
                  href="#"
                  @click="ctrlAbleStrEndPoint()"
                  title="始点と終点の緯度経度を入力します"
                >
                  <div ref="str-end-point" class="str-end-point">
                    <img :src="icon.locationStrEnd" alt="" />
                  </div>
                </a>
              </div>
              <div class="bottom-map_text">
                撮影ポイント設定
              </div>
              <div class="bottom-map_picture-point">
                <a
                  href="#"
                  @click="ctrlAblePicturePoint()"
                  title="撮影ポイントを設定します"
                >
                  <div ref="picture-point" class="picture-point">
                    <img :src="icon.location" alt="" />
                  </div>
                </a>
              </div>
            </div>
            <!-- 地図 -->
            <div v-if="currentTab === 1" class="bottom-atlas">
              <div class="bottom-atlas_text">
                撮影ポイント自動設定
              </div>
              <div class="bottom-atlas_leaflet-auto-location">
                <a
                  href="#"
                  title="図面タブにて始点終点の緯度経度が入力済みの場合、自動で撮影ポイントを設定します"
                >
                  <div
                    ref="leaflet-auto-location"
                    class="leaflet-auto-location"
                    @click="calcLatLng()"
                  >
                    <img :src="icon.locationOnAtlas" alt="" />
                  </div>
                </a>
              </div>
              <div class="bottom-atlas_text">
                撮影ポイント手動設定
              </div>
              <div class="bottom-atlas_leaflet-location">
                <div>
                  <a
                    href="#"
                    @click="ctrlAbleLocation(!ableLocationFlg)"
                    title="細かな撮影ポイントの設定を手動で行います。未設定の撮影ポイントが存在する場合、操作可能です"
                  >
                    <div ref="leaflet-location" class="leaflet-location">
                      <img :src="icon.location" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div class="bottom-atlas_leaflet-location-select">
                <select
                  ref="location-select"
                  :disabled="!ableLocationFlg"
                  @change="selLocation($event.target.selectedIndex - 1)"
                >
                  <option value="-1" hidden>選択してください</option>
                  <option
                    v-for="(item, index) in items"
                    :value="index"
                    :key="index"
                    :hidden="hasLatlng(item)"
                  >
                    {{ item["point_name"] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import * as _ from "lodash";
import {close, crosshair, location, locationOnAtlas, locationStrEnd,} from "@/util/icon.js";
import {sleep} from "@/util/sleep.js";
import * as Uobj from "@/util/obj.js";
import * as Uimg from "@/util/img.js";
import * as Uatlas from "@/util/atlas.js";
import {BASE64_TAG_JPG} from "@/util/constants.js";
import {latLng, latLngBounds} from "leaflet";
import {LControl, LControlZoom, LMap, LMarker, LPopup, LTileLayer,} from "vue2-leaflet";

export default {
  components: {
    LMap,
    LControl,
    LControlZoom,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data: () => {
    const R = 15; // 円の半径
    /**
     * オブジェクトのベースとなるクラス
     */
    class BaseObject {
      constructor(x, y) {
        this.x = x;
        this.y = y;
      }

      // 初期表示
      // eslint-disable-next-line no-unused-vars
      draw(ctx, point_name, img) { }

      // eslint-disable-next-line no-unused-vars
      reDraw(ctx, img) { }

      // 自オブジェクトがクリックされたかどうか判定
      // eslint-disable-next-line no-unused-vars
      isHit(point) { }

      // クリックされたときの処理
      // eslint-disable-next-line no-unused-vars
      clicked(ctx) { }
    }

    /**
     * 円オブジェクトのクラス
     */
    class Circle extends BaseObject {
      constructor(x, y, point_id, name, latitude, longitude) {
        super(x, y);
        this.r = R;
        this.point_id = point_id;
        this.point_name = name;
        this.latitude = latitude;
        this.longitude = longitude;
      }

      async draw(ctx, name, img) {
        this.point_name = name;
        ctx.save();
        ctx.beginPath();
        // 画像サイズを考慮して座標計算
        ctx.drawImage(img, this.x - 15, this.y - 15);
        ctx.fill();
        ctx.restore();
      }

      async reDraw(ctx, img) {
        this.clicked(ctx);
        ctx.save();
        ctx.beginPath();
        ctx.drawImage(img, this.x - 15, this.y - 15);
        ctx.fillStyle = "rgb(255,0,0)";
        ctx.fill();
        ctx.restore();
      }

      clicked(ctx) {
        ctx.save();
        ctx.beginPath();
        ctx.globalCompositeOperation = "destination-out"; // 切取り
        ctx.arc(this.x, this.y, this.r + 0.8, 0, Math.PI * 2, true);
        ctx.fill();
        ctx.restore();
      }

      isHit(point) {
        return (
          Math.pow(this.x - point.x, 2) + Math.pow(this.y - point.y, 2) <=
          Math.pow(this.r, 2)
        );
      }
    }

    return {
      icon: {
        location,
        locationStrEnd,
        locationOnAtlas,
        crosshair,
        close,
        delete: require("@/assets/img/icon-panel-delete.png"),
      },
      CONST: {
        BASE64_TAG_JPG,
        IMAGE: {
          WIDTH: 800,
          HEIGHT: 600,
        },
        ADD_POINT: {
          title: "撮影ポイント名を入力してください",
          keyword: "撮影ポイント名",
          maxLength: 15,
        },
        STR_PONIT: {
          title: "始点の緯度経度を入力してください",
        },
        END_PONIT: {
          title: "終点の緯度経度を入力してください",
        },
      },
      ICON_IMAGE: {
        POINT_CHECK: require("@/assets/img/dot-circle-chk.png"),
        POINT_CLEAR: require("@/assets/img/dot-circle-def.png"),
        POINT_STR: require("@/assets/img/dot-circle-str.png"),
        POINT_END: require("@/assets/img/dot-circle-end.png"),
      },
      CANVAS_ELM_ID: "modal-map_canvas",
      // 共通
      showModalFlg: false, // モーダル表示
      currentTab: 0, // タブ
      tabs: [
        { id: 1, tabName: "図面" },
        { id: 2, tabName: "地図" },
      ],
      map: null,
      items: [], // 撮影ポイント
      existIdx: -1, // 撮影ポイントindex
      bfrExistIdx: -1, // 前回選択済み撮影ポイントindex
      positions: {}, // 始点終点
      positionFlg: null, // 始点終点index 0:始点、1:終点
      showCommentFlg: false, // コメント表示
      pointNameStyle: "",
      pointNameTailStyle: "",
      pointName: "",
      mapImg: null,
      showImgFlg: false,
      imgWidthGtHeightFlg: true,
      isShowToastMessage: null,
      toastMessage: null,
      // canvas関連
      Circle,
      ableStrEndPointFlg: false, // 始点、終点ポイント
      ablePicturePointFlg: false, // 撮影ポイント
      // leaflet2Vue関連
      locationsIdx: -1,
      ableLocationFlg: false, // 撮影ポイント設定ボタン
      zoom: 13,
      center: latLng(35.684465, 139.753708),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5, // 地図：ズーム
      currentCenter: latLng(35.684465, 139.753708), // 地図：中心点
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      bounds: null,
    };
  },
  props: {
    showFlg: {
      type: Boolean,
      required: true,
    },
    maps: {
      type: [Array, Object],
      required: false,
    },
    map_id: {
      type: [String, Number],
      required: false,
    },
  },
  async mounted() {
    this.$watch(
      () => [this.showFlg, this.maps, this.map_id, this.currentTab],
      async (newValue, oldValue) => {
        let showFlg = newValue[0];
        let maps = newValue[1];
        let map_id = newValue[2];
        let currentTab = newValue[3];
        // 選択：タブ 図面
        if (newValue !== oldValue && maps && map_id && currentTab === 0) {
          this.map = maps.find((item) => item.id === map_id);
          let positionPoints = await this.loadCaptchaStartEndPoint();
          let map = this.map;
          let captchaPoints = map["captcha_points"]
            ? map["captcha_points"]
            : null;

          await this.createCanvas(captchaPoints, positionPoints);

          // 取得:図面画像
          let login = this.$store.getters[`LoginApi/getStateLogin`];
          let zumenImage = await this.$store.dispatch("ViewImageApi/getZumenImage", {
            api_token: login["api_token"],
            file_id: map["file_id"],
          });
          if (zumenImage["status"] !== 200) return;
          let zumenImageData = zumenImage["data"];
          this.showModalFlg = showFlg;
          this.mapImg = zumenImageData["image"]
            ? this.CONST.BASE64_TAG_JPG + zumenImageData["image"]
            : null;
          await sleep(300);
          this.imgWidthGtHeightFlg = await Uimg.isWidthGtHeight(
            this.mapImg,
            this.CONST.IMAGE.WIDTH,
            this.CONST.IMAGE.HEIGHT
          );
          this.showImgFlg = true;
          await sleep(100);
          this.setClassForStrEndPointButton();
        }
        // 選択：タブ 地図
        if (oldValue && currentTab !== oldValue[2]) {
          if (currentTab === 1) {
            this.startLocation();
            await sleep(300);
            this.setClassForAutoLocationButton();
            this.setClassForLocationButton();
          }
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    // 初期化
    initModal() {
      this.showModalFlg = false;
      this.showCommentFlg = false;
      this.mapImg = null;
      this.showImgFlg = false;
      this.imgWidthGtHeightFlg = true;
      this.map = null;
      this.items = [];
      this.existIdx = -1;
      this.bfrExistIdx = -1;
      this.positions = {};
      this.positionFlg = null;
      this.pointNameStyle = "";
      this.pointNameTailStyle = "";
      this.pointName = "";
      this.currentTab = 0;
      this.ableLocationFlg = false;
    },
    close() {
      this.initModal();
      this.$emit("close");
    },
    // タブ選択
    async selTab(index) {
      // 連続切替を行うとleafletでエラー発生への対処
      await sleep(500);
      await this.showComment();
      this.currentTab = index;
    },
    // Toast
    ctrlToastMsg(message) {
      if (!message) return;
      this.toastMessage = message;
      this.isShowToastMessage = true;
      setTimeout(() => {
        this.isShowToastMessage = false;
      }, 3000);
      setTimeout(() => {
        this.toastMessage = null;
      }, 3600);
    },
    /// 図面関連
    // 保持確認：始点終点の緯度経度
    hasStartEndPoint() {
      let strLat = Uobj.getNest(this.positions, "start_point", "latitude");
      let strLng = Uobj.getNest(this.positions, "start_point", "longitude");
      let endLat = Uobj.getNest(this.positions, "end_point", "latitude");
      let endLng = Uobj.getNest(this.positions, "end_point", "longitude");
      return strLat && strLng && endLat && endLng;
    },
    /// 図面：canvas関連
    getPositionPointsName(positionFlg, lat, lng) {
      if (_.isNil(positionFlg) || _.isNil(lat) || _.isNil(lng)) return "";
      let positionName = positionFlg === 0 ? "始点" : "終点";
      return `${positionName}\n 緯度：${lat}\n 経度：${lng}`;
    },
    getCircle(point, id, pointName) {
      return new this.Circle(
          Number(point["point_x"]),
          Number(point["point_y"]),
          id,
          pointName,
          Number(point["latitude"]),
          Number(point["longitude"])
      );
    },
    async createCanvas(captchaPoints, positionPoints) {
      const canvas = document.createElement("canvas");
      canvas.id = this.CANVAS_ELM_ID;
      canvas.width = this.CONST.IMAGE.WIDTH;
      canvas.height = this.CONST.IMAGE.HEIGHT;
      canvas.style.zIndex = "2px";
      canvas.style.position = "absolute";
      canvas.style.left = "0";
      canvas.style.padding = "10px";
      // 画像が切り替わったら、前回作成したキャンバスを削除する。
      const imgCanvasElm = document.getElementById(this.CANVAS_ELM_ID);
      if (imgCanvasElm) {
        imgCanvasElm.remove();
        this.items = [];
      }
      const imgWrpElm = document.getElementById("modal-map_img-wrapper");
      imgWrpElm.appendChild(canvas);
      const ctx = canvas.getContext("2d");
      ctx.save();
      ctx.fillStyle = "rgba(255,255,255,0)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.restore();

      // 描画：始点、終点
      let strPoint = Uobj.getNest(positionPoints, "start_point");
      if (strPoint) {
        let pointImg = await Uimg.loadImage(this.ICON_IMAGE.POINT_STR);
        strPoint.reDraw(ctx, pointImg);
      }
      let endPoint = Uobj.getNest(positionPoints, "end_point");
      if (endPoint) {
        let pointImg = await Uimg.loadImage(this.ICON_IMAGE.POINT_END);
        endPoint.reDraw(ctx, pointImg);
      }
      // 描画：撮影ポイント
      const pointImg = await Uimg.loadImage(this.ICON_IMAGE.POINT_CLEAR)
          .catch(e => console.log(e));

      _.forEach(captchaPoints, (value) => {
        if (this.items.find((item) => item.point_id === value.id)) return;
        let circle = new this.Circle(
          value["point_x"],
          value["point_y"],
          value.id,
          value.name,
          value["latitude"],
          value["longitude"]
        );
        circle.reDraw(ctx, pointImg);
        this.items.push(circle);
      });

      // イベント登録
      canvas.addEventListener("click", async (e) => {
        // マウスの座標をCanvas内の座標とあわせるため
        const rect = canvas.getBoundingClientRect();
        const point = {
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        };

        // 検知：始点、終点
        this.positionFlg = null;
        let circleStr = Uobj.getNest(this.positions, "start_point");
        let circleEnd = Uobj.getNest(this.positions, "end_point");
        if (circleStr && circleStr.isHit(point)) {
          this.positionFlg = 0;
        } else if (circleEnd && circleEnd.isHit(point)) {
          this.positionFlg = 1;
        }
        // 検知：撮影ポイント
        let existIdx = -1;
        if (_.isNil(this.positionFlg)) {
          for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];
            if (item.isHit(point)) {
              existIdx = i;
            }
          }
        }

        // 選択：始点、終点
        if (!_.isNil(this.positionFlg)) {
          if (e.detail === 1) {
            await sleep(500);
            let item = this.positionFlg === 1 ? circleEnd : circleStr;
            await this.showComment(item, null);
          }
        }
        // 選択：撮影ポイント
        else if (existIdx >= 0) {
          if (e.detail === 1) {
            await sleep(500);
            if (
              this.bfrExistIdx >= 0 &&
              this.bfrExistIdx <= this.items.length - 1
            ) {
              let bfrItem = this.items[this.bfrExistIdx];
              let bfrPointImg = await Uimg.loadImage(
                this.ICON_IMAGE.POINT_CLEAR
              );
              bfrItem.reDraw(ctx, bfrPointImg);
            }
            this.bfrExistIdx = _.cloneDeep(existIdx);
            let item = this.items[existIdx];
            this.$emit("sel", {
              map_id: this.map.id,
              id: item.point_id,
            });
            let pointImg = await Uimg.loadImage(this.ICON_IMAGE.POINT_CHECK);
            item.reDraw(ctx, pointImg);
            await this.showComment(this.items[existIdx], existIdx);
          }
        }
        // 追加：始点終点
        else if (this.ableStrEndPointFlg) {
          let positionFlg = null;
          if (!Uobj.getNest(this.positions, "start_point")) {
            positionFlg = 0;
          } else if (!Uobj.getNest(this.positions, "end_point")) {
            positionFlg = 1;
          }
          if (!_.isNil(positionFlg)) {
            let res = await this.$root.$children[0].$refs["popupLanLng"].call(
              positionFlg === 0 ? this.CONST.STR_PONIT : this.CONST.END_PONIT
            );
            if (!res.result) return;
            // バリデート：Canvas始点終点の距離が100px以上離れているかチェックする
            const isValidMapDistance = () => {
              let position =
                positionFlg === 0
                  ? this.positions["end_point"]
                  : this.positions["start_point"];
              if (positionFlg) {
                return (
                  Math.abs(position.x - point.x) >= 100 &&
                  Math.abs(position.y - point.y) >= 100
                );
              } else {
                return true;
              }
            };
            if (!isValidMapDistance(res)) {
              alert(
                "始点終点の緯度経度が近すぎます。始点終点の距離を離して設定してください"
              );
              return;
            }
            // API
            const csepPrm = {
              zumen_id: this.map.id,
              point_x: point.x,
              point_y: point.y,
              latitude: res.lat,
              longitude: res.lng,
              position_flg: positionFlg,
            };
            const csepRes = await this.postCaptchaStartEndPoint(csepPrm);
            if (csepRes["status"] !== 200) return;
            // 自コンポーネント内操作を実施
            let pointImg = await Uimg.loadImage(
              positionFlg === 0
                ? this.ICON_IMAGE.POINT_STR
                : this.ICON_IMAGE.POINT_END
            );
            let pointName = this.getPositionPointsName(
              positionFlg,
              res.lat,
              res.lng
            );
            let circle = this.getCircle(csepPrm, null, pointName);
            circle.draw(ctx, pointName, pointImg);
            if (positionFlg === 0) {
              this.positions["start_point"] = circle;
            } else {
              this.positions["end_point"] = circle;
            }
            this.setClassForStrEndPointButton();
          }
        }
        // 追加：撮影ポイント
        else if (this.ablePicturePointFlg) {
          let res = await this.$root.$children[0].$refs["popupInput"].call(
            this.CONST.ADD_POINT
          );
          if (res.result) {
            // API
            const cpPrm = {
              zumen_id: this.map.id,
              name: res.value,
              point_x: point.x,
              point_y: point.y,
            };
            const cpRes = await this.postCaptchaPoint(cpPrm);
            if (cpRes["status"] !== 200) return;
            // 自コンポーネント内操作を実施
            const point_id = cpRes["data"]["id"];
            const circle = new this.Circle(point.x, point.y, point_id);
            let pointImg = await Uimg.loadImage(this.ICON_IMAGE.POINT_CLEAR);
            circle.draw(ctx, res.value, pointImg);
            this.items.push(circle);
            this.$emit("add");
          }
        }
      });
    },
    // 取得：始点、終点
    async loadCaptchaStartEndPoint() {
      const csepPrm = {
        zumen_id: this.map.id,
      };
      const csepRes = await this.getCaptchaStartEndPoint(csepPrm);
      if (csepRes["status"] !== 200) return;

      let strPoint = Uobj.getNest(csepRes, "data", "start_point");
      let endPoint = Uobj.getNest(csepRes, "data", "end_point");
      let positions = {};
      if (strPoint) {
        let pointName = this.getPositionPointsName(
          strPoint["position_flg"],
          strPoint["latitude"],
          strPoint["longitude"]
        );
        positions["start_point"] = this.getCircle(strPoint, null, pointName);
      }
      if (endPoint) {
        let pointName = this.getPositionPointsName(
          endPoint["position_flg"],
          endPoint["latitude"],
          endPoint["longitude"]
        );
        positions["end_point"] = this.getCircle(endPoint, null, pointName);
      }
      this.positions = _.cloneDeep(positions);
      return this.positions;
    },
    // 削除
    async delPoint() {
      if (!_.isNil(this.positionFlg)) {
        await this.delCaptchaStartEndPoint();
      } else {
        await this.delCaptchaPoint();
      }
    },
    // 削除：始点、終点
    async delCaptchaStartEndPoint() {
      let positionName = this.positionFlg === 0 ? "始点" : "終点";
      let ans = window.confirm(`${positionName}を削除しますか？`);
      if (!ans) return;
      // API
      const csepPrm = {
        zumen_id: this.map.id,
        position_flg: this.positionFlg,
      };
      const csepRes = await this.deleteCaptchaStartEndPoint(csepPrm);
      if (csepRes["status"] !== 200) return;
      // 自コンポーネント内操作を実施
      this.showComment();
      const imgCanvasElm = document.getElementById(this.CANVAS_ELM_ID);
      const ctx = imgCanvasElm.getContext("2d");
      let positionItem = this.positionFlg === 0 ? "start_point" : "end_point";
      let item = this.positions[positionItem];
      item.clicked(ctx);
      await this.loadCaptchaStartEndPoint();
      this.setClassForStrEndPointButton();
    },
    // 削除：撮影ポイント
    async delCaptchaPoint() {
      let ans = window.confirm(
        "撮影ポイントを削除しますか？ ※撮影した画像や履歴が全て削除されます"
      );
      if (!ans) return;
      // API
      let item = this.items[this.existIdx];
      const cpPrm = {
        zumen_site_id: this.map.id,
        captcha_point_id: item.point_id,
      };
      const cpRes = await this.deleteCaptchaPoint(cpPrm);
      if (cpRes["status"] !== 200) return;
      // 自コンポーネント内操作を実施
      this.showComment();
      const imgCanvasElm = document.getElementById(this.CANVAS_ELM_ID);
      const ctx = imgCanvasElm.getContext("2d");
      item.clicked(ctx);
      this.items.splice(this.existIdx, 1);
      this.$emit("del");
    },
    // 閉じる
    closePointNamePopup() {
      this.showComment();
    },
    ctrlAbleStrEndPoint() {
      if (this.hasStartEndPoint()) return;
      this.ableStrEndPointFlg = !this.ableStrEndPointFlg;
      this.ablePicturePointFlg = false;
      if (this.ableStrEndPointFlg) {
        this.ctrlToastMsg("図面をクリックして始点終点を設定してください");
      }
      this.setClassForStrEndPointButton();
      this.setClassForPicturePointButton();
    },
    setClassForStrEndPointButton() {
      let picturePoint = this.$refs["str-end-point"];
      if (!picturePoint) return;
      if (this.hasStartEndPoint()) {
        picturePoint.className = "str-end-point--disable";
      } else if (this.ableStrEndPointFlg) {
        picturePoint.className = "str-end-point--active";
      } else {
        picturePoint.className = "str-end-point";
      }
    },
    ctrlAblePicturePoint() {
      this.ableStrEndPointFlg = false;
      this.ablePicturePointFlg = !this.ablePicturePointFlg;
      if (this.ablePicturePointFlg) {
        this.ctrlToastMsg("図面をクリックして撮影ポイントを設定してください");
      }
      this.setClassForStrEndPointButton();
      this.setClassForPicturePointButton();
    },
    setClassForPicturePointButton() {
      let picturePoint = this.$refs["picture-point"];
      if (!picturePoint) return;
      if (this.ablePicturePointFlg) {
        picturePoint.className = "picture-point--active";
      } else {
        picturePoint.className = "picture-point";
      }
    },
    // コメント表示
    async showComment(item, existIdx) {
      this.showCommentFlg = false;
      this.pointName = "";
      if (!item) return;

      this.pointName = item.point_name;
      if (!_.isNil(existIdx)) this.existIdx = existIdx;
      const cmtElm = document.getElementById("modal-map_point-name");
      const imgWrpElm = document.getElementById("modal-map_img-wrapper");
      // コメントがマウントされるまで、sleepする
      await sleep(100);
      const cmtWidth = cmtElm.clientWidth;
      const cmtHeight = cmtElm.clientHeight;
      const pageX = imgWrpElm.offsetLeft + item.x;
      const pageY = imgWrpElm.offsetTop + item.y;

      // 画像表示エリア内にポップアップコメントが収まるか判定(true:内に収まる、false:内に収まらない)
      // - 比較：クリック位置(x) + コメントの幅（半分）< 画像表示エリアの開始位置
      let xClkCmtWidthLtZeroFlg = item.x - cmtWidth / 2 < 0;
      // - 比較：クリック位置(x) + コメントの幅（半分）< 画像表示エリアの幅
      let xClkLtImgWrpWidthFlg = item.x + cmtWidth / 2 < imgWrpElm.clientWidth;
      // - 比較：クリック位置(y) > コメントの高さ
      let yClkGtCmtHeightFlg = item.y > cmtHeight;

      // 座標計算：名称
      let point_name_potion_x = 0;
      if (xClkCmtWidthLtZeroFlg) {
        point_name_potion_x = imgWrpElm.offsetLeft;
      } else if (!xClkLtImgWrpWidthFlg) {
        point_name_potion_x =
          imgWrpElm.offsetLeft + imgWrpElm.clientWidth - cmtWidth;
      } else {
        point_name_potion_x = pageX - cmtWidth / 2;
      }

      let point_name_potion_y;
      if (yClkGtCmtHeightFlg) {
        point_name_potion_y = pageY - cmtElm.clientHeight - 10;
      } else {
        point_name_potion_y = pageY + 14;
      }

      // positon,styleの切り替え
      const commnet_potison =
        "top:" + point_name_potion_y + "px; left:" + point_name_potion_x + "px;";
      if (yClkGtCmtHeightFlg && xClkLtImgWrpWidthFlg) {
        if (xClkCmtWidthLtZeroFlg) {
          this.pointNameStyle =
            "border-radius: 8px 8px 8px 0px;" + commnet_potison;
        } else {
          // デフォルトデザイン
          this.pointNameStyle =
            "border-radius: 8px 8px 8px 8px;" + commnet_potison;
        }
      } else if (yClkGtCmtHeightFlg && !xClkLtImgWrpWidthFlg) {
        this.pointNameStyle =
          "border-radius: 8px 8px 0px 8px;" + commnet_potison;
      } else if (!yClkGtCmtHeightFlg && xClkLtImgWrpWidthFlg) {
        this.pointNameStyle =
          "border-radius: 0px 8px 8px 8px;" + commnet_potison;
      } else {
        this.pointNameStyle =
          "border-radius: 8px 0px 8px 8px;" + commnet_potison;
      }

      // 座標計算：矢印
      let point_name_tail_margin_left = 0;
      if (xClkCmtWidthLtZeroFlg) {
        point_name_tail_margin_left = item.x - 16;
      } else if (!xClkLtImgWrpWidthFlg) {
        point_name_tail_margin_left = pageX - point_name_potion_x - 16;
      } else {
        point_name_tail_margin_left = cmtWidth / 2 - 16;
      }
      let point_name_tail_margin_top = 0;
      let tailShape = "";
      if (yClkGtCmtHeightFlg) {
        point_name_tail_margin_top = 12;
        tailShape =
          "border-top: 12px solid #fff; border-right: 12px solid transparent; border-left: 12px solid transparent;";
      } else {
        point_name_tail_margin_top = -cmtHeight;
        tailShape =
          "border-bottom: 12px solid #fff;  border-right: 12px transparent solid; border-left: 12px transparent solid;";
      }

      this.pointNameTailStyle =
        "margin-top:" +
        point_name_tail_margin_top +
        "px; margin-left:" +
        point_name_tail_margin_left +
        "px; " +
        tailShape;

      this.showCommentFlg = true;
    },

    /// 地図関連
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    setClassForAutoLocationButton() {
      let leafletLocation = this.$refs["leaflet-auto-location"];
      if (!leafletLocation) return;
      if (!this.hasStartEndPoint()) {
        leafletLocation.className = "leaflet-auto-location--disable";
      } else {
        leafletLocation.className = "leaflet-auto-location";
      }
    },
    ctrlAbleLocation(ableLocationFlg) {
      let options = this.$refs["location-select"].children;
      let hiddens = _.filter(options, (o) => {
        return o.hidden;
      });
      if (options.length === 0 || options.length === hiddens.length) {
        this.ableLocationFlg = false;
      } else if (ableLocationFlg !== undefined) {
        this.ableLocationFlg = ableLocationFlg;
      } else {
        this.ableLocationFlg = true;
      }
      if (ableLocationFlg && this.ableLocationFlg) {
        this.ctrlToastMsg("地図をクリックして撮影ポイントを設定してください");
      }
      this.setClassForLocationButton();
    },
    setClassForLocationButton() {
      let locationSelect = this.$refs["location-select"];
      let leafletLocation = this.$refs["leaflet-location"];
      if (!locationSelect || !leafletLocation) return;
      let options = locationSelect.children;
      let hiddens = _.filter(options, (o) => {
        return o.hidden;
      });
      if (options.length === 0 || options.length === hiddens.length) {
        leafletLocation.className = "leaflet-location--disable";
      } else if (this.ableLocationFlg) {
        leafletLocation.className = "leaflet-location--active";
      } else {
        leafletLocation.className = "leaflet-location";
      }
    },
    startLocation() {
      const css =
        this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      let cs = css[0]; // TOOD 見直し:現場選択実装
      let lmapObj = this.$refs.lmap.mapObject;
      if (_.isNumber(cs["latitude"]) && _.isNumber(cs["longitude"])) {
        // 現場の緯度経度が登録されている場合
        lmapObj.panTo(latLng(cs["latitude"], cs["longitude"]));
        return;
      }
      let firstItem = this.items.find(
        (item) =>
          _.isNumber(item.latitude) &&
          _.isNumber(item.longitude) &&
          Number(item.latitude) !== 0 &&
          Number(item.longitude)
      );
      if (firstItem) {
        // 撮影ポイントに緯度経度が設定されている場合
        lmapObj.panTo(latLng(firstItem.latitude, firstItem.longitude));
        return;
      }
      // 上記のいずれでもない場合
      // デフォルト地点を設定
      lmapObj.panTo(latLng(35.684465, 139.753708));
    },
    selLocation(index) {
      this.locationsIdx = index;
    },
    // 撮影ポイント自動反映
    async calcLatLng() {
      if (!this.hasStartEndPoint()) return;
      let ans = window.confirm(
        "地図に全ての撮影ポイントを反映しますか？※設置済みの撮影ポイントは上書きされます"
      );
      if (!ans) return;
      // Step1. canvasの中心点を求める
      if (!this.items || this.items.length === 0) {
        alert("地図に撮影ポイントが設定されていません");
        return;
      }
      let strItem = this.positions.start_point; // 境界：始点
      let endItem = this.positions.end_point; // 境界：終点
      let centerX = (strItem.x + endItem.x) / 2;
      let centerY = (strItem.y + endItem.y) / 2;

      // Step2. 直径(2点の距離)と半径を求める
      let diameter = Math.sqrt(
        Math.pow(Math.abs(strItem.x - endItem.x), 2) +
          Math.pow(Math.abs(strItem.y - endItem.y), 2)
      );

      // Step3. 境界：地図の北東、南西の座標を求める
      let strLat = strItem.latitude;
      let strLng = strItem.longitude;
      let endLat = endItem.latitude;
      let endLng = endItem.longitude;

      let diffLan = Math.abs(strLat - endLat);
      let diffLng = Math.abs(strLng - endLng);
      let maxDiff = diffLan > diffLng ? diffLan : diffLng;
      let centerLat = (strLat + endLat) / 2;
      let centerLng = (strLng + endLng) / 2;
      console.log("- calcLatLng center", centerLat, centerLng);

      const decimalPoint = 100000000;
      let northEastLat = // y:Max
        Math.round((centerLat + maxDiff / 2) * decimalPoint) / decimalPoint;
      let northEastLng = // x:Max
        Math.round((centerLng + ((maxDiff / 2) * 3) / 4) * decimalPoint) /
        decimalPoint;
      let southWestLat = // y:Min
        Math.round((centerLat - maxDiff / 2) * decimalPoint) / decimalPoint;
      let southWestLng = // x:Min
        Math.round((centerLng - ((maxDiff / 2) * 3) / 4) * decimalPoint) /
        decimalPoint;
      this.bounds = latLngBounds([
        [southWestLat, southWestLng],
        [northEastLat, northEastLng],
      ]);
      console.log("- calcLatLng bounds", this.bounds);

      // Step4. 図面を北向けに向ける為の角度を算出する
      let vi = Uatlas.vincentyInverse(strLat, strLng, endLat, endLng);
      if (!vi) {
        alert("始点終点に入力された緯度経度から方位を検出できませんでした");
        return;
      }

      let vdCenter = Uatlas.vincentyDirect(
        strLat,
        strLng,
        vi.azimuth1,
        vi.distance / 2
      );

      // Step5. 撮影ポイントを回転させて、配置する
      // 取得：2点間の距離
      const getDistance = (x, y, x2, y2) => {
        return Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y));
      };
      // 取得：2点間のラジアン
      const getRadian = (x, y, x2, y2) => {
        return Math.atan2(y2 - y, x2 - x);
      };
      // 取得：Canvasで取得した角度を地図の角度に変換する
      const cnvMapDegree = (canvasDegree) => {
        if (!canvasDegree && canvasDegree !== 0) {
          return 0;
        } else if (canvasDegree < 0) {
          return normalize360Degrees(270 + (180 + canvasDegree));
        } else {
          return normalize360Degrees(canvasDegree + 90);
        }
      };
      // 360度以上の場合、360度以下に標準化する
      const normalize360Degrees = (degrees) => {
        if (!degrees && degrees !== 0) return 0;
        if (degrees > 360) {
          return degrees % 360;
        } else if (degrees < 0) {
          return (degrees % 360) + 360;
        } else {
          return degrees;
        }
      };

      let canvasRadian = getRadian(strItem.x, strItem.y, endItem.x, endItem.y);
      let canvasDegree = (canvasRadian * 180) / Math.PI;
      let mapDegree = cnvMapDegree(canvasDegree);
      let cnt = 0;
      let _items = _.cloneDeep(this.items);
      for (let i = 0; i < _items.length; i++) {
        let item = _items[i];
        let distanceMap = getDistance(centerX, centerY, item.x, item.y);
        let radian = getRadian(centerX, centerY, item.x, item.y);
        let per = distanceMap / diameter; // (Canvas内)距離の比率（始点終点の中心点から撮影ポイントまでの距離／始点終点間の距離）
        let distanceAtlas = vi.distance * per; // 距離比率
        let degree = (radian * 180) / Math.PI; // 変換：ラジアン->角度
        let sumDegree = vi.azimuth1 - mapDegree;
        let degreeAtlas = normalize360Degrees(cnvMapDegree(degree) + sumDegree);

        let vd = Uatlas.vincentyDirect(
          vdCenter.lat,
          vdCenter.lng,
          degreeAtlas,
          distanceAtlas
        );
        if (!vd) {
          alert(
            "始点終点に入力された緯度経度から撮影ポイントを自動検出できませんでした"
          );
          break;
        }
        console.log("- idx", i, " item", item, " lat", vd.lat, " lng", vd.lng);
        item.latitude = vd.lat;
        item.longitude = vd.lng;
        cnt++;
      }
      if (_items.length !== cnt) return;
      // API 更新
      let isErrFlg = false;
      const updateCaptchaPoints = _items.map(async (item) => {
        if (isErrFlg) return;
        const cpPrm = {
          zumen_id: this.map.id,
          captcha_point_id: item.point_id,
          name: item.point_name,
          point_x: item.x,
          point_y: item.y,
          latitude: item.latitude,
          longitude: item.longitude,
        };
        let res = await this.patchCaptchaPoint(cpPrm);
        if (res["status"] !== 200) isErrFlg = true;
        return res;
      });
      await Promise.all(updateCaptchaPoints);
      if (!isErrFlg) {
        this.items = _.cloneDeep(_items);
        this.$emit("add");
        await sleep(500);
        this.ctrlAbleLocation();
      }
    },
    hasLatlng(item) {
      let latLng = this.cnvLatlng(item);
      return latLng.lat !== 0 || latLng.lng !== 0;
    },
    cnvLatlng(item) {
      let lat = item.latitude;
      let lng = item.longitude;
      const hasOtherThenZero = (val) => {
        return !_.isNil(val) && (val > 0 || val < 0);
      };
      if (hasOtherThenZero(lat) && hasOtherThenZero(lng)) {
        return latLng(lat, lng);
      } else {
        return latLng(0, 0);
      }
    },
    // 追加：撮影ポイント
    async addLatlng(e) {
      if (!this.ableLocationFlg) return;
      if (this.locationsIdx < 0) {
        alert("撮影ポイントを選択してください");
        return;
      }
      let _items = _.cloneDeep(this.items);
      let item = _items[this.locationsIdx];
      // API
      const cpPrm = {
        zumen_id: this.map.id,
        captcha_point_id: item.point_id,
        name: item.point_name,
        point_x: item.x,
        point_y: item.y,
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
      };
      const cpRes = await this.patchCaptchaPoint(cpPrm);
      if (cpRes["status"] !== 200) return;
      // 自コンポーネント内操作を実施
      item.latitude = e.latlng.lat;
      item.longitude = e.latlng.lng;
      this.items = _items;
      // 対象撮影ポイントの非活性化
      this.ctrlHiddenLocationSelectbox(this.locationsIdx, true);
      this.setClassForLocationButton();
      this.$emit("add");
    },
    // 削除：撮影ポイント
    async delLatLng(index) {
      let ans = window.confirm(
        "地図からこの撮影ポイントを削除しますか? ※図面の撮影ポイントは削除されません。"
      );
      if (!ans) return;
      let _items = _.cloneDeep(this.items);
      let item = _items[index];
      // API
      const cpPrm = {
        zumen_id: this.map.id,
        captcha_point_id: item.point_id,
        name: item.point_name,
        point_x: item.x,
        point_y: item.y,
        latitude: "0.0",
        longitude: "0.0",
      };
      const cpRes = await this.patchCaptchaPoint(cpPrm);
      if (cpRes["status"] !== 200) return;
      // 自コンポーネント内操作を実施
      item.latitude = 0.0;
      item.longitude = 0.0;
      this.items = _items;
      // 対象撮影ポイントの活性化
      this.ctrlHiddenLocationSelectbox(index, false);
      this.setClassForLocationButton();
      this.$emit("del");
    },
    // 活性・非活性：撮影ポイント リスト
    ctrlHiddenLocationSelectbox(index, hiddenFlg) {
      let options = this.$refs["location-select"].children;
      options[index + 1].hidden = hiddenFlg;
      this.ctrlAbleLocation();
    },
    // 表示：撮影ポイント
    selPicturePoint(point_id) {
      this.$emit("sel", {
        map_id: this.map.id,
        id: point_id,
      });
    },

    // API 登録:始点終点
    async postCaptchaStartEndPoint(param) {
      let params = _.cloneDeep(param);
      delete params.circle;
      const cs =
        this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      params["api_token"] = this.$store.getters[`LoginApi/getApiToken`];
      params["construction_site_id"] = cs[0]["id"]; // TOOD 見直し:現場選択実装
      return await this.$store.dispatch(
        "CaptchaStartEndPointApi/postCaptchaStartEndPoint",
        params
      );
    },
    // API 取得:始点終点
    async getCaptchaStartEndPoint(param) {
      let params = _.cloneDeep(param);
      const cs =
        this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      params["api_token"] = this.$store.getters[`LoginApi/getApiToken`];
      params["construction_site_id"] = cs[0]["id"]; // TOOD 見直し:現場選択実装
      return await this.$store.dispatch(
        "CaptchaStartEndPointApi/getCaptchaStartEndPoint",
        params
      );
    },
    // API 削除:始点終点
    async deleteCaptchaStartEndPoint(param) {
      const cs =
        this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      let params = _.cloneDeep(param);
      params["api_token"] = this.$store.getters[`LoginApi/getApiToken`];
      params["construction_site_id"] = cs[0]["id"]; // TOOD 見直し:現場選択実装
      return await this.$store.dispatch(
        "CaptchaStartEndPointApi/deleteCaptchaStartEndPoint",
        params
      );
    },
    // API 登録:撮影ポイント
    async postCaptchaPoint(param) {
      let params = _.cloneDeep(param);
      params["api_token"] = this.$store.getters[`LoginApi/getApiToken`];
      return await this.$store.dispatch("CaptchaPointApi/postCaptchaPoint", params);
    },
    // API 更新:撮影ポイント
    async patchCaptchaPoint(param) {
      let params = _.cloneDeep(param);
      params["api_token"] = this.$store.getters[`LoginApi/getApiToken`];
      return await this.$store.dispatch("CaptchaPointApi/patchCaptchaPoint", params);
    },
    // API 削除:撮影ポイント
    async deleteCaptchaPoint(param) {
      const cs =
          this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      let params = _.cloneDeep(param);
      params["api_token"] = this.$store.getters[`LoginApi/getApiToken`];
      params["construction_site_id"] = cs[0]["id"]; // TOOD 見直し:現場選択実装
      return await this.$store.dispatch("CaptchaPointApi/deleteCaptchaPoint", params);
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.mapid {
  width: 100%;
  height: 400px;
}
.vue2leaflet-map {
  .leaflet-control-container {
    .leaflet-bottom,
    .leaflet-right {
      .leaflet-control-zoom {
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import "../../style/config.scss";

.modal-map {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 911;
  }
  .popup {
    padding: 16px;
    border-radius: 4px;
    background-color: white;
    font-size: $font-size-40;

    .modal-map_body {
      display: flex;
      margin-bottom: $margin-15;
      .modal-map_tab-wrapper {
        display: flex;
        .modal-map_tab {
          width: 120px;
          height: 22px;
          padding: 4px;
          border-radius: 8px 8px 0 0;
          background: $color-base-30;
          cursor: pointer;
        }
        .modal-map_tab--active {
          width: 120px;
          height: 22px;
          padding: 4px;
          border-radius: 8px 8px 0 0;
          background: $col-selected;
          cursor: pointer;
        }
      }
      .modal-map_img-wrapper {
        position: relative;
        width: 800px;
        height: 600px;
        margin: 0 $margin-20, $margin-20, $margin-20;
        border-top: 2px solid $col-selected;
        background: $color-base-30;
        .img-item--leading-width {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          height: auto;
        }
        .img-item--leading-height {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          height: 100%;
          width: auto;
        }
      }
      .modal-map_point-name {
        position: absolute;
        z-index: 912;
        min-width: 148px;
        max-width: 176px;
        padding: $padding-15 $padding-5;
        color: $color-base-00;
        background-color: $color-base-50;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        .point-name-popup-label-wrapper {
          display: flex;
          justify-content: center;
          font-size: $font-size-30;
          .point-name-popup-label {
            margin-right: $margin-10;
            white-space: pre-wrap;
            text-align: left;
          }
          .point-name-popup-del-button-wrapper {
            margin: 2px $margin-10 0 0;
            .point-name-popup-del-button {
              height: 12px;
              width: auto;
              padding-right: 12px;
            }
          }
        }
        .point-name-popup-close-button {
          position: absolute;
          top: 0;
          right: 0;
          padding: 4px 4px 0 0;
          border: none;
          text-align: center;
          width: 18px;
          height: 14px;
          font: 16px/14px Tahoma, Verdana, sans-serif;
          color: #c3c3c3;
          text-decoration: none;
          font-weight: bold;
          background: transparent;
        }
        .tail {
          position: fixed;
          z-index: 912;
          width: 0;
          height: 0;
        }
      }
    }
    .modal-map_bottom {
      .bottom-toast-message {
        position: relative;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s ease-out;
        &.show {
          opacity: 1;
          transition: opacity 0.3s ease-in;
        }
        .toast-message-wrapper {
          position: absolute;
          margin-top: -48px;
          left: 0;
          right: 0;
          z-index: 999;
          .toast-message {
            display: inline-block;
            font-size: 12px;
            padding: 4px 8px;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 8px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
          }
        }
      }

      .bottom-map {
        display: flex;
        justify-content: flex-end;
        .bottom-map_text {
          font-size: $font-size-30;
          padding: 8px 6px 0 0;
        }
        .bottom-map_str-end-point {
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          margin-right: 16px;
          .str-end-point {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            cursor: pointer;
          }
          .str-end-point--active,
          .str-end-point:hover {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-30;
            cursor: pointer;
          }
          .str-end-point--disable {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            opacity: 0.3;
            cursor: default;
          }
        }
        .bottom-map_picture-point {
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          .picture-point {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            cursor: pointer;
          }
          .picture-point--active,
          .picture-point:hover {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-30;
            cursor: pointer;
          }
        }
      }

      .bottom-atlas {
        display: flex;
        justify-content: flex-end;
        .bottom-atlas_text {
          font-size: $font-size-30;
          padding: 8px 6px 0 0;
        }
        .bottom-atlas_leaflet-auto-location {
          margin-right: 16px;
          .leaflet-auto-location {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: pointer;
          }
          .leaflet-auto-location:hover {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-30;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: pointer;
          }
          .leaflet-auto-location--disable {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            opacity: 0.3;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: default;
          }
        }
        .bottom-atlas_leaflet-location {
          margin-right: 2px;
          .leaflet-location {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: pointer;
          }
          .leaflet-location:hover,
          .leaflet-location--active {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-30;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: pointer;
          }
          .leaflet-location--disable {
            padding: 4px 2.8px 0 2.8px;
            background: $color-base-50;
            opacity: 0.3;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: default;
          }
        }
        .bottom-atlas_leaflet-location-select {
          padding: 7px 0 0 4px;
        }
      }
    }
    .modal-map_atlas-wrapper {
      position: relative;
      width: 800px;
      height: 600px;
      border-top: 2px solid $col-selected;
      .leaflet-crosshair {
        padding: 4px 2.8px 0 2.8px;
        background: $color-base-50;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        cursor: pointer;
      }
      .leaflet-crosshair:hover {
        padding: 4px 2.8px 0 2.8px;
        background: $color-base-30;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        cursor: pointer;
      }
      .l-popup_label-wrapper {
        display: flex;
        font-size: $font-size-30;
        .l-popup_label {
          margin-right: $margin-10;
        }
        .l-popup_label--del-icon {
          height: 12px;
          width: auto;
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transform: translate(0px, 0px);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .fade-enter,
  .fade-leave-to {
    transform: translateY(-100vh) translateY(0px);
  }
}
</style>
