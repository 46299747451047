import * as _ from "lodash";
import api from "@/api/api.js";

export const CaptchaStartEndPointApi = {
  namespaced: true,

  state: {
    captchaStartEndPoint: {},
  },
  mutations: {
    GET_CAPTCHA_START_END_POINT(state, payload) {
      state.captchaStartEndPoint = _.cloneDeep(payload);
    },
  },
  actions: {
    async postCaptchaStartEndPoint(context, payload) {
      const service = "postCaptchaStartEndPoint";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.captcha_start_end_point;
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },

    async deleteCaptchaStartEndPoint(context, payload) {
      const service = "deleteCaptchaStartEndPoint";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.delete(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = null;
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },

    async getCaptchaStartEndPoint(context, payload) {
      const service = "getCaptchaStartEndPoint";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = {
          start_point: response.data.start_point,
          end_point: response.data.end_point,
        };
        context.commit("GET_CAPTCHA_START_END_POINT", res["data"]);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getStateCaptchaStartEndPoint: (state) => {
      return state.captchaStartEndPoint;
    },
  },
};
