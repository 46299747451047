/**
 * 定数
 */

// 環境
export const isProduction = process.env.NODE_ENV === "production";

export const URL_LOCALHOST = "http://localhost";
//export const URL_PRODUCTION = "https://360remote.kts-telecom.com";
export const URL_PRODUCTION = "../360remote";

// 最大ファイルサイズ (5MB)
export const MAX_FILE_SIZE = 5120;
// PDF 表示可能上限 (1.5MB)
export const VIEW_UPPER_LIMIT_PDF_SIZE = 1500;

// BASE64 TAG JPG
export const BASE64_TAG_JPG = "data:image/jpeg;base64,";

// 記号
export const NAGAGURO = "・"; // 中黒
export const SPACE_ZENKAKU = "　"; // 全角
export const OMISSION = "…"; // 省略
export const COLON_ZENKAKU = "："; // コロン(全角)

/// 指摘・連絡
// 種別
export const INDICATION_INFO = [
  { name: "指摘", value: 0 },
  { name: "連絡", value: 1 },
];

// 緊急度
export const INDICATION_URGENCY = [
  { name: "低", value: 0 },
  { name: "中", value: 1 },
  { name: "高", value: 2 },
  { name: "緊急", value: 3 },
];

// 指摘ステータス
export const INDICATION_CORRECT = [
  { name: "指摘未是正", value: 0 },
  { name: "指摘是正済", value: 1 },
];

// 指摘ステータス（省略）

// export const INDICATION_SHORT_STATUS = [
export const INDICATION_SHORT_CORRECT = [
  { name: "未是正", value: 0 },
  { name: "是正済", value: 1 },
];

//コメントタイプ(仮)
export const FILE_INFO_TYPE = {
  COMMNET: { name: "コメント", key: "comment" },
  FILE: { name: "ファイル", key: "file" },
};

//ファイルタイプ
export const POST_IMAGE_TYPE = {
  JPG: { value: "jpg" },
  PDF: { value: "pdf" },
};

//パネルメニューの状態
export const PANEL_STATUS = {
  INDICATION: "indication",
  FILEUPLOAD: "file_upload",
  FILELIST: "file_list",
  ADJUST_NORTH: "adjust_north",
};

//フロア
export const FLOOR_TYPE = {
  FLOOR1: "floor1",
  FLOOR2: "floor2",
};

export const Extensions = {
  IMAGE: [ "bmp", "gif", "jpg", "jpeg", "png", ],
  MOVIE: [ "mp4", ],
  CAD: [ "bfo", "bfox", "dwg", "dxf", "ifc", "p21", "sfc", "xml", ],
  FBX: [ "fbx", ],
  LAS: [ "las", "laz", ],
  GLTF: [ "glb", "gltf", ],
  PDF: [ "pdf", ],
};

export const ViewType = {
  PLANE: 0,
  PANORAMA: 1,
  CAD2D: 2,
  CAD3D: 3,
}

export const CAMERA_CONTROL_TYPE = {
  PLANE: 0,
  PANORAMA: 1,
  CAD: 2,
}

export const TRANS_MODE = {
  NONE: "none",
  TRANSLATE : "translate",
  ROTATE: "rotate",
  SCALE: "scale"
}

/**
 * 分割数とレイアウトのマップ
 */
export const DivideLayoutDefs = {
  1: {rows: 1, cols: 1},
  2: {rows: 1, cols: 2},
  4: {rows: 2, cols: 2},
  8: {rows: 2, cols: 4},
  16: {rows: 4, cols: 4}
};