import * as _ from "lodash";

// 楕円体
const ELLIPSOID_GRS80 = 0; // GRS80
const ELLIPSOID_WGS84 = 1; // WGS84

// 楕円体ごとの長軸半径と扁平率
const GEODETIC_DATUM = {
  ELLIPSOID_GRS80: [
    6378137.0, // [GRS80]長軸半径
    1 / 298.257222101, // [GRS80]扁平率
  ],
  ELLIPSOID_WGS84: [
    6378137.0, // [WGS84]長軸半径
    1 / 298.257223563, // [WGS84]扁平率
  ],
};

// 反復計算の上限回数
const ITERATION_LIMIT = 1000;

// 楕円体ごとの長軸半径と扁平率の取得
const getGeodeticDatum = (ellipsoid) => {
  let a, ƒ;
  if (ellipsoid) {
    if (ellipsoid == ELLIPSOID_GRS80) {
      a = GEODETIC_DATUM.ELLIPSOID_GRS80[0];
      ƒ = GEODETIC_DATUM.ELLIPSOID_GRS80[1];
    } else if (ellipsoid == ELLIPSOID_GRS84) {
      a = GEODETIC_DATUM.ELLIPSOID_WGS84[0];
      ƒ = GEODETIC_DATUM.ELLIPSOID_WGS84[1];
    } else {
      return null;
    }
  } else {
    a = GEODETIC_DATUM.ELLIPSOID_GRS80[0];
    ƒ = GEODETIC_DATUM.ELLIPSOID_GRS80[1];
  }
  return [a, ƒ];
};

// 度(degree)をラジアン(radian)に変換
const radians = (degree) => {
  return degree * (Math.PI / 180);
};

// ラジアン(radian)を度(degree)に変換
const degrees = (radian) => {
  return (radian * 180) / Math.PI;
};

/*
 Vincenty法(逆解法)
 2地点の座標(緯度経度)から、距離と方位角を計算する
  :param lat1: 始点の緯度
  :param lng1: 始点の経度
  :param lat2: 終点の緯度
  :param lng2: 終点の経度
  :param ellipsoid: 楕円体
  :return: 距離と方位角
*/
export const vincentyInverse = (lat1, lng1, lat2, lng2, ellipsoid) => {
  if (_.isNil(lat1) || _.isNil(lng1) || _.isNil(lat2) || _.isNil(lng2)) {
    return null;
  }

  // 差異が無ければ0.0を返す
  if (lat1 == lat2 && lng1 == lng2) {
    return {
      distance: 0.0,
      azimuth1: 0.0,
      azimuth2: 0.0,
    };
  }

  // 計算時に必要な長軸半径(a)と扁平率(ƒ)を定数から取得し、短軸半径(b)を算出する
  // 楕円体が未指定の場合はGRS80の値を用いる
  const geodeticDatum = getGeodeticDatum(ellipsoid);
  const a = geodeticDatum[0];
  const ƒ = geodeticDatum[1];
  let b = (1 - ƒ) * a;

  // 度(degree)をラジアン(radian)に変換
  const φ1 = radians(lat1);
  const φ2 = radians(lat2);
  const λ1 = radians(lng1);
  const λ2 = radians(lng2);

  // 更成緯度(補助球上の緯度)
  const U1 = Math.atan((1 - ƒ) * Math.tan(φ1));
  const U2 = Math.atan((1 - ƒ) * Math.tan(φ2));

  const sinU1 = Math.sin(U1);
  const sinU2 = Math.sin(U2);
  const cosU1 = Math.cos(U1);
  const cosU2 = Math.cos(U2);

  // 2点間の経度差
  const L = λ2 - λ1;

  // λをLで初期化
  let λ = L;

  // 以下の計算をλが収束するまで反復する
  // 地点によっては収束しないことがあり得るため、反復回数に上限を設ける
  for (let i = 0; i < ITERATION_LIMIT; i++) {
    var sinλ = Math.sin(λ);
    var cosλ = Math.cos(λ);
    var sinσ = Math.sqrt(
      (cosU2 * sinλ) ** 2 + (cosU1 * sinU2 - sinU1 * cosU2 * cosλ) ** 2
    );
    var cosσ = sinU1 * sinU2 + cosU1 * cosU2 * cosλ;
    var σ = Math.atan2(sinσ, cosσ);
    var sinα = (cosU1 * cosU2 * sinλ) / sinσ;
    var cos2α = 1 - sinα ** 2;
    var cos2σm = cosσ - (2 * sinU1 * sinU2) / cos2α;
    var C = (ƒ / 16) * cos2α * (4 + ƒ * (4 - 3 * cos2α));
    var λʹ = λ;
    λ =
      L +
      (1 - C) *
        ƒ *
        sinα *
        (σ + C * sinσ * (cos2σm + C * cosσ * (-1 + 2 * cos2σm ** 2)));

    // 偏差が0.000000000001以下ならbreak
    if (Math.abs(λ - λʹ) <= 0.000000000001) break;
    if (i == ITERATION_LIMIT - 1) {
      // 計算が収束しなかった場合はnullを返す
      return null;
    }
  }

  // λが所望の精度まで収束したら以下の計算を行う
  let u2 = (cos2α * (a ** 2 - b ** 2)) / b ** 2;
  let A = 1 + (u2 / 16384) * (4096 + u2 * (-768 + u2 * (320 - 175 * u2)));
  let B = (u2 / 1024) * (256 + u2 * (-128 + u2 * (74 - 47 * u2)));
  let Δσ =
    B *
    sinσ *
    (cos2σm +
      (B / 4) *
        (cosσ * (-1 + 2 * cos2σm ** 2) -
          (B / 6) * cos2σm * (-3 + 4 * sinσ ** 2) * (-3 + 4 * cos2σm ** 2)));

  // 2点間の楕円体上の距離
  let s = b * A * (σ - Δσ);

  // 各点における方位角
  let α1 = Math.atan2(cosU2 * sinλ, cosU1 * sinU2 - sinU1 * cosU2 * cosλ);
  let α2 =
    Math.atan2(cosU1 * sinλ, -sinU1 * cosU2 + cosU1 * sinU2 * cosλ) + Math.PI;

  if (α1 < 0) α1 = α1 + Math.PI * 2;

  return {
    distance: s, // 距離
    azimuth1: degrees(α1), // 方位角(始点→終点)
    azimuth2: degrees(α2), // 方位角(終点→始点)
  };
};

/*
 Vincenty法(順解法)
 始点の座標(緯度経度)と方位角と距離から、終点の座標と方位角を求める
  :param lat: 緯度
  :param lng: 経度
  :param azimuth: 方位角
  :param distance: 距離
  :param ellipsoid: 楕円体
  :return: 終点の座標、方位角
*/
export const vincentyDirect = (lat, lng, azimuth, distance, ellipsoid) => {
  if (_.isNil(lat) || _.isNil(lng) || _.isNil(azimuth) || _.isNil(distance)) {
    return null;
  }

  // 計算時に必要な長軸半径(a)と扁平率(ƒ)を定数から取得し、短軸半径(b)を算出する
  // 楕円体が未指定の場合はGRS80の値を用いる
  const geodeticDatum = getGeodeticDatum(ellipsoid);
  const a = geodeticDatum[0];
  const ƒ = geodeticDatum[1];
  let b = (1 - ƒ) * a;

  // ラジアンに変換する(距離以外)
  const φ1 = radians(lat);
  const λ1 = radians(lng);
  const α1 = radians(azimuth);
  const s = distance;

  const sinα1 = Math.sin(α1);
  const cosα1 = Math.cos(α1);

  // 更成緯度(補助球上の緯度)
  const U1 = Math.atan((1 - ƒ) * Math.tan(φ1));

  const sinU1 = Math.sin(U1);
  const cosU1 = Math.cos(U1);
  const tanU1 = Math.tan(U1);

  const σ1 = Math.atan2(tanU1, cosα1);
  const sinα = cosU1 * sinα1;
  const cos2α = 1 - sinα ** 2;
  const u2 = (cos2α * (a ** 2 - b ** 2)) / b ** 2;
  const A = 1 + (u2 / 16384) * (4096 + u2 * (-768 + u2 * (320 - 175 * u2)));
  const B = (u2 / 1024) * (256 + u2 * (-128 + u2 * (74 - 47 * u2)));

  // σをs/(b*A)で初期化
  let σ = s / (b * A);

  // 以下の計算をσが収束するまで反復する
  // 地点によっては収束しないことがあり得るため、反復回数に上限を設ける
  for (let i = 0; i < ITERATION_LIMIT; i++) {
    var cos2σm = Math.cos(2 * σ1 + σ);
    var sinσ = Math.sin(σ);
    var cosσ = Math.cos(σ);
    var Δσ =
      B *
      sinσ *
      (cos2σm +
        (B / 4) *
          (cosσ * (-1 + 2 * cos2σm ** 2) -
            (B / 6) * cos2σm * (-3 + 4 * sinσ ** 2) * (-3 + 4 * cos2σm ** 2)));
    var σʹ = σ;
    σ = s / (b * A) + Δσ;

    // 偏差が0.000000000001以下ならbreak
    if (Math.abs(σ - σʹ) <= 0.000000000001) break;
    if (i == ITERATION_LIMIT - 1) {
      // 計算が収束しなかった場合はnullを返す
      return null;
    }
  }

  // σが所望の精度まで収束したら以下の計算を行う
  const x = sinU1 * sinσ - cosU1 * cosσ * cosα1;
  const φ2 = Math.atan2(
    sinU1 * cosσ + cosU1 * sinσ * cosα1,
    (1 - ƒ) * Math.sqrt(sinα ** 2 + x ** 2)
  );
  const λ = Math.atan2(sinσ * sinα1, cosU1 * cosσ - sinU1 * sinσ * cosα1);
  const C = (ƒ / 16) * cos2α * (4 + ƒ * (4 - 3 * cos2α));
  const L =
    λ -
    (1 - C) *
      ƒ *
      sinα *
      (σ + C * sinσ * (cos2σm + C * cosσ * (-1 + 2 * cos2σm ** 2)));
  const λ2 = L + λ1;

  const α2 = Math.atan2(sinα, -x) + Math.PI;

  return {
    lat: degrees(φ2), // 緯度
    lng: degrees(λ2), // 経度
    azimuth: degrees(α2), // 方位角
  };
};
