import { render, staticRenderFns } from "./VideoControl.vue?vue&type=template&id=4cd7616e&scoped=true&"
import script from "./VideoControl.vue?vue&type=script&lang=js&"
export * from "./VideoControl.vue?vue&type=script&lang=js&"
import style0 from "./VideoControl.vue?vue&type=style&index=0&id=4cd7616e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd7616e",
  null
  
)

export default component.exports