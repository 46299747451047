import {Box3, Group, MathUtils, Matrix4, Vector3} from 'three';

/**
 * 通常マトリクス
 * @type {Matrix4}
 */
const NORMAL_MATRIX = new Matrix4().makeBasis(
  new Vector3(1, 0, 0),
  new Vector3(0, 0,-1),
  new Vector3(0, 1, 0));

/**
 * XY軸反転マトリクス
 * @type {Matrix4}
 */
const REVERSE_MATRIX = new Matrix4().makeBasis(
  new Vector3(0, 0, -1),
  new Vector3(1, 0, 0),
  new Vector3(0, 1, 0));

/**
 * 3Dモデルのオフセット操作用ラッパー
 *
 * [内部構造]
 * Model3D
 * └─オフセット操作ハンドル (_translateHandle)
 *  └─回転操作ハンドル (_rotateHandle)
 *   └─ローカル座標空間  （地理／数学座標系の切替用）
 *　   └─モデル本体 中心が原点になる位置に配置
 *
 * [座標軸]
 * 画面奥を北として
 *
 * Model3D
 * ・X+ 東方向
 * ・Y+ 天頂方向
 * ・Z+ 南方向
 *
 * オフセット操作ハンドル (threeJsデフォルトの座標空間)
 *  座標軸は親（Model3D）と同じ
 *  原点はオフセット値に従い移動
 *
 * ローカル座標空間
 * ・x+ 東方向
 * ・Y+ 北方向
 * ・Z+ 天頂方向
 * 　原点が親（オフセット操作ハンドル）の原点に重なる位置に配置する
 *
 * モデル本体
 * 　座標軸は親（ローカル座標空間）と同じ
 * 　バインディングスフィアの中心が親の原点に重なる位置に配置する
 */
class Model3D extends Group {

  /**
   * コンストラクタ
   * @param obj モデル本体
   * @param props プロパティ初期値
   */
  constructor(obj, props) {
    super();

    this.isModel3D = true;

    // モデル本体
    // this._obj = obj;

    // モデルのバウンディングボックスで平面判定
    const bb = new Box3().setFromObject(obj);
    this._isPlane = bb.min.z === 0 && bb.max.z === 0;

    // オブジェクト中心のローカル座標
    const center = bb.getCenter(new Vector3());

    // ローカル座標系
    this._localCoordinateSystem = new Group();
    this._localCoordinateSystem.matrixAutoUpdate = false;
    this._localCoordinateSystem.matrix.copy(NORMAL_MATRIX);

    // サイジング用
    this._scaleHandle = new Group();
    this._scaleHandle.add(obj);
    obj.position.copy(center.negate());

    // マーカー用
    this._markerGroup = new Group();
    this._markerGroup.rotateX(Math.PI/2);
    // this._markerGroup.matrixAutoUpdate = false;
    // this._markerGroup.matrix.copy(this._localCoordinateSystem.matrix).invert();
    this._scaleHandle.add(this._markerGroup);

    // 回転操作用ハンドル
    this._rotateHandle = new Group();
    this._rotateHandle.add(this._scaleHandle)
    this._rotateHandle.rotation.order = "ZXY";

    // 位置操作用ハンドル
    this._translateHandle = new Group();
    this._translateHandle.add(this._rotateHandle);

    this._localCoordinateSystem.add(this._translateHandle);
    this.add(this._localCoordinateSystem);

    this._north = 0;
    this._east = 0;

    // プロパティ初期値を適用
    if (props) {
      this.reverseCoordinate = props.reverseCoordinate;
      this.planeRectCoordSystem = props.planeRectCoordSystem;

      this.latitude = props.latitude;
      this.longitude = props.longitude;
      this.north = props.north;
      this.east = props.east;
      this.offsetNorth = props.offsetNorth;
      this.offsetEast = props.offsetEast;
      this.offsetHeight = props.offsetHeight;
      this.offsetRoll = props.offsetRoll;
      this.offsetPitch = props.offsetPitch;
      this.offsetYaw = props.offsetYaw;
      this.scaleScalar = props.scale;

      this.updateMatrixWorld();
    }
  }

  /**
   * 平面かどうかを取得
   * @returns {boolean} 平面ならtrue
   */
  get isPlane() {
    return this._isPlane;
  }

  /**
   * 移動ハンドル
   * @returns {Group}
   */
  get translateHandle() {
    return this._translateHandle;
  }

  /**
   * 回転ハンドル
   * @returns {Group}
   */
  get rotateHandle() {
    return this._rotateHandle;
  }

  get translatedPosition() {
    return this._translateHandle.position.clone().applyMatrix4(this._localCoordinateSystem.matrix);
  }

  /**
   * スケーリングハンドル
   * @returns {Group}
   */
  get scaleHandle() {
    return this._scaleHandle;
  }

  get markerGroup() {
    return this._markerGroup;
  }

  /**
   * モデルのX,Y軸の入替ON/OFF
   * @type {boolean}
   * @private
   */
  _reverseCoordinate = false;

  /**
   * モデルのX,Y軸の入替ON/OFF取得
   * @returns {boolean}
   */
  get reverseCoordinate() {
    return this._reverseCoordinate;
  }

  /**
   * モデルのX,Y軸の入替ON/OFF設定
   * @param value X,Y軸の入替ONならtrue/OFFならfalse
   */
  set reverseCoordinate(value) {
    if (this._reverseCoordinate !== value) {
      this._reverseCoordinate = value || false;
      this._localCoordinateSystem.matrix.copy(this.reverseCoordinate ? REVERSE_MATRIX : NORMAL_MATRIX);
      this._markerGroup.matrix.copy(this._localCoordinateSystem.matrix).invert();
      this._localCoordinateSystem.updateMatrixWorld();
    }
  }

  /**
   * 直交座標の北成分取得
   * @returns {number} 直交座標の北成分
   */
  get north() {
    return this._north;
  }

  /**
   * 直交座標の北成分設定
   * @param value 直交座標の北成分
   */
  set north(value) {
    this._north = value || 0;
  }

  /**
   * 直交座標の東成分取得
   * @returns {number} 直交座標の東成分
   */
  get east() {
    return this._east;
  }

  /**
   * 直交座標の東成分設定
   * @param value 直交座標の東成分
   */
  set east(value) {
    this._east = value || 0;
  }

  /**
   * 北方向のオフセット取得
   * @returns {number} 北方向のオフセット
   */
  get offsetNorth() {
    // return -this._translateHandle.position.z;
    return this.reverseCoordinate ? this._translateHandle.position.x : this._translateHandle.position.y;
  }

  /**
   * 北方向のオフセット設定
   * @param value 北方向のオフセット
   */
  set offsetNorth(value) {
    // this._translateHandle.position.z = -value || 0;
    if (this.reverseCoordinate) {
      this._translateHandle.position.x = value || 0;
    } else {
      this._translateHandle.position.y = value || 0;
    }
  }

  /**
   * 東方向のオフセット取得
   * @returns {number} 東方向のオフセット
   */
  get offsetEast() {
    // return this._translateHandle.position.x;
    return !this.reverseCoordinate ? this._translateHandle.position.x : this._translateHandle.position.y;
  }

  /**
   * 東方向のオフセット設定
   * @param value 東方向のオフセット
   */
  set offsetEast(value) {
    // this._translateHandle.position.x = value || 0;
    if (!this.reverseCoordinate) {
      this._translateHandle.position.x = value || 0;
    } else {
      this._translateHandle.position.y = value || 0;
    }
  }

  /**
   * 上方向のオフセット取得
   * @returns {number} 上方向のオフセット
   */
  get offsetHeight() {
    // return this._translateHandle.position.y;
    return this._translateHandle.position.z;
  }

  /**
   * 上方向のオフセット設定
   * @param value 上方向のオフセット
   */
  set offsetHeight(value) {
    // this._translateHandle.position.y = value || 0;
    this._translateHandle.position.z = value || 0;
  }

  /**
   * ロール取得
   * @returns {number} ロール (deg)
   */
  get offsetRoll() {
    if (this.reverseCoordinate) {
      return -MathUtils.radToDeg(this._rotateHandle.rotation.x);
    } else {
      return MathUtils.radToDeg(this._rotateHandle.rotation.y);
    }
  }

  /**
   * ロール設定
   * @param value ロール (deg)
   */
  set offsetRoll(value) {
    if (this.reverseCoordinate) {
      this._rotateHandle.rotation.x = -MathUtils.degToRad(value || 0);
    } else {
      this._rotateHandle.rotation.y = MathUtils.degToRad(value || 0);
    }
  }

  /**
   * ピッチ取得
   * @returns {number} ピッチ (deg)
   */
  get offsetPitch() {
    if (!this.reverseCoordinate) {
      return MathUtils.radToDeg(this._rotateHandle.rotation.x);
    } else {
      return -MathUtils.radToDeg(this._rotateHandle.rotation.y);
    }
  }

  /**
   * ピッチ設定
   * @param value ピッチ (deg)
   */
  set offsetPitch(value) {
    if (!this.reverseCoordinate) {
      this._rotateHandle.rotation.x = MathUtils.degToRad(value || 0);
    } else {
      this._rotateHandle.rotation.y = -MathUtils.degToRad(value || 0);
    }
  }

  /**
   * ヨー取得
   * @returns {number} ヨー (deg)
   */
  get offsetYaw() {
    if (this.reverseCoordinate) {
      return MathUtils.radToDeg(this._rotateHandle.rotation.z);
    } else {
      return -MathUtils.radToDeg(this._rotateHandle.rotation.z);
    }
  }

  /**
   * ヨー設定
   * @param value ヨー (deg)
   */
  set offsetYaw(value) {
    if (this.reverseCoordinate) {
      this._rotateHandle.rotation.z = MathUtils.degToRad(value || 0);
    } else {
      this._rotateHandle.rotation.z = -MathUtils.degToRad(value || 0);
    }
  }

  get scaleScalar() {
    return this._scaleHandle.scale.x || 1;
  }

  set scaleScalar(value) {
    this._scaleHandle.scale.setScalar(value || 1);
  }

}

export {Model3D};
