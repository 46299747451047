import * as _ from "lodash";
import api from "@/api/api.js";

export const ConstructionSitesApi = {
  namespaced: true,

  state: {
    constructionSites: {},
  },
  mutations: {
    GET_CONSTRUCTION_SITES(state, payload) {
      state.constructionSites = _.cloneDeep(payload);
    },
  },
  actions: {
    async getConstructionSites(context, payload) {
      const service = "getConstructionSites";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;

      console.log("- API:", service, " prm:", payload, apiToken);
      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("GET_CONSTRUCTION_SITES", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", response);
      return res;
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getStateConstructionSites: (state) => {
      return state.constructionSites;
    },
  },
};
