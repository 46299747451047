<template>
  <div class="eventPanel">
    <TooltipBtn label="移動">
      <button class="btn" @click.stop="$emit('change', TRANS_MODE.TRANSLATE)">
        <v-icon color="black" size="20">fa-regular fa-arrows-up-down-left-right</v-icon>
      </button>
    </TooltipBtn>
    <div class="spacer" />
    <TooltipBtn label="回転">
      <button class="btn" @click.stop="$emit('change', TRANS_MODE.ROTATE)">
        <v-icon color="black" size="20">fa-regular fa-arrows-rotate</v-icon>
      </button>
    </TooltipBtn>
  </div>
</template>
<script>
import { TRANS_MODE } from "@/util/constants";
import TooltipBtn from "@/components/tooltip/TooltipBtn.vue";

export default {
  name: "TransModePanel",
  model: {
    prop: 'transMode',
    event: 'change'
  },
  data: () => {
    return {
      TRANS_MODE,
    };
  },
  components: {
    TooltipBtn,
  },
  props: {
    transMode: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.eventPanel {
  position: absolute;
  bottom: 128px;
  right: 64px;
  width: 32px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .btn {
    padding: 0;
    margin: 8px 0;
    background: none;
    border: none;
    width: inherit;
    opacity: 0.6;
    outline: none;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:disabled {
      opacity: 0.2;
      &:hover {
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  .spacer {
    height: 1px;
    width: 60%;
    margin: 0 20%;
    border-top: 1px solid #ccc;
  }
}
</style>
