var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"filelist",staticClass:"filelist",class:_vm.isOpen ? 'open' : null},[_c('div',{staticClass:"filelist__inner"},_vm._l((_vm.item.files),function(file){return _c('div',{key:file.file_id,staticClass:"filelist__item"},[_c('div',{on:{"click":function($event){return _vm.showDetail({
            ap_file_id: file.id,
            file_id: file.file_id,
            image_type: file.image_type,
            file_name: file.file_name,
            size: file.size
          })}}},[(file.image_type === _vm.POST_IMAGE_TYPE.JPG.value)?[_c('FileImage',{attrs:{"file_id":file.file_id,"name":file.file_name,"cp_around_photograph_id":_vm.item.cp_around_photograph_id}})]:_vm._e(),(file.image_type === _vm.POST_IMAGE_TYPE.PDF.value)?[_c('FilePdf',{attrs:{"file_id":file.file_id,"name":file.file_name,"cp_around_photograph_id":_vm.item.cp_around_photograph_id}})]:_vm._e()],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }