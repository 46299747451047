<template>
  <div ref="self">
    <slot />
  </div>
</template>
<script>

export default {
  props:{
    label:{
      type:String
    }
  },
  mounted(){
    this.init();
  },
  destroyed(){
    this.release();
  },
  methods:{
    init(){
      this.$refs.self.addEventListener('mouseenter',this.onHover);
      this.$refs.self.addEventListener('mouseleave',this.onLeave);
    },
    release(){
      if(this.$refs.self){
        this.$refs.self.removeEventListener('mouseenter',this.onHover);
        this.$refs.self.removeEventListener('mouseleave',this.onLeave);
      }
    },
    onHover(){
      const rect = this.$refs.self.getBoundingClientRect();
      const pos_x = Math.floor(rect.left + rect.width/2);
      const pos_y = Math.floor(rect.top - 16);
      this.$store.dispatch("Tooltip/show",{pos_x,pos_y,message:this.label});
    },
    onLeave(){
      this.$store.dispatch("Tooltip/close");
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
