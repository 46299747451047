<template>
  <div :id="`picture360_` + id" class="picture360 fill-height">
    <div :id="id" :ref="id" class="picture-container" @click="thisClick"></div>
    <div v-if="isViewPanorama" class="view-type"> パノラマ </div>
    <div v-if="isViewCAD" class="view-type"> 3D </div>
    <div v-if="isViewPlane" class="view-type"> 2D </div>
    <file-uploader
      :panel_status="panel_status"
      :nameId="'panel_file_upload_' + id"
      :target_area_id="`picture360_` + id"
      @onClickPanelEvent="callbackPanelEvent"
      @onFileUploadCancelEvent="onFileUploadCancel"
    />
    <video-control class="video-control" :video="video" @change="$emit('onVideoStateChange', $event)"/>
  </div>
</template>

<script>

import * as THREE from "three";
import * as _ from "lodash";
import {
  BASE64_TAG_JPG,
  CAMERA_CONTROL_TYPE,
  Extensions,
  FILE_INFO_TYPE,
  PANEL_STATUS,
  POST_IMAGE_TYPE, TRANS_MODE,
  ViewType
} from "@/util/constants";

import * as API from "@/api/api.js";
import FileUploader from "@/components/fileUploader/FileUploader";
import VideoControl from "@/components/videoControl/VideoControl";
import EXIF from "exif-js";

import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { TransformControls } from 'three/addons/controls/TransformControls.js';

import {nextTick} from "vue";
import {Model3D} from "@/three-ex/Model3D";
import {GeographicGroup} from "@/three-ex/GeographicGroup";
import {Box3, MathUtils} from "three";
import {LoaderFactory} from "@/loader/LoaderFactory";

// アイコン
const ICON_IMAGE = {
  INFO: require("@/assets/img/icon-info_blue.png"),
  FILE_PICTURE: require("@/assets/img/icon-picture.png"),
  FILE_DEFAULT: require("@/assets/img/icon-file.png"),
};
// パノラマ貼付け用の球体半径(m)
const SPHERE_RADIUS = 100;
const ZOOM_RANGE = {
  MIN: 50,
  MAX: 10000,
};
const FOV = 55;

const pdfjsLib = require("pdfjs-dist");
pdfjsLib.GlobalWorkerOptions.workerSrc = "./pdfjs-dist/build/pdf.worker.js";


export default {
  components: {
    // ProgressIndicator,
    FileUploader,
    VideoControl
  },
  /************************************************************
   * プロパティ
   ************************************************************/
  props: {
    id: {
      type: [String],
      required: true,
    },
    item: {
      type: Object,
    },
    camera_attr: {
      type: Object,
      required: false,
      default: () => {
        return {
          position: { x:0, y:1.8, z:0.01 },
          target: { x:0, y:1.8, z:0}
        };
      }
    },
    models: {
      type: Array,
    },
    zumen_id: {
      type: Number,
    },
    captcha_point_id: {
      type: Number,
    },
    zoom_value: {
      type: Number,
    },
    // パネルメニューで選択している状態
    // => null:何も選択してない / PANEL_STATUS
    panel_status: {
      type: String,
    },
    camera_control: {
      type: Number,
    },
    transProps: Object,
    transMode: String,
    transTargetId: Number
  },
  /************************************************************
   * データ
   ************************************************************/
  data() {
    return {

      CONST: {
        BASE64_TAG_JPG,
        ADD_POINT: {
          title: "コメントを入力してください",
          keyword: "内容",
          maxLength: 255,
        },
        // SHOW_TXT: {
        //   value: null,
        //   file: null,
        // },
      },
      scene: null,
      camera: null,
      renderer: null,
      element: null,
      controls: null,
      mainModel: null,
      group: null,
      geographicGroup: null,
      filesGroup: null,
      clickTimer: null,
      gpsImgDirection: 0,
      video: undefined,
      viewType: null,
      active: false,
      modelObjectMap: { },
      rID: 0,
      modelLoadingStates: { }
    };
  },
  /************************************************************
   * 計算
   ************************************************************/
  computed: {
    width: {
      cache: false,
      get() {
        return this.$el.clientWidth;
      }
    },
    height: {
      cache: false,
      get() {
        return this.$el.clientHeight;
      }
    },
    isViewPlane() {
      return this.camera_control === CAMERA_CONTROL_TYPE.PLANE;
    },
    isViewPanorama() {
      return this.camera_control === CAMERA_CONTROL_TYPE.PANORAMA;
    },
    isViewCAD() {
      return this.camera_control === CAMERA_CONTROL_TYPE.CAD;
    },
    properties() {
      return this.item ? JSON.parse(this.item.properties) : {};
    },
    informations() {
      return this.item ? this.item.informations : [];
    },
    files() {
      return this.item ? this.item.files : [];
    },
    handleName() {
      return this.$store.getters["BpLinkage/getState"].handleName;
    }
  },
  /************************************************************
   * 監視
   ************************************************************/
  watch: {
    item(nv, ov) {
      if (ov && this.modelLoadingStates[ov.cp_around_photograph_id] === 'loading') {
        this.modelLoadingStates[ov.cp_around_photograph_id] = 'aborted';
      }
      if (!nv || _.isEmpty(nv)) {
        this.clearScene();
      } else if (!ov || nv.cp_around_photograph_id !== ov.cp_around_photograph_id) {
        setTimeout(() => {
          this.clearScene();
          this.loadScene().then(
            () => {
              this.setCameraControl();
              console.log("loading scene success.");
            }
          ).catch(
            e => console.error("loading scene failed.", e)
          );
        });
      } else {
        this.addAllComments(this.informations);
        this.addAllFiles(this.files);
      }
    },
    /**
     * ズーム率
     * {ZOOM_RANGE.MIN}
     * @param newVal
     */
    zoom_value(newVal) {

      if (newVal < ZOOM_RANGE.MIN) {
        this.$emit("onZoom", ZOOM_RANGE.MIN);
        return;
      }
      if (ZOOM_RANGE.MAX < newVal) {
        this.$emit("onZoom", ZOOM_RANGE.MAX);
        return;
      }

      // FOV初期値で垂直方向の表示範囲の半分を計算
      let x = Math.tan( MathUtils.degToRad(FOV / 2) );
      const zoom = newVal / 100;
      x = x / zoom;

      // FOVを計算
      this.camera.zoom = zoom;
      this.camera.updateProjectionMatrix();

      // スプライト
      // 拡大・縮小してもマーカーのサイズを保つ
      this.scene.traverse(o=>{
        if (o.isSprite) {
          if (!o.userData.orgScale) o.userData.orgScale = o.scale.clone();
          const x = o.userData.orgScale.x / zoom;
          const y = o.userData.orgScale.y / zoom;
          const z = o.userData.orgScale.z / zoom;
          o.scale.set(x, y, z);
        }
      })
    },
    /**
     * 3Dモデル
     */
    models: {

      handler(newVal, oldVal) {
        // 削除
        oldVal.forEach(o => {
          const key = o.id;
          let n = newVal.find(n => n.id === key);
          if (!n) {
            this.geographicGroup.remove(this.modelObjectMap[key]);
            this.clearChildren(this.modelObjectMap[key]);
            delete this.modelObjectMap[key];
            if (this.modelLoadingStates[key] === 'loading') {
              this.modelLoadingStates[key] = 'aborted';
              console.log('aborted.');
            }
          }
        });

        // 新規 / 変更
        newVal.forEach(n => {
          const key = n.id;
          let add = () => {
            if (this.modelLoadingStates[key] === 'loading') {
              console.log('loading.');
              return;
            } else if (this.modelLoadingStates[key] === 'aborted') {
              this.modelLoadingStates[key] = 'loading';
              console.log('loading.');
              return;
            }

            setTimeout(async () => {
              this.modelLoadingStates[key] = 'loading';
              console.log('loading.');
              try {
                const obj = await this.load(n.id, n.file_id, n.file_type, true);
                if (obj && this.modelLoadingStates[key] === 'loading') {
                  const model = new Model3D(obj, JSON.parse(n.properties));
                  this.geographicGroup.add(model);
                  this.modelObjectMap[key] = model;
                }
              } catch(ex) {
                console.log('load ' + n.file + ' failed.', ex);
              }
              delete this.modelLoadingStates[key];
              console.log('loaded.');
            });
          };
          let old = oldVal.find(o => o.id === key);
          if (old) {
            // プロパティが変更されていたら作り直し
            if (n.properties !== old.properties) {
              this.geographicGroup.remove(this.modelObjectMap[key]);
              this.clearChildren(this.modelObjectMap[key]);
              add();
            }
          } else {
            add();
          }
        });
      },
      deep: true,
    },
    camera_attr: {
      handler(newVal) {
        this.controls.object.position.copy(newVal.position);
        this.controls.target.copy(newVal.target);
        this.controls.update();
        this.controls.object.lookAt(this.controls.target);
      },
      deep: true,
    },
    camera_control() {
      this.setCameraControl();
    },
    /**
     * 移動・回転モード変更時
     */
    transMode() {
      console.log("targetMode changed.");
      this.setTransControl();
    },
    /**
     * 移動・回転対象モデルのID変更時
     */
    transTargetId() {
      console.log("transTargetId changed.");
      this.setTransControl();
    },
    /**
     * プロパティパネルでの移動・回転を反映する
     * @param newVal 対象モデルの移動・回転
     */
    transProps(newVal) {
      if (this.transTargetId) {
        this.setTranslation(this.transControl.targetModel, newVal);
      }
    },
  },
  /************************************************************
   * ライフサイクル
   ************************************************************/
  /**
   * マウント後
   * @returns {Promise<void>}
   */
  mounted() {

    this.init();

  },
  /**
   * 廃棄前
   * @returns {Promise<void>}
   */
  beforeDestroy() {

    this.dispose();

  },
  /************************************************************
   * メソッド
   ************************************************************/
  methods: {
    /**
     * コンポーネント初期化
     */
    init() {

      this.initRenderer();
      this.initScene();
      this.initCamera();
      this.initOrbitControls();
      this.initTransControl();
      this.render();

    },
    /**
     * コンポーネント破棄
     */
    dispose() {

      // 描画ストップ
      cancelAnimationFrame(this.rID);

      this.disposeOrbitControls();
      this.disposeScene();
      this.disposeRenderer();

      this.transControl.removeEventListener( 'change',  this.onTrans);
      this.transControl.removeEventListener( 'dragging-changed', this.onTransDragging);

    },
    /**
     * レンダラー初期化
     */
    initRenderer() {

      // レンダラーの作成
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setPixelRatio(devicePixelRatio);
      this.renderer.setSize(this.width, this.height);
      this.element = this.renderer.domElement;
      this.$refs[this.id].appendChild(this.element);

      addEventListener("resize", this.resizeRenderer);

    },
    /**
     * レンダーのリサイズ
     */
    resizeRenderer() {
      this.renderer.setPixelRatio(devicePixelRatio);
      this.renderer.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
    },
    /**
     * シーン初期化
     * @returns {Promise<void>}
     */
    async initScene() {

      // シーン作成
      this.scene = new THREE.Scene();

      // 光源
      this.directionLight = new THREE.DirectionalLight();
      this.scene.add(this.directionLight);
      this.directionLight.position.set(100, 100, 0);

      const light = new THREE.AmbientLight(0xFFFFFF, 0.2);
      this.scene.add(light);

      // ルートグループ
      this.group = new THREE.Group();
      this.scene.add(this.group);

      // 中心線
      this.centerLineGroup = this.createCenterLine();
      this.scene.add(this.centerLineGroup);
      this.centerLineGroup.position.set(0, 0, 0);
      this.centerLineGroup.renderOrder = 2;
    },
    /**
     * カメラ初期化
     */
    initCamera() {
      // カメラ
      this.camera = new THREE.PerspectiveCamera(
          FOV,
          this.width / this.height,
          0.001,
          100000
      );

      // this.cameraCarrier = new THREE.Group();
      // this.cameraCarrier.add(this.camera);
      // this.scene.add(this.cameraCarrier);
      this.scene.add(this.camera);

      this.camera.position.copy(this.camera_attr.position);
    },
    /**
     * カメラコントロール初期化
     */
    initOrbitControls() {

      this.controls = new OrbitControls(this.camera, this.element);
      // this.controls.enableDamping = true; // 視点操作のイージングをONにする
      // this.controls.dampingFactor = 0.2; // 視点操作のイージングの値
      // this.controls.enableZoom = true;
      this.controls.screenSpacePanning = true;
      this.controls.listenToKeyEvents(window);
      this.controls.addEventListener('change', () => {
        this.$emit("onChange", {
          position: this.controls.object.position.clone(),
          target: this.controls.target.clone(),
          floor: this.id
        });
      });
      this.controls.enabled = false;
      this.$el.addEventListener('mouseover', this.onPointerEnter);
      this.$el.addEventListener('mouseout', this.onPointerLeave);

    },
    /**
     * 移動・回転・縮小コントロール初期化
     */
    initTransControl() {

      this.transControl = this.createTransControl();
      this.transControl.setSpace("local");
      this.scene.add(this.transControl);
    },
    /**
     * シーン破棄
     */
    disposeScene() {

      if (this.scene) {
        this.clearChildren(this.scene);
        this.scene = null;
      }

    },
    /**
     * レンダラー破棄
     */
    disposeRenderer() {

      removeEventListener("resize", this.resizeRenderer);

    },
    /**
     * カメラコントロール破棄
     */
    disposeOrbitControls() {

      removeEventListener('keydown', this.onKeyDown);
      this.$el.removeEventListener('wheel', this.scrollZoom);

    },
    /**
     * 指定のオブジェクトの子をクリア
     * メモリリーク対策
     * @param obj
     */
    clearChildren(obj) {
      // メモリリーク対策
      // 子孫込みでdispose
      const clearFunc = (o) => {
        while (0 < o?.children?.length) {
          let c = o.children[0];
          if (c.geometries) {
            c.geometries.dispose();
          }
          if (c.material) {
            if (c.material.map && c.material.map.isTexture) {
              c.material.map.dispose();
            }
            c.material.dispose();
          }
          clearFunc(c);
          c.removeFromParent();
        }
      };
      clearFunc(obj);
    },
    /**
     * 対象ファイル読込みシーン作成
     * @returns {Promise<void>}
     */
    async loadScene() {

      const { cp_around_photograph_id, file_id, file_type, panorama_flg } = this.item;
      console.log('loadScene called. id=' + this.id + "file_id=" + file_id + "file_type=" + file_type);

      const fileType = file_type ? file_type.toLowerCase() : undefined;

      // 3Dモデルグループ (地理座標系)
      this.geographicGroup = new GeographicGroup();
      this.group.add(this.geographicGroup);

      // CADファイル
      if ([ ...Extensions.CAD, ...Extensions.FBX, ...Extensions.LAS, ...Extensions.GLTF].includes(fileType)) {

        const key = cp_around_photograph_id;
        if (this.modelLoadingStates[key] === 'loading') {
          return;
        } else if (this.modelLoadingStates[key] === 'aborted') {
          this.modelLoadingStates[key] = 'loading';
          return;
        }

        this.modelLoadingStates[key] = 'loading';

        try {
          const obj = await this.load(cp_around_photograph_id, file_id, fileType, false);
          if (obj) {
            if (this.modelLoadingStates[key] === 'loading') {
              // モデルのバウンディングボックスで平面判定
              const bb = new Box3().setFromObject(obj);
              const isPlane = bb.min.z === 0 && bb.max.z === 0;

              if (isPlane) {
                // 平面
                this.mainModel = obj;
                this.viewType = ViewType.CAD2D;
              } else {
                // 立体
                this.mainModel = new Model3D(obj, this.properties);
                this.viewType = ViewType.CAD3D;
              }
            } else {
              this.clearChildren(obj);
              delete this.modelLoadingStates[key];
              return;
            }
          }
        } catch(ex) {
          console.log('load ' + n.file + ' failed.', ex);
        }
        delete this.modelLoadingStates[key];
        console.log('loaded.');

      } else {
        // 画像をテクスチャとして読み込み
        const texture = await this.createTexture(file_id, file_type);
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          depthWrite: false
        });

        if (panorama_flg) {
          // パノラマ
          this.gpsImgDirection = await this.getGpsImgDirection(texture.image);
          const geometry = new THREE.SphereGeometry(SPHERE_RADIUS, 60, 40);
          material.map.wrapS = THREE.RepeatWrapping;
          material.map.repeat.x = -1;
          material.side = THREE.BackSide;
          const sphere = new THREE.Mesh(geometry, material);
          sphere.rotateX(Math.PI / 2);
          sphere.rotateY(MathUtils.degToRad(- this.gpsImgDirection + 90));
          this.mainModel = new Model3D(sphere, this.properties);
          this.viewType = ViewType.PANORAMA;
        } else {
          // 平面
          let { width, height } = texture.image;
          if (texture.isVideoTexture) {
            width = texture.image.videoWidth;
            height = texture.image.videoHeight;
          }
          const geometry = new THREE.PlaneGeometry( 100, 100 * height / width );
          this.mainModel = new THREE.Mesh(geometry, material);
          this.viewType = ViewType.PLANE;
        }
      }

      if ([ViewType.PANORAMA, ViewType.CAD3D].includes(this.viewType)) {
        this.geographicGroup.addBase(this.mainModel);

        // 平面以外ではグリッド作成
        const groupBB = new THREE.Box3().setFromObject(this.group);
        const gridSize = groupBB.max.distanceTo(groupBB.min);
        const minPartSize = gridSize < 100 ? 1 : 5;
        let i = 0, step = 0;
        do {
          i++;
          step = Math.ceil(gridSize / (minPartSize * i));
        } while (30 < step);

        this.gridHelper = new THREE.GridHelper(gridSize, step, 'yellow');
        this.gridHelper.visible = false;
        this.gridHelper.renderOrder = 3;
        this.geographicGroup.add(this.gridHelper);

        // 方位
        // World座標でZ軸マイナス方向が北
        this.gridHelper.add(this.createDirectionText(gridSize / 2));

      } else {
        // 平面
        this.group.add(this.mainModel);
      }
      this.mainModel.updateMatrixWorld();

      // カメラ位置
      if (ViewType.PANORAMA === this.viewType) {
        this.camera.position.set(0, 0, 0.01);
      } else {
        this.camera.position.set(0, 0, this.calcDistance(this.mainModel));
      }

      // 指摘・連絡グループ
      this.infosGroup = new THREE.Group();
      if (this.mainModel.markerGroup) {
        this.mainModel.markerGroup.add(this.infosGroup);
      } else {
        this.mainModel.add(this.infosGroup);
      }

      // ファイル貼り付けグループ
      this.filesGroup = new THREE.Group();
      if (this.mainModel.markerGroup) {
        this.mainModel.markerGroup.add(this.filesGroup);
      } else {
        this.mainModel.add(this.filesGroup);
      }

      this.mainModel.updateMatrixWorld();

      // 全部のコメントを設置
      this.addAllComments(this.informations);

      // 全部のファイルを設置
      this.addAllFiles(this.files);

      // ズーム倍率、ビュータイプ通知
      nextTick(() => {
        this.$emit("onZoom", 100);
        this.$emit("onViewTypeChange", {
          id: this.id,
          viewType: this.viewType
        });
      });
    },
    /**
     * シーンをクリア
     */
    clearScene() {

      this.mainModel = null;
      this.clearChildren(this.group);
      this.clearVideo();
    },
    /**
     *
     * @param obj
     * @param newVal
     */
    setTranslation(obj, newVal) {
      console.log("setTranslation called. newVal.reverseCoordinate:" + newVal.reverseCoordinate);
      obj.reverseCoordinate = newVal.reverseCoordinate;
      obj.latitude = newVal.latitude;
      obj.longitude = newVal.longitude;
      obj.offsetNorth = newVal.offsetNorth;
      obj.offsetEast = newVal.offsetEast;
      obj.offsetHeight = newVal.offsetHeight;
      obj.offsetRoll = newVal.offsetRoll;
      obj.offsetYaw = newVal.offsetYaw;
      obj.offsetPitch = newVal.offsetPitch;
      obj.scaleScalar = newVal.scale;

      if (this.mainModel && this.item.cp_around_photograph_id === this.transTargetId && this.isViewPanorama) {
        const position = this.mainModel.translatedPosition;
        this.gridHelper.position.copy(position);
        this.geographicGroup.position.copy(position.clone().negate());
      }
    },
    /**
     *
     * @param cp_around_photograph_id
     * @param fileId
     * @param fileType
     * @param removeLight
     * @returns {Promise<null>}
     */
    async load(cp_around_photograph_id, fileId, fileType, removeLight) {

      const loader = new LoaderFactory().create(fileType);
      const url = API.END_POINT + API.aclPath({cp_around_photograph_id: cp_around_photograph_id}) + "get3DModelData";

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          loader.load(url, obj => {
            if (!loader.noScaling) {
              obj.scale.setScalar(0.001);
            }
            if (loader.isModelUpYAxis) {
              obj.rotateX(Math.PI / 2);
            }
            // モデルに含まれている光源の除去
            if (removeLight) {
              obj.traverse(o => {
                if (o.isLight) o.visible = false;
              });
            }
            resolve(obj);
          }, p => {
            // if (p.lengthComputable) {
            //   console.log('loaded/total:' + p.loaded + '/' + p.total);
            // }
          }, err => {
            console.error(err);
            reject(err);
          });
        }, 0);
      });
    },

    /**
     * 対象オブジェクトの全体がビュー内に収まるカメラ位置（z座標）の計算
     * @param obj 対象オブジェクト
     * @returns {*} z座標
     */
    calcDistance(obj) {
      // fov = ビューの下から上までのカメラの視錐台の垂直視野
      const { fov, aspect } = this.camera;
      let w = Math.sin(MathUtils.degToRad(fov / 2)) * aspect;
      let fovH = MathUtils.radToDeg(2 * Math.asin(w));

      // バウンディングボックスから幅と高さを計算
      const bb = new THREE.Box3().setFromObject(obj, true);
      let c = bb.getCenter(new THREE.Vector3());
      let bs = bb.getBoundingSphere(new THREE.Sphere(c));
      let r = bs.radius;
      let distance = r / Math.sin(MathUtils.degToRad(Math.max(fov, fovH) / 2)) - c.z;
console.log("r:" + r);
console.log("w:" + w)
console.log("c.z:" + c.z)
console.log("fov:" + fov);
console.log("fovH:" + fovH);
console.log("distance:" + distance);
new THREE.Sphere
      return distance;
    },
    /**
     *
     * @param image
     * @returns {Promise<unknown>}
     */
    getGpsImgDirection(image) {
      return new Promise((resolve, reject) => {
        EXIF.getData(image, function () {
          try {
            const tag = EXIF.getTag(this, 'GPSImgDirection');
            const value = tag ? tag['numerator'] / tag['denominator'] : 0;
            resolve(value);
          } catch (e) {
            reject(e);
          }
        });
      });
    },
    // setInitialOrbit(){
    //   let sphericalTarget = new THREE.Spherical(1, Math.PI / 2 - pitch, yaw)
    //   let target = new THREE.Vector3().setFromSpherical(sphericalTarget)
    // },

    onPointerEnter() {
      this.controls.enabled = true;
    },
    onPointerLeave() {
      this.controls.enabled = false;
    },
    // マウスホイールによる360写真の拡大縮小
    scrollZoom(event) {
      event.stopPropagation();
      const { wheelDelta } = event;

      let k = 10;
      if (2000 <= this.zoom_value) {
        k = 1000;
      } else if (200 <= this.zoom_value) {
        k = 100;
      }

      let delta = wheelDelta < 0 ? -k : k;
console.log("this.zoom_value:" + this.zoom_value);
console.log("delta:" + delta);
      this.$emit("onZoom", this.zoom_value + delta);
    },
    setCameraControl() {
      this.$el.removeEventListener('wheel', this.scrollZoom);
      if (!this.controls) {
        return;
      }
      if (this.isViewPlane) {
        this.controls.enableRotate = false;
        this.controls.enablePan = true;
        this.controls.enableZoom = false;
        this.controls.panSpeed = 1;
        this.controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
        this.controls.mouseButtons.MIDDLE = THREE.MOUSE.DOLLY;
        this.controls.mouseButtons.RIGHT = THREE.MOUSE.PAN;
        this.controls.target.set(0, 0, 0);
        this.$el.addEventListener('wheel', this.scrollZoom);
        addEventListener('keydown', this.onKeyDown);
      } else if (this.isViewPanorama) {
        this.controls.rotateSpeed = -0.5; // 視点変更の速さ
        this.controls.enableRotate = true;
        this.controls.enablePan = false;
        this.controls.enableZoom = false;
        this.controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
        this.controls.mouseButtons.MIDDLE = THREE.MOUSE.NONE;
        this.controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
        this.controls.target.set(0, 0, 0);
        // this.mainModel.getWorldPosition(this.controls.target);
        this.$el.addEventListener('wheel', this.scrollZoom);
        if (this.mainModel) {
          const position = this.mainModel.translatedPosition;
          this.gridHelper.position.copy(position);
          this.geographicGroup.position.copy(position.clone().negate());
        }
        addEventListener('keydown', this.onKeyDown);
      } else if (this.isViewCAD) {
        this.controls.rotateSpeed = -0.5; // 視点変更の速さ
        this.controls.enableRotate = true;
        this.controls.enablePan = true;
        this.controls.enableZoom = false;
        this.controls.panSpeed = 1;
        this.controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
        this.controls.mouseButtons.MIDDLE = THREE.MOUSE.DOLLY;
        this.controls.mouseButtons.RIGHT = THREE.MOUSE.PAN;
        this.controls.target.set(0, 0, 0);
        this.$el.addEventListener('wheel', this.scrollZoom);
        addEventListener('keydown', this.onKeyDown);
      }
      this.controls.update();
console.log("id:" + this.id + " :" + this.controls.enablePan);
    },
    onKeyDown(event) {

      if (!this.controls.enabled) return;

      const deltaRotate = MathUtils.degToRad(1);

      switch (event.code) {
        case "KeyS":
          this.$emit("onZoom", this.zoom_value - 50);
          break;
        case "KeyW":
          this.$emit("onZoom", this.zoom_value + 50);
          break;
        case "KeyA":
          if (this.isViewPanorama) {
            this.rotateCamera(0, deltaRotate);
          } else {
            dispatchEvent(new KeyboardEvent("keydown", {code: this.controls.keys.LEFT}));
          }
          break;
        case "KeyD":
          if (this.isViewPanorama) {
            this.rotateCamera(0, -deltaRotate);
          } else {
            dispatchEvent(new KeyboardEvent("keydown", {code: this.controls.keys.RIGHT}));
          }
          break;
        case this.controls.keys.UP:
          if (this.isViewPanorama) {
            this.rotateCamera(deltaRotate, 0);
          }
          break;
        case this.controls.keys.BOTTOM:
          if (this.isViewPanorama) {
            this.rotateCamera(-deltaRotate, 0);
          }
          break;
        case this.controls.keys.LEFT:
          if (this.isViewPanorama) {
            this.rotateCamera(0, deltaRotate);
          }
          break;
        case this.controls.keys.RIGHT:
          if (this.isViewPanorama) {
            this.rotateCamera(0, -deltaRotate);
          }
          break;
      }
    },
    /**
     * カメラ位置を回転
     * @param dp 上下方向の回転角 (ラジアン)
     * @param dt 左右方向の回転角 (ラジアン)
     */
    rotateCamera(dp, dt) {
      const position = new THREE.Vector3().setFromSphericalCoords(
          this.controls.getDistance(),
          this.controls.getPolarAngle() + dp,
          this.controls.getAzimuthalAngle() + dt
      ).add(this.controls.target);

      this.camera.position.copy(position);
      this.controls.update();
    },
    //カメラの回転をリセット
    resetOrbitControls(){
      this.controls.reset();
    },
    // 再描画
    render() {

      this.rID = requestAnimationFrame(this.render);

      const bb = new THREE.Box3().setFromObject(this.group);
      const k = new THREE.Vector3().subVectors(bb.max, bb.min).length();

      this.centerLineGroup.visible = !_.isNil(this.transTargetId) && this.isViewPanorama;

      if (this.geographicGroup) {
        this.geographicGroup.updatePositions();
      }

      if (this.gridHelper) {
        this.gridHelper.visible = !_.isNil(this.transTargetId);
      }

      const gamepads = navigator.getGamepads();
      if (gamepads && gamepads.length) {
        const gamepad = gamepads[0];
        if (gamepad) {
          const { index, id, buttons, axes } = gamepad;
          console.log("gamepad.index:" + index);
          console.log("gamepad.id:" + id);
          console.log("gamepad.buttons.length:" + buttons.length);
          console.log("gamepad.axes.length:" + axes.length);

          if (0 < axes.length) {

            // AXIS 0 左スティック 左右
            this.camera.rotateY(2 * Math.PI * Math.round(axes[0] * 100) / 100 / 360);
            // AXIS 1 左スティック 上下
            this.camera.rotateX(2 * Math.PI * Math.round(axes[1] * 100) / 100 / 360);
            // AXIS 2 右スティック 左右
            this.camera.rotateZ(2 * Math.PI * Math.round(axes[2] * 100) / 100 / 360);
            // AXIS 5 右スティック 上下
            this.camera.translateZ(k / 30 * Math.round(axes[5] * 100) / 100);

          }
        }
      }

      this.renderer.render(this.scene, this.camera);
    },
    async thisClick(e) {
      clearTimeout(this.clickTimer);
      if (e.detail === 1) {
        // single click
        console.log("- Single click", this.panel_status);

        // パネルが選択されている場合のイベント
        if (this.panel_status === PANEL_STATUS.INDICATION) {
          //指摘追加
          await this.newIndication(e);
          // ステータスをクリア
          this.$emit("onChangePanelStatus", null);
          return;
        } else if (this.panel_status === PANEL_STATUS.FILEUPLOAD) {
          await this.newFile(e);
          return;
        } else if (this.panel_status === PANEL_STATUS.ADJUST_NORTH) {
          return;
        } else if (this.transTargetId !== null) {
          return;
        }

        // ステータスをクリア
        this.$emit("onChangePanelStatus", null);

        //await sleepだとイベント自体がキャンセルされない、
        //コメントを削除した後にポップアップが表示されてしまう。
        //setTimeoutでイベント自体をキャンセルする
        this.clickTimer = setTimeout(() => {
          this.showDetail(e);
        }, 500);
      } else if (e.detail === 2) {
        // double click
        clearTimeout(this.clickTimer);
      }

      // this.isMouseDown = false;

      const _camera = { ...this.camera };
      this.$emit("onClick", { camera: _camera, select_floor_id: this.id });
    },
    getRayHit(e, children) {

      const rect = e.target.getBoundingClientRect();

      // スクリーン上のマウス位置を取得する
      let mouseX = e.clientX - rect.left;
      let mouseY = e.clientY - rect.top;

      // 取得したスクリーン座標を-1〜1に正規化する（WebGLは-1〜1で座標が表現される）
      mouseX =  (mouseX / rect.width ) * 2 - 1;
      mouseY = -(mouseY / rect.height) * 2 + 1;

      const pos = new THREE.Vector2(mouseX, mouseY);
      const ray = new THREE.Raycaster();
      ray.setFromCamera(pos, this.camera);
console.log("vec2:" + JSON.stringify(pos));
      // 交差判定
      // 引数は取得対象となるMeshの配列を渡す。以下はシーン内のすべてのオブジェクトを対象に。
      return {
        ray,
        objs: ray.intersectObjects(children),
      };
    },
    showDetail(e) {
      const children = [ ...this.infosGroup.children, ...this.filesGroup.children ];
      const rayInfo = this.getRayHit(e, children);
      if (rayInfo.objs.length > 0) {
        const item = { ...rayInfo.objs[0].object.userData.item };

        // コメントを開く
        if (item.type === FILE_INFO_TYPE.COMMNET.key) {
          this.editIndication(item);
        }
        // 複数ファイル対応
        else if (item.type === FILE_INFO_TYPE.FILE.key) {
          const files = rayInfo.objs.map(obj => {
            return { ...obj.object.userData.item };
          }).filter(obj=>Object.keys(obj).length !== 0);

          this.getApFile(item.file_id, item.cp_around_photograph_id);

          this.$store.dispatch("FileViewer/showImages", {
            cp_around_photograph_id: this.item.cp_around_photograph_id,
            files
          });
        }
      }
    },
    async getApFile(file_id, cp_around_photograph_id){
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      await this.$store.dispatch("ApFile/getApFileImage",{api_token, file_id, cp_around_photograph_id});
    },
    callbackPanelEvent() {
      this.$emit("onClickPanelEvent", {
        cp_around_photograph_id: this.item.cp_around_photograph_id
      });
    },
    onFileUploadCancel() {
      console.log("onFileUploadCancel!");
      this.$emit("onChangePanelStatus", null);
    },
    /**
     * 新規ファイル
     * クリックしたポイントに選択したファイルを配置
     * @param e
     * @returns {Promise<string>}
     */
    //パネルイベント用にクリック座標を取得
    async newFile(e) {
      const rayInfo = this.getRayHit(e, [this.mainModel, ...this.mainModel.children]);
      if (rayInfo.objs.length === 0) return 'none';

      const pos = rayInfo.objs[2 <= rayInfo.objs ? 1 : 0].point.clone();
      const localPosition = this.infosGroup.worldToLocal(pos);
      console.log("pos:" + JSON.stringify(pos));
      console.log("localPosition:" + JSON.stringify(localPosition));

      //左右のどちらかを設定
      await this.$store.dispatch("PanelFileUploads/setPanelSide", this.id);
      //座標を設定
      await this.$store.dispatch("PanelFileUploads/setPosition", localPosition);
      this.callbackPanelEvent();
    },
    // 新規：連絡・指摘入力
    async newIndication(e) {
      const rayInfo = this.getRayHit(e, [this.mainModel, ...this.mainModel.children]);
      if (rayInfo.objs.length === 0) return 'none';

      let cs = this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      let construction_site_id = cs[0]["id"]; // TODO 見直し:現場選択実装
      let ids = {
        construction_site_id,
        zumen_id: this.zumen_id,
        captcha_point_id: this.captcha_point_id,
      };

      // 表示
      // let direction = rayInfo.ray.ray.direction;
      // const pos = await this.setDummy(direction);

      const pos = rayInfo.objs[2 <= rayInfo.objs ? 1 : 0].point.clone();
      const localPosition = this.infosGroup.worldToLocal(pos);
console.log("pos:" + JSON.stringify(pos));
console.log("localPosition:" + JSON.stringify(localPosition));
      let res = await this.$root.$children[0].$refs["popupInfo"].call(
          {
            cp_around_photograph_id: this.item.cp_around_photograph_id,
            point_x: localPosition.x,
            point_y: localPosition.y,
            point_z: localPosition.z,
            created_by: this.handleName,
          },
          ids
      );
      if (res.result !== true) return;

      this.informations.push(res.data);


      //
      // ストアから同期を検出して新しいコメントを自動的に追加するが表示されるまでが遅いので、
      // 表示上ここで描画をしておく
      //
      // 指定アイコンの取得
      let getIcon = (res) => {
        if (_.isNil(res.fileData)) return ICON_IMAGE.INFO;
        if (~res.fileData.type.indexOf("image")) return ICON_IMAGE.FILE_PICTURE;
        return ICON_IMAGE.FILE_DEFAULT;
      };

      const mesh = this.createPoint(getIcon(res), localPosition);
      mesh.userData.item = { value: res.value, file: res.fileData };

      this.infosGroup.add(mesh);
    },
    // 編集：指摘・連絡事項
    async editIndication(item) {
      // const rayInfo = this.getRayHit(e, this.infosGroup.children);
      let cs = this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      let construction_site_id = cs[0]["id"]; // TODO 見直し:現場選択実装
      let ids = {
        construction_site_id,
        zumen_id: this.zumen_id,
        captcha_point_id: this.captcha_point_id,
      };

      if (item) {
        let ap_information_id = item.id;
        let information;
        _.forEach(this.informations, (val) => {
          if (val.id === ap_information_id) {
            information = val;
          }
        });
        // 表示
        if (information) {
          const res = await this.$root.$children[0].$refs["popupInfo"].call(
              information,
              ids
          );

          // if (res.result !== true) return;
          //
          // let index = this.informations.indexOf(information);
          // if (0 <= index) {
          //   this.informations.splice(index, 1);
          // }
          // if (res.action === "update") {
          //   this.informations.push(res.data);
          // }
          // this.addAllComments(this.informations);
        }
      }
    },
    createPointText(char, position) {
      const canvas = document.createElement( 'canvas' );
      const context = canvas.getContext( '2d' );

      const size = 256;
      const scale = window.devicePixelRatio;
      canvas.width = Math.floor(size * scale);
      canvas.height = Math.floor(size * scale);

      context.scale(scale, scale);

      context.beginPath();
      context.globalAlpha = 0.7;
      context.strokeStyle = '#000000';
      context.lineWidth = 4.0;

      context.fillRect(  0 ,  0 , size , size );
      context.stroke();

      context.beginPath();
      context.globalAlpha = 1.0;
      context.fillStyle = '#FFFFFF';
      context.font= '128px Arial';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(char, 128, 138);


      const texture = new THREE.Texture( canvas );
      texture.needsUpdate = true;
      const material = new THREE.SpriteMaterial({
        map: texture,
        sizeAttenuation: false,
        depthFunc: THREE.AlwaysDepth,
      });
      const sprite = new THREE.Sprite(material);
      sprite.position.copy(position);//.setLength(10);
      sprite.scale.set(0.05, 0.05, 1);
      return sprite;
    },
    // 球面常にオブジェクトを生成する
    createPoint(icon, position) {
      const texture = new THREE.TextureLoader().load(icon);
      const materialImg = new THREE.SpriteMaterial({
        map: texture,
        sizeAttenuation: false,
        depthFunc: THREE.AlwaysDepth,
      });

      const sprite = new THREE.Sprite(materialImg);
      sprite.position.copy(position);
      sprite.scale.set(0.05 / this.camera.zoom, 0.05 / this.camera.zoom, 1);
      return sprite;
    },
    //全てのPointを削除
    removeAllPoint() {
      this.infosGroup.clear();
    },
    //全てのPointを削除infosGroup
    removeAllFilePoint() {
      this.filesGroup.clear();
    },
    //全てのPointを設置
    addAllComments(informations) {
      if (!informations || !this.infosGroup) return;
      //全部削除
      this.removeAllPoint();
      //全部を再追加する
      informations.forEach((info) => {
        this.putComment(info);
      });
    },
    addAllFiles(files) {
      if (!files || !this.filesGroup) return;
      //全部削除
      this.removeAllFilePoint();
      //全部を再追加する
      files.forEach((file) => {
        this.putFile(file);
      });
    },
    //コメントを画面に追加
    putComment(info) {
      let mesh = this.createPoint(ICON_IMAGE.INFO, {
        x: info.point_x,
        y: info.point_y,
        z: info.point_z,
      });
      mesh.userData.item = {
        value: info.title,
        file: null,
        id: info.id,
        point_x: info.point_x,
        point_y: info.point_y,
        point_z: info.point_z,
        type: FILE_INFO_TYPE.COMMNET.key,
      };
      this.infosGroup.add(mesh);
    },
    putFile(file) {
      let icon = ICON_IMAGE.FILE_DEFAULT;
      if (file.image_type === POST_IMAGE_TYPE.JPG.value)
        icon = ICON_IMAGE.FILE_PICTURE;
      if (file.image_type === POST_IMAGE_TYPE.PDF.value)
        icon = ICON_IMAGE.FILE_DEFAULT;

      let mesh = this.createPoint(icon, {
        x: file.point_x,
        y: file.point_y,
        z: file.point_z,
      });
      mesh.userData.item = {
        value: file.image_type,
        file: null,
        file_id: file.file_id,
        ap_file_id: file.id,
        point_x: file.point_x,
        point_y: file.point_y,
        point_z: file.point_z,
        image_type: file.image_type,
        file_name: file.file_name,
        size: file.size,
        type: FILE_INFO_TYPE.FILE.key,
      };
      this.filesGroup.add(mesh);
    },
    async createTexture(file_id, file_type) {
      console.log("createTexture called. file_id=" + file_id + "file_type=" + file_type);
      console.log("this.item.scp_around_photograph_id:" + this.item.cp_around_photograph_id);
      let texture;
      if (file_type) {
        const type = file_type.toLowerCase();
        if (Extensions.IMAGE.includes(type)) {
          texture = await this.createImageTexture(file_id);
        } else if (Extensions.MOVIE.includes(type)) {
          texture = await this.createVideoTexture(file_id);
        } else if (Extensions.PDF.includes(type)) {
          texture = await this.createPdfTexture(file_id);
        }
      }
      return texture;
    },
    async createImageTexture(file_id) {
      return new Promise((resolve, reject) => {
        // base64画像からtextureを作成
        const image = new Image();
        if (this.$store.getters["BpLinkage/getState"].isLink) {
          image.src = API.END_POINT + API.aclPath({cp_around_photograph_id: this.item.cp_around_photograph_id}) + "get360Image";
        } else {
          this.getPhotograps(file_id).then(res => {
            image.src = this.CONST.BASE64_TAG_JPG + res;
          }).catch(e => {
            reject(e);
          });
        }
        const texture = new THREE.Texture();
        texture.image = image;
        image.onload = function () {
          texture.needsUpdate = true;
          resolve(texture);
        };
        image.onerror = function (e) {
          reject(e);
        }
      });
    },
    async createVideoTexture(file_id) {
console.log("createVideoTexture called.");
console.log("this.item.cp_around_photograph_id:" + this.item.cp_around_photograph_id);

      return new Promise(( resolve, reject) => {
        const video = document.createElement('video');
        video.autoplay = false;
        this.video = video;

        const texture = new THREE.VideoTexture(video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBAFormat;

        video.addEventListener("error", function(e) {
          reject(e);
        });
        video.addEventListener("loadedmetadata", function () {
          console.log(texture.image.videoWidth, texture.image.videoHeight);
          resolve(texture);
        }, false);

        if (this.$store.getters["BpLinkage/getState"].isLink) {
          video.src = API.END_POINT + API.aclPath({cp_around_photograph_id: this.item.cp_around_photograph_id}) + "get360Image";
        } else {
          video.src = "http://localhost:8080/api/get360Video?file_id=" + file_id;
        }
      });
    },
    clearVideo() {
      if (this.video) {
        this.video.remove();
        this.video = undefined;
      }
    },
    async createPdfTexture(file_id) {
      return new Promise((resolve, reject) => {

        let src;
        if (this.$store.getters["BpLinkage/getState"].isLink) {
          src = API.END_POINT + API.aclPath({cp_around_photograph_id: this.item.cp_around_photograph_id}) + "get360Image";
        } else {
          this.getPhotograps(file_id).then(res => {
            src = this.CONST.BASE64_TAG_JPG + res;
          }).catch(e => {
            reject(e);
          });
        }

        const loadingTask = pdfjsLib.getDocument(src);
        loadingTask.promise.then(function(pdf) {
          // you can now use *pdf* here
          console.log('PDF loaded');

          // Fetch the first page
          let pageNumber = 1;
          pdf.getPage(pageNumber).then(function(page) {
            console.log('Page loaded');

            let scale = 8;
            const viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            const canvas = document.createElement( 'canvas' );
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const texture = new THREE.CanvasTexture(canvas);

            // Render PDF page into canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            const renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              console.log('Page rendered');
              texture.needsUpdate = true;
              resolve(texture);
            });
          }, function(e) {
            reject(e);
          });
        });

        // // pdfからtextureを作成
        // const image = new Image();
        // if (this.$store.getters["BpLinkage/getState"].isLink) {
        //   image.src = API.END_POINT + API.aclPath({cp_around_photograph_id: this.item.cp_around_photograph_id}) + "get360Image";
        // } else {
        //   this.getPhotograps(file_id).then(res => {
        //     image.src = this.CONST.BASE64_TAG_JPG + res;
        //   }).catch(e => {
        //     reject(e);
        //   });
        // }
        // const texture = new THREE.CanvasTexture();
        // texture.canvas = image;
        // image.onload = function () {
        //   texture.needsUpdate = true;
        //   resolve(texture);
        // };
        // image.onerror = function (e) {
        //   reject(e);
        // }
      });
    },
    async getPhotograps(file_id) {
      if (_.isNil(file_id) && !this.item.cp_around_photograph_id) return "";
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = { api_token, file_id, cp_around_photograph_id:this.item.cp_around_photograph_id };
      const vrImage = await this.$store.dispatch("ViewImageApi/get360Image", params);
      if (vrImage["status"] !== 200) return "";
      return vrImage["data"]["image"];
    },
    async get3dModelData(cp_around_photograph_id, file_id, type) {
      if (_.isNil(file_id) && !cp_around_photograph_id) return "";
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = { api_token, file_id, cp_around_photograph_id };
      const response = await this.$store.dispatch("ViewImageApi/get3dModelData", params);
      if (response.data && response.data.geometries) {
        response.data.geometries = this.toBufferedGeometries(response.data.geometries, type);
      }
      return response.data;
    },
    createCenterLine() {
      const group = new THREE.Group({ visible: true });
      const curve = new THREE.EllipseCurve(
          0,  0,            // ax, aY
          SPHERE_RADIUS, SPHERE_RADIUS,           // xRadius, yRadius
          0,  2 * Math.PI,  // aStartAngle, aEndAngle
          false,            // aClockwise
          0                 // aRotation
      );
      const points = curve.getPoints( 50 );
      const geometry = new THREE.BufferGeometry().setFromPoints( points );
      const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );

      const horizonLine = new THREE.Line(geometry, material);
      group.add(horizonLine);
      horizonLine.rotateX( Math.PI / 2);

      const verticalLine = new THREE.Line(geometry, material);
      group.add(verticalLine);
      verticalLine.rotateY(Math.PI / 2);

      return group;
    },
    createDirectionText(size) {
      const group = new THREE.Group();
      group.add(this.createPointText("北", new THREE.Vector3(0, 0, -size)));
      group.add(this.createPointText("東", new THREE.Vector3(size, 0, 0)));
      group.add(this.createPointText("南", new THREE.Vector3(0, 0, size)));
      group.add(this.createPointText("西", new THREE.Vector3(-size, 0, 0)));
      group.add(this.createPointText("上", new THREE.Vector3(0, size, 0)));
      group.add(this.createPointText("下", new THREE.Vector3(0, -size, 0)));
      return group;
    },
    createTransControl() {
      return new TransformControls(this.camera, this.element);
    },

    setTransControl() {
console.log("this.transTargetId = " + this.transTargetId);
console.log("setTransControl called.");
      // if (this.isViewPanorama) return;

      let target = null;
      if (!_.isNil(this.transTargetId)) {
        if (this.item.cp_around_photograph_id === this.transTargetId) {
          target = this.mainModel;
        } else {
          target = this.modelObjectMap[this.transTargetId];
        }
      }
      if (target) {
        this.transControl.enabled = true;
        if (this.transMode === TRANS_MODE.TRANSLATE) {
          this.transControl.object = target.translateHandle;
        } else if (this.transMode === TRANS_MODE.ROTATE) {
          this.transControl.object = target.rotateHandle;
        }
        this.transControl.targetModel = target;
        this.transControl.visible = true;
        this.transControl.addEventListener( 'change', this.onTrans);
        this.transControl.addEventListener( 'dragging-changed', this.onTransDragging);
      } else {
        this.transControl.enabled = false;
        this.transControl.object = undefined;
        this.transControl.targetModel = undefined;
        this.transControl.visible = false;
        this.transControl.removeEventListener( 'change', this.onTrans);
        this.transControl.removeEventListener( 'dragging-changed', this.onTransDragging);
      }

      this.transControl.mode = this.transMode;
    },
    onTrans() {
      if (this.transTargetId) {
        const model = this.transControl.targetModel;
        const trans = _.clone(this.transProps);

        trans.offsetNorth = model.offsetNorth;
        trans.offsetEast = model.offsetEast;
        trans.offsetHeight = model.offsetHeight;
        trans.offsetRoll = model.offsetRoll;
        trans.offsetPitch = model.offsetPitch;
        trans.offsetYaw = model.offsetYaw;
        trans.scale = model.scaleScalar;
        this.$emit('onTrans', trans);
      }
    },
    onTransDragging(event) {
      this.controls.enabled = !event.value;
    },
    videoPlay() {
      if (this.video) {
        this.video.play();
      }
    },
    videoPause() {
      if (this.video) {
        this.video.pause();
      }
    }
  },

};

</script>

<style scoped lang="scss">
.picture360 {
  position: relative;
}

.picture-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.progress {
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

.video-control {
  position: absolute;
  bottom: 64px;
}

.view-type {
  position: absolute;
  top: 4px;
  right: 1px;
  padding: 2px 8px 0 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

</style>
