<template>
  <div id="app">
    <v-app>
      <!-- top of router-view -->
      <router-view />
      <!-- bottom of router-view -->
      <PopupInfo ref="popupInfo" />
      <PopupInput ref="popupInput" />
      <PopupTxt ref="popupTxt" />
      <PopupLanLng ref="popupLanLng" />
      <ProgressBar />
      <Toast />
      <ImageViewer />
      <Tooltip />
    </v-app>
  </div>
</template>

<script>
import PopupInfo from "@/components/popup/PopupInfo.vue";
import PopupInput from "@/components/popup/PopupInput.vue";
import PopupTxt from "@/components/popup/PopupTxt.vue";
import PopupLanLng from "@/components/popup/PopupLanLng.vue";
import ProgressBar from "@/components/progressbar/ProgressBar";
import Toast from "@/components/toast/Toast";
import ImageViewer from "@/components/imageViewer/ImageViewer";
import Tooltip from "@/components/tooltip/Tooltip";

export default {
  name: "app",
  components: {
    PopupInfo,
    PopupInput,
    PopupTxt,
    PopupLanLng,
    ProgressBar,
    Toast,
    ImageViewer,
    Tooltip,
  },
  data() {
    return {};
  },
  async created() {
    const queryParams = this.$route.query;
    if (queryParams) {
      // 現場＝ファイルキャビネット（チームアイテム）
      // 図面＝フォルダに相当
      await this.$store.dispatch("BpLinkage/setLink", {
        tmItemId: Number.parseInt(queryParams.tm_itm.toString()) || 0,
        folderId: Number.parseInt(queryParams.folder.toString()) || 0,
        handleName: queryParams.handle_name,
      });
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

//#nav {
//  // padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}

</style>
