import axios from "axios";
import * as _ from "lodash";
import { getURLParams, asyncApi, apiCache } from "./apiCache";
// import { getUnixTime } from "date-fns";
import store from "@/store";
import { isProduction, URL_LOCALHOST, URL_PRODUCTION } from "@/util/constants";

/*
|200|正常終了||
|400|不正なリクエスト（パラメータは不正です）|このエラーが出た場合は、パラメータに問題がありますので修正してください。|
|404|見つからない|APIがそもそも存在しないか、データが見つかりません。通常の遷移でこのエラーが返るのはバグなので修正してください。|
|500|サーバーエラー|サーバーエラーなので、報告して頂けると助かります。|
|503|メンテナンス中|メンテナンス中です。 `/` に移動してください。|
*/

const getPoint = function(document_domain, port) {
  let url;
  if (document_domain === `localhost`) {
    // url = URL_LOCALHOST + `:` + port;
    url = URL_LOCALHOST + `:` + document.location.port;
  } else if (document_domain === `0.0.0.0`) {
    url = `http://0.0.0.0:` + port;
  } else {
    url = URL_PRODUCTION;
  }

  if (isProduction) {
    // memo:"yarn serve"起動時は、サーバーと通信する
    url = URL_PRODUCTION;
  }
  return url;
};

const END_POINT = getPoint(document.domain, `80`) + "/api/";

const request = (requestConfigOption) => {
  const onSuccess = (response) => {
    //ここでステータスを判定
    if (response.status === 200) {
      return {
        result: true,
        ...response,
      };
    } else {

      alert("通信エラーが発生しました。");
      return {
        result: false,
        ...response,
      };
    }
  };

  const onError = (error) => {
    console.error("onError !! ", error);
    alert("通信エラーが発生しました。");
    return {
      result: false,
      ...error.response,
    };
  };

  return axios(requestConfigOption)
    .then(onSuccess)
    .catch(onError);
};

const editHeaderAddAuth = (headers, authToken) => {
  if (authToken) {
    headers["Authorization"] = authToken;
  }
};

// アクセスコントロール用
// basepageを通している場合、チームアイテム，フォルダ等でアクセス可／不可を判定できるようにパスに埋め込む
// 各APIのパラメータ名(paramsのキー)が統一されていることを前提としている
const aclPath = (params) => {
  // basepage経由の場合
  if (store.getters["BpLinkage/getState"].isLink) {
    const { construction_site_id, zumen_id, captcha_point_id, cp_around_photograph_id } = params;

    // パノラマ画像
    if (cp_around_photograph_id) {
      const captchaPoint = store.getters["CaptchaPointApi/getStateCaptchaPoint"];
      const files = captchaPoint.around_photographs;
      const file = _.find(files, { 'id' : cp_around_photograph_id });
      return `files/${file.bp_id}/`;
    }

    // 撮影ポイント
    if (captcha_point_id) {
      const captchaPoints = store.getters["CaptchaPointApi/getStateCaptchaPoints"];
      const captchaPoint = _.find(captchaPoints, { 'id' : captcha_point_id });
      if (captchaPoint.bp_id) {
        return `folders/${captchaPoint.bp_id}/`;
      }
    }

    // 図面
    if (zumen_id) {
      const zumens = store.getters["ZumensApi/getStateZumens"];
      const zumen = _.find(zumens, { 'id' : zumen_id });
      if (zumen.bp_id) {
        return `folders/${zumen.bp_id}/`;
      }
    }

    // 現場
    if (construction_site_id) {
      const sites = store.getters["ConstructionSitesApi/getStateConstructionSites"];
      const site = _.find(sites, { 'id' : construction_site_id })
      return `teamItems/${site.bp_id}/`;
    }
  }

  return "";
};

export default {
  get: async (service, params, authToken) => {
    //キャッシュ
    const URL = END_POINT + aclPath(params) + service;
    let isCache = await apiCache.get(URL, params);
    if (isCache) {
      return isCache.response;
    }

    let req = {
      method: "GET",
      url: URL,
      params: params,
      headers: {},
    };
    editHeaderAddAuth(req["headers"], authToken);
    let response = await request(req);

    //成功したらキャッシュに追加
    if (response.result) {
      //キャッシュに追加
      apiCache.set(URL, _.cloneDeep(response), params);
    }
    return response;
  },

  getFromCache: async (service, params, authToken, sec) => {
    //キャッシュ ※主な用途:画像
    const URL = END_POINT + aclPath(params) + service;
    let cache = await apiCache.get(URL, params, sec);
    if (cache) {
      return cache.response;
    }

    let req = {
      method: "GET",
      url: URL,
      params: params,
      headers: {},
    };
    editHeaderAddAuth(req["headers"], authToken);

    let response;

    //ロード中
    const key = req.url.concat(`?${getURLParams(req.params)}`);
    if (
      asyncApi.isLoading({ id: key }) ||
      asyncApi.isLoaded({ id: key })
    ) {
      response = await asyncApi.getResponse({ id: key });
    } else {
      asyncApi.setLoading({ id: key });
      response = await request(req);
      asyncApi.update({ id: key, res: response });
    }

    //成功したらキャッシュに追加
    if (response.result) {
      //キャッシュに追加
      apiCache.set(URL, _.cloneDeep(response), params);
    }
    return response;
  },

  post: (service, params, authToken) => {
    let req = {
      method: "POST",
      url: END_POINT + aclPath(params) + service,
      data: params,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'Accept': 'application/json'
      },
    };
    editHeaderAddAuth(req["headers"], authToken);
    return request(req);
  },
  put: (service, id, params, authToken) => {
    let req = {
      method: "PUT",
      // url: END_POINT + service,
      url: END_POINT + service + "/" + id,
      data: params,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    editHeaderAddAuth(req["headers"], authToken);
    return request(req);
  },
  patch: (service, params, authToken) => {
    let req = {
      method: "PUT",
      url: END_POINT + aclPath(params) + service,
      //patchはdataではなくparamsに設定する -> bodyにはいるらしい
      // params: params,
      data: params,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    editHeaderAddAuth(req["headers"], authToken);
    return request(req);
  },
  delete: (service, params, authToken) => {
    let req = {
      method: "DELETE",
      url: END_POINT + aclPath(params) + service,
      //TODO ここもpatchと同様にparamsかもしれない
      // data: params,
      params: params,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    editHeaderAddAuth(req["headers"], authToken);
    return request(req);
  },
};

export { END_POINT, getPoint, aclPath };
