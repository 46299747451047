import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

window.addEventListener('dragover', function(ev){
  ev.dataTransfer.dropEffect = 'none';
  ev.preventDefault();
}, false);
window.addEventListener('drop', function(ev){
  ev.preventDefault();
  ev.stopPropagation();
}, false);

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
