export const buildings = [
  {
    building_id: 1,
    building_name: "テストビル",
    floors: [
      {
        floor_id: 1,
        floor_name: "1f",
        sections: [
          {
            section_id: 1,
            section_name: "フロア全体",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
    ],
  },
  {
    building_id: 2,
    building_name: "テストビル２",
    floors: [
      {
        floor_id: 1,
        floor_name: "B1",
        sections: [
          {
            section_id: 1,
            section_name: "東側",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 2,
            section_name: "西側",
            section_image_id: 2,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
      {
        floor_id: 2,
        floor_name: "1Faaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        sections: [
          {
            section_id: 1,
            section_name: "北側aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 2,
            section_name: "東側",
            section_image_id: 2,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 3,
            section_name: "南側",
            section_image_id: 3,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 4,
            section_name: "西側",
            section_image_id: 4,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
      {
        floor_id: 2,
        floor_name: "1Faaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        sections: [
          {
            section_id: 1,
            section_name: "北側aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 2,
            section_name: "東側",
            section_image_id: 2,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 3,
            section_name: "南側",
            section_image_id: 3,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 4,
            section_name: "西側",
            section_image_id: 4,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
      {
        floor_id: 2,
        floor_name: "1Faaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        sections: [
          {
            section_id: 1,
            section_name: "北側aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 2,
            section_name: "東側",
            section_image_id: 2,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 3,
            section_name: "南側",
            section_image_id: 3,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 4,
            section_name: "西側",
            section_image_id: 4,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
      {
        floor_id: 2,
        floor_name: "1Faaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        sections: [
          {
            section_id: 1,
            section_name: "北側aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 2,
            section_name: "東側",
            section_image_id: 2,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 3,
            section_name: "南側",
            section_image_id: 3,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 4,
            section_name: "西側",
            section_image_id: 4,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
      {
        floor_id: 2,
        floor_name: "1Faaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        sections: [
          {
            section_id: 1,
            section_name: "北側aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            section_image_id: 1,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 2,
            section_name: "東側",
            section_image_id: 2,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 3,
            section_name: "南側",
            section_image_id: 3,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
          {
            section_id: 4,
            section_name: "西側",
            section_image_id: 4,
            section_image:
              "TODO フロア画像はローカルに読み込まれているものとする。",
            picture_point: [{}],
          },
        ],
      },
    ],
  },
];

export const pic_point_detail = [
  {
    image: "@/assets/img/kentiku_genba_1.jpeg",
    note: "365日24時間いつでもOK",
  },
  {
    image: "@/assets/img/kentiku_genba_2.jpeg",
    note:
      "1回25分でサクッとできる1回25分でサクッとできる1回25分でサクッとできる1回25分でサクッとできる1回25分でサクッとできる",
  },
  {
    image: "@/assets/img/kentiku_genba_3.jpg",
    note: "1回25分でサクッとできる",
  },
  {
    image: "@/assets/img/kentiku_genba_4.jpeg",
    note: "1回25分でサクッとできる",
  },
  {
    image: "@/assets/img/kentiku_genba_5.jpg",
    note: "1回25分でサクッとできる",
  },
  // {
  //   image: "@/assets/img/kentiku_genba_5.jpg",
  //   note: "1回25分でサクッとできる",
  // },
  // {
  //   image: "@/assets/img/kentiku_genba_5.jpg",
  //   note: "1回25分でサクッとできる",
  // },
];
