<template>
  <div class="indication mw-100 mh-100 d-flex flex-column">
    <div class="indication-header w-100 h-100 d-flex flex-wrap flex-shrink-1 flex-grow-1 justify-center">
      <div class="mr-4">
        <div class="menu__datetimepicker-title">日付</div>
        <div class="menu__datetimepicker ">
          <Datepicker
              v-model="selectDay"
              format="yyyy/MM/dd"
              :language="lang"
              :highlighted="highlighted"
              input-class="datetimepicker__input"
              calendar-class="datetimepicker__calender"
          />
        </div>
      </div>
      <div class="mr-4">
        <div class="menu__indication-title">指摘・連絡</div>
        <div class="menu__indication">
          <CheckboxGroup
              :name="'indication'"
              :list="CONST.INDICATION_INFO"
              @onChange="changeIndication"
          />
        </div>
      </div>
      <div class="mr-4">
        <div class="menu__urgency-title">緊急度</div>
        <div class="menu__urgency">
          <Select
              name="urgency"
              :items="CONST.INDICATION_URGENCY"
              itemName="name"
              :defaultOption="2"
              :selectedIdx="-1"
              :isFirstOptionGray="true"
              @onChange="changeUrgency"
          />
        </div>
      </div>
      <div>
        <div class="menu__indication-status-title">ステータス</div>
        <div class="menu__indication-status">
          <CheckboxGroup
              :name="'correct'"
              :list="CONST.INDICATION_SHORT_CORRECT"
              @onChange="changeCorrect"
          />
        </div>
        </div>
    </div>

    <div class="indication-list w-100 h-100 overflow-y-auto flex-fill">
      <div v-if="filteredIndications.length === 0">
        <div class="indication-area--non-data">データがありません</div>
      </div>
      <div v-else class="d-flex flex-wrap justify-center">
        <CardIndication
            v-for="item in filteredIndications"
            :key="item.id"
            :item="item"
            :mapName="mapName"
            :construction_site_id="construction_site_id"
            :zumen_id="zumen_id"
            :captcha_point_id="captcha_point_id"
        />
      </div>
    </div>
  </div>
</template>

<script>

import * as _ from "lodash";
import Datepicker from "vuejs-datepicker";
import {ja} from "vuejs-datepicker/dist/locale";
import {format} from "date-fns";
import {
  INDICATION_INFO,
  INDICATION_URGENCY,
  INDICATION_SHORT_CORRECT,
} from "@/util/constants.js";
import * as Uobj from "@/util/obj.js";
import CheckboxGroup from "@/components/checkbox/CheckboxGroup.vue";
import Select from "@/components/select/Select.vue";
import CardIndication from "@/components/card/CardIndication.vue";

export default {
  components: {
    Datepicker,
    CheckboxGroup,
    Select,
    CardIndication,
  },
  data: () => {
    return {
      CONST: {INDICATION_INFO, INDICATION_URGENCY, INDICATION_SHORT_CORRECT},
      // カレンダー
      lang: ja,
      highlighted: {
        dates: [],
      },

      // 絞り込み後
      filteredIndications: [],

      // 絞り込み条件：日付
      _selectedDay: "",
      // 絞り込み条件：指摘／連絡
      checkedIndications: [],
      // 絞り込み条件：緊急度
      selectUrgency: -1,
      // 絞り込み条件：ステータス
      checkedCorrect: [],

      // マップ名
      mapName: "",
    };
  },
  props: {
    // 指摘・
    indications: {
      type: Array,
      default: [],
    },
    construction_site_id: {
      type: Number,
      default: 0,
      required: true,
    },
    zumen_id: {
      type: Number,
      default: 0,
      required: true,
    },
    captcha_point_id: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  watch: {
    indications: {
      handler(newVal) {
        this.extractIndicationDays(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    selectDay: {
      cache: false,
      get: function () {
        return this._selectedDay;
      },
      set: function (val) {
        console.log("selectDay newVal = " + val);
        this._selectedDay = val;
        this.filter();
      },
    },
  },
  methods: {
    // カレンダー：指摘・連絡がある日にマークをつける
    extractIndicationDays(indications) {
      if (_.isArray(indications)) {
        const dates = indications.map(i => new Date(i['created_at']));
        this.highlighted.dates = dates;
        if (dates.length) {
          this.selectDay = dates[0];//format(dates[0], "yyyy-MM-dd");
        }
      } else {
        this.highlighted.dates = [];
      }
    },
    // カレンダー：日付選択
    // 選択：指摘・連絡
    changeIndication(param) {
      let checkedList = [];
      _.forEach(param.values, (val) => {
        if (val.checked) {
          checkedList.push(val.value);
        }
      });
      this.checkedIndications = checkedList;
      this.filter();
    },
    // 選択：緊急度
    changeUrgency(param) {
      this.selectUrgency = param.idx >= 0 ? param.value.value : -1;
      this.filter();
    },
    // 選択：ステータス
    changeCorrect(param) {
      let checkedList = [];
      _.forEach(param.values, (val) => {
        if (val.checked) {
          checkedList.push(val.value);
        }
      });
      this.checkedCorrect = checkedList;
      this.filter();
    },
    // 並び替え：カード
    async filter() {
      // 絞り込み
      let searchedItems = [];
      if (this.selectDay) {
        _.forEach(this.indications, (val) => {
          if (
              this.isSelectDate(val) &&
              this.hasCheckedIndications(val) &&
              this.isSelectUrgency(val) &&
              this.hasCheckedCorrect(val)
          ) {
            searchedItems.push(val);
          }
        });
      }
      console.log("this.filteredIndications.length:" + this.filteredIndications.length)
      console.log("searchedItems.length:" + searchedItems.length)
      this.filteredIndications = searchedItems;
    },
    // 日付
    isSelectDate(item) {
      console.log("create_at" + format(new Date(item['created_at']), "yyyy-MM-dd"))
      console.log("selectDay" + format(new Date(this.selectDay), "yyyy-MM-dd"))
      // 選択した日付と一致する"指摘・連絡事項"が１件以上存在する場合は絞り込む
      return (
          format(new Date(item['created_at']), "yyyy-MM-dd") ===
          format(new Date(this.selectDay), "yyyy-MM-dd")
      );
    },
    // 指摘
    hasCheckedIndications(item) {
      if (Uobj.hasList(this.checkedIndications)) {
        let findItem = this.checkedIndications.find(
            (checkedItem) => checkedItem === item.indication
        );
        return !_.isNil(findItem);
      } else {
        // 空の時は一律、true
        return true;
      }
    },
    isSelectUrgency(item) {
      return this.selectUrgency === -1 || this.selectUrgency === item.urgency;
    },
    hasCheckedCorrect(item) {
      if (Uobj.hasList(this.checkedCorrect)) {
        let findItem = this.checkedCorrect.find(
            (checkedItem) => checkedItem === item.correct
        );
        return !_.isNil(findItem);
      } else {
        // 空の時は一律、true
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../style/config.scss";

.indication {

  position:absolute;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  padding-top: 16px;
  font-size: 10pt;

  .menu__datetimepicker {
    .vdp-datepicker {
      .vdp-datepicker__calendar {
        top: 24px;
        background-color: white;
        height: 320px;
      }
    }
  }

  .indication-header {
    margin: 8px 28px 8px 16px;

    .menu__datetimepicker-title {
      padding-left: 4px;
      text-align: left;
    }

    .menu__indication-title {
      padding-left: 4px;
      text-align: left;
    }

    .menu__urgency-title {
      padding-left: 4px;
      text-align: left;
    }

    .menu__indication-status-title {
      padding-left: 4px;
      text-align: left;
    }

    .menu__datetimepicker {
      width: 102px;
      padding: 5px 0 0 0;

      .datetimepicker__input {
        border: solid 1px;
        max-width: 96px;
        height: 22px;
        border-radius: 8px;
        font-size: 16px;
        padding: 2px 6px;
        margin: 1px 2px;
        outline: none;
        color: #666;
        &:hover {
          color: #000;
          cursor: pointer;
        }
      }
    }

    .menu__indication {
      width: 108px;
    }

    .menu__urgency {
      padding-top: 5px;

      select {
        border: solid 1px;
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 1px;
      }
    }

    .menu__indication-status {
      width: 132px;
    }
  }

  .indication-area--non-data {
    font-size: $font-size-40;
    color: $color-base-25;
    margin-top: $padding-30;
  }
}

</style>
