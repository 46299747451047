<template>
  <v-container v-if="video" class="background" >
    <v-layout column>
      <v-flex>
        <v-slider dense
            v-model="currentTime"
            :min="0"
            :max="video.duration"
            :step="0.0001"
            @change="applyClickedTime"
        ></v-slider>
      </v-flex>
<!--    <div class="seek-bar-outer">-->
<!--      <div class="seek-bar-inner"></div>-->
<!--    </div>-->
      <v-flex shrink>
        <v-container>
          <v-layout row>
            <v-flex>
              <v-container>
                <v-layout row>
                  <v-flex>
                    <TooltipBtn label="再生" v-if="!playing">
                      <v-icon class="icon" color="white" @click="play">fa-regular fa-play</v-icon>
                    </TooltipBtn>
                    <TooltipBtn label="一時停止" v-if="playing">
                      <v-icon class="icon" color="white" @click="pause">fa-regular fa-pause</v-icon>
                    </TooltipBtn>
<!--                    <TooltipBtn label="リプレイ" :v-if="false">-->
<!--                      <v-icon class="icon" color="white" @click="replay">fa-regular fa-rotate-right</v-icon>-->
<!--                    </TooltipBtn>-->
                  </v-flex>
                  <v-flex>
                    <span>{{ dispTime }}/{{ dispDuration }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
            <v-flex>
              <v-container>
                <v-layout row>
                  <v-flex>
                    <TooltipBtn label="音量" v-if="!video.muted">
                      <v-icon class="icon" color="white" @click="muteOn">fa-regular fa-volume</v-icon>
                    </TooltipBtn>
                    <TooltipBtn label="ミュート" v-if="video.muted">
                      <v-icon class="icon" color="white" @click="muteOff">fa-regular fa-volume-slash</v-icon>
                    </TooltipBtn>
                  </v-flex>
                  <v-flex align-self-start>
                    <v-slider dense v-model="video.volume" :min="0" :max="1" step="0.01"></v-slider>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TooltipBtn from "@/components/tooltip/TooltipBtn";
export default {
  name: "VideoControl",
  components: {TooltipBtn},
  data: () => {
    return {
      playing: false,
      currentTime: 0,
      duration: NaN,
      muted: false,
      volume: 18,
    };
  },
  props: {
    video: HTMLVideoElement
  },
  mounted() {
    if (this.video) {
      this.updateState();
    }
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        // 各種イベントハンドラ登録
        if (oldVal) {
          oldVal.onplay = null;
          oldVal.onpause = null;
          oldVal.ontimeupdate = null;
          oldVal.onvolumechange = null;
          oldVal.onmute = null;
        }
        if (newVal) {
          newVal.onplay = this.updateState;
          newVal.onpause = this.updateState;
          newVal.ontimeupdate = this.updateState;
          newVal.onvolumechange = this.updateState;
          newVal.onmute = this.updateState;
          newVal.currentTime = 0;
        }
        console.log("video changed.");
        this.updateState();
      }
    }
  },
  computed: {
    dispTime: function() {
      return this.currentTime ? this.currentTime.toFixed(2) : '';
    },
    dispDuration: function() {
      return this.duration ? this.duration.toFixed(2) : '';
    },
    isCompleted: function() {
      return this.duration === this.currentTime;
    },
  },
  methods: {
    play() {
      if (this.video?.paused) {
        this.video.play();
      }
    },
    pause() {
      if (!this.video?.paused) {
        this.video?.pause();
      }
    },
    replay() {
      if (this.video?.paused) {
        this.video.currentTime = 0;
        this.video.play();
      }
    },
    muteOff() {
      if (this.video?.muted) {
        this.video.muted = false;
      }
    },
    muteOn() {
      if (!this.video?.muted) {
        this.video.muted = true;
      }
    },
    updateState() {
      this.playing = !this.video?.paused;
      this.currentTime = this.video?.currentTime;
      this.duration = this.video?.duration;
      this.muted = this.video?.muted;
      this.volume = this.video?.volume;

      this.$emit("change", {
        "playing": this.playing
      });
    },
    applyClickedTime(value) {
console.log("new currentTime:" + value);
      this.video.currentTime = value;
    }
  }
}
</script>

<style lang="scss" scoped>

  .background {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .icons > .icon {
    margin: 8px;
    width: 24px;
    height: 24px;
    opacity: 0.7;
    cursor: pointer;
  }
  .icons > .icon:hover {
    opacity: 1.0;
  }
  .seek-bar-outer {
    height: 16px;
  }

  span{
    color: rgba(255, 255, 255, 0.7);
  }

</style>
