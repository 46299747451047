// お問い合わせ
export const MAIL_CONTACT = {
  to: "supports@basepage.com",
};

// 指摘・連絡事項
export const MAIL_INDICATION = {
  subject: "【basepage 360°臨場システム】連絡・指摘事項のリンク共有",
  body: `━━━━━━━━━━━━
basepgae 360°臨場システム
■指摘・連絡事項■
━━━━━━━━━━━━
リンクを他の人と共有する場合、指摘・連絡事項を表示するには、basepgaeのアカウントがあり、このプロジェクトにアクセスできる必要があることに注意してください。

【連絡・指摘事項URL】
$$url
【メッセージ】
$$message

※このメールは、指摘・連絡事項をお知らせするためにお送りしました。返信することはできません。`,
};
