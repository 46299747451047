var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imageViewer",class:_vm.isShow ? 'enable' : null},[(_vm.isShow)?_c('div',{staticClass:"background"}):_vm._e(),_c('div',{ref:"imageView",staticClass:"imageView",class:_vm.showin ? 'show' : null},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"filename"},[_vm._v(" "+_vm._s(_vm.file_name)+" ")]),_c('TooltipBtn',{attrs:{"label":"閉じる"}},[_c('button',{staticClass:"close btn",on:{"click":function($event){return _vm.close()}}},[_c('img',{staticClass:"btn_image",attrs:{"src":_vm.icon.close,"alt":"close"}})])])],1),_c('div',{staticClass:"main"},[(_vm.loadingFlg)?_c('div',{staticClass:"loading"},[_c('vue-loading',{attrs:{"type":"spin","color":"#ccc","size":{ width: '100px', height: '100px' }}})],1):_c('div',{staticClass:"image"},[(
            _vm.files[_vm.file_index] &&
              _vm.files[_vm.file_index].type === _vm.POST_IMAGE_TYPE.JPG.value
          )?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.files[_vm.file_index].src},on:{"load":_vm.onLoadEmbed}})]):_vm._e(),(
            _vm.isShowPDF(_vm.files[_vm.file_index]) &&
              _vm.files[_vm.file_index] &&
              _vm.files[_vm.file_index].type === _vm.POST_IMAGE_TYPE.PDF.value
          )?_c('div',{staticClass:"pdf"},[_c('embed',{staticClass:"pdf-file",attrs:{"src":_vm.files[_vm.file_index].src},on:{"load":_vm.onLoadEmbed}})]):_vm._e(),(
            _vm.files[_vm.file_index] &&
              _vm.files[_vm.file_index].type === _vm.POST_IMAGE_TYPE.PDF.value &&
              !_vm.isShowPDF(_vm.files[_vm.file_index])
          )?_c('div',{staticClass:"pdf-msg"},[_vm._v(" "+_vm._s(_vm.onLoadEmbed())+" "),_vm._m(0)]):_vm._e()])]),_c('div',{staticClass:"footer"},[_c('TooltipBtn',{attrs:{"label":"前"}},[_c('button',{staticClass:"prev btn",class:_vm.getLeftArrowClass,attrs:{"disabled":_vm.disableClick},on:{"click":_vm.prev}},[_c('img',{staticClass:"btn_image",attrs:{"src":require("@/assets/img/icon-arrow-left.png"),"alt":"prev"}})])]),_c('TooltipBtn',{attrs:{"label":"ダウンロード"}},[(
            _vm.files[_vm.file_index] &&
              _vm.files[_vm.file_index].type === _vm.POST_IMAGE_TYPE.JPG.value
          )?_c('a',{staticClass:"download btn",attrs:{"href":_vm.files[_vm.file_index].src,"download":"image.jpg"}},[_c('img',{staticClass:"btn_image",attrs:{"src":_vm.icon.download,"alt":"download"}})]):_vm._e()]),_c('TooltipBtn',{attrs:{"label":"ダウンロード"}},[(
            _vm.files[_vm.file_index] &&
              _vm.files[_vm.file_index].type === _vm.POST_IMAGE_TYPE.PDF.value
          )?_c('a',{staticClass:"download btn",attrs:{"href":_vm.files[_vm.file_index].src,"download":"doc.pdf"}},[_c('img',{staticClass:"btn_image",attrs:{"src":_vm.icon.download,"alt":"download"}})]):_vm._e()]),_c('TooltipBtn',{attrs:{"label":"削除"}},[_c('button',{staticClass:"delete btn",on:{"click":_vm.del}},[_c('img',{staticClass:"btn_image",attrs:{"src":require("@/assets/img/icon-panel-delete.png"),"alt":"delete"}})])]),_c('TooltipBtn',{attrs:{"label":"次"}},[_c('button',{staticClass:"next btn",class:_vm.getRightArrowClass,attrs:{"disabled":_vm.disableClick},on:{"click":_vm.next}},[_c('img',{staticClass:"btn_image",attrs:{"src":require("@/assets/img/icon-arrow-right.png"),"alt":"next"}})])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_vm._v(" ファイルサイズが大きすぎるため、プレビューできません"),_c('br'),_vm._v(" ファイルをダウンロードしてご確認ください ")])
}]

export { render, staticRenderFns }