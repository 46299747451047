<template>
  <div class="component" v-if="showFlg" @click.self="close">
    <div class="dialog rounded">
      <div class="content d-flex flex-column">
        <div class="flex-grow-0 flex-shrink-0 mb-2">
          <!-- 検索テキスト -->
          <div class="d-flex justify-end">
<!--            <v-select-->
<!--                v-model="divideCount"-->
<!--                :items="divideLayoutItems"-->
<!--                label="表示数"-->
<!--            ></v-select>-->

            <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="検索"
                single-line
                dense
                class="shrink"
            ></v-text-field>
          </div>
          <!-- 分類タブ -->
          <div class="d-flex justify-start">
            <v-tabs v-model="tabKey" dense>
              <v-tab v-for="o in Object.values(tabs)" :key="o.key">{{ o.label }}</v-tab>
            </v-tabs>
          </div>
        </div>

        <!-- 撮影ポイント写真/3Dモデル一覧 -->
        <div class="flex-grow-1 flex-shrink-1 mt-2" >
          <v-data-table
              dense
              :loading="loading"
              :headers="headers"
              :search="search"
              :items="prefiltered"
              :items-per-page="ITEM_PER_PAGE"
              class="elevation-0"
              hide-default-footer
              height="calc(100vh - 220px)"
              fixed-header
              :custom-sort="customSort"
              :page.sync="page"
              @page-count="pageCount = $event"
          >

            <!-- ファイル名 -->
            <template #[`item.file_name`]="{ item }">
              <div v-on:mouseover="onMouseover(item.id)">{{ item.file_name }}</div>
            </template>

            <!-- 日時 -->
            <template #[`item.shot_at`]="{ item }">
              <div v-on:mouseover="onMouseover(item.id)">{{ item.shot_at }}</div>
            </template>

            <!-- 左画像選択 -->
            <template #[`item.left`]="{ item }">
              <v-radio-group v-model="left" dense >
                <v-radio label="" :value="item.id" class="justify-center" dense @mouseover="onMouseover(item.id)"/>
              </v-radio-group>
            </template>

            <!-- 右画像選択 -->
            <template #[`item.right`]="{ item }">
              <v-radio-group v-model="right" dense >
                <v-radio label="" :value="item.id" class="justify-center" dense @mouseover="onMouseover(item.id)"/>
              </v-radio-group>
            </template>

            <template #[`loading`]><span>　</span></template>

            <template #[`no-data`]>
              データ無し
            </template>

          </v-data-table>
        </div>
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>

      </div>

      <v-card >
        <v-img>

        </v-img>
      </v-card>

      <!-- 戻る -->
      <v-btn class="close elevation-0" @click.stop="close">
        <v-icon size="20">fa-regular fa-images</v-icon>
      </v-btn>

    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import {DivideLayoutDefs, Extensions} from "@/util/constants";

/**
 * 分類タブ
 */
const TabDefinitions = {
  All: { key: 0, label: "ALL", panorama: null, extensions: undefined, },
  PanoramaMovie: { key: 1, label: "360°動画", panorama: 1, extensions: Extensions.MOVIE, },
  PanoramaImage: { key: 2, label: "360°画像", panorama: 1, extensions: Extensions.IMAGE, },
  Movie: { key: 3, label: "動画", panorama: 0, extensions: Extensions.MOVIE, },
  Image: { key: 4, label: "画像", panorama: 0, extensions: Extensions.IMAGE, },
  CAD: { key: 5, label: "CAD", panorama: 0, extensions: Extensions.CAD, },
  // PDF: { key: 6, label: "PDF", panorama: 0, extensions: Extensions.PDF, },
};

// /**
//  * 画面の分割数
//  * @type {*[]}
//  */
// const divideLayoutItems = [];
// for (const [k, v] of Object.entries(DivideLayoutDefs)) {
//   divideLayoutItems.push({
//     value: Number.parseInt(k),
//     text: `${k} ( ${v.rows}行 × ${v.cols}列 )`
//   });
// }

export default {
  name: "PictureSelector",
  // model: {
  //   prop: 'item_index',
  //   event: 'change'
  // },
  props: {
    /**
     * 表示
     */
    showFlg: {
      type: Boolean,
      required: true,
    },
    /**
     * 写真/モデル配列
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     *
     */
    item_index: Object,
    loading: Boolean,
  },
  data: function() {
    return {
      search: null,
      tabKey: TabDefinitions.All.key,
      tabs: TabDefinitions,
      headers: [
        { text: 'ファイル', align: 'start', sortable: true, value: 'file_name'},
        { text: '日時', align: 'center', sortable: true, value: 'shot_at', width: '200px'},
        { text: '左表示', align: 'center', sortable: true, value: 'left', width: '100px'},
        { text: '右表示', align: 'center', sortable: true, value: 'right', width: '100px'},
      ],
      left: null,
      right: null,
      page: 1,
      pageCount: 0,
      ITEM_PER_PAGE: 20,
      // divideCount: 2,
      // divideLayoutItems
    }
  },
  computed: {

    /**
     * 選択中の分類
     */
    activeTab: function() {
      return _.find(this.tabs, t => t.key === this.tabKey);
    },
    /**
     * 「全て」を選択中
     */
    isAll: function() {
      return this.activeTab === this.tabs.All;
    },
    /**
     * フィルタ
     */
    prefiltered: function() {
      if (this.isAll) {
        return this.items;
      }

      const {panorama, extensions} = this.activeTab;
      return _.filter(this.items, f => {
        const panoramaFlg = f.panorama_flg || 0;
        const fileType = (f.file_type || '').toLowerCase();
        return panorama === panoramaFlg && extensions.includes(fileType);
      });
    },
  },
  watch: {
    items: function(nv, ov) {
      this.left = nv[0]?.id;
      this.right = nv[1]?.id || nv[0]?.id;
    }
  },
  methods: {
    onMouseover(item) {

    },
    close() {
      this.$emit("close", {
        left: this.left,
        right: this.right
      });
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        let ret = 0;
        if (index[0] === 'file_name' || index[0] === 'shot_at') {
          ret = a[index[0]].localeCompare(b[index[0]])
        } else if (index[0] === 'left') {
          ret = b['id'] === this.left ? 1 : -1;
        } else if (index[0] === 'right') {
          ret = b['id'] === this.right ? 1 : -1;
        }
        if (isDesc[0]) ret = -ret;
        return ret;
      });
      return items;
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/style/config.scss";

  div.component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);

    div.dialog {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 16px;
      background-color: white;
      box-shadow: 1px 1px 1px 1px gray;

      div.content {
        height: 100%;
        max-height: 100%;
        position: relative;
      }
    }
  }

  .v-data-table> .v-data-table__wrapper> table> thead> tr> td {
    font-size: .875rem !important;
    height: 48px !important;
  }

  .v-btn.close {
    position: absolute;
    top: 12px;
    left: 8px;
    height: 40px!important;
    width: 40px!important;
    min-width: 40px!important;
    padding: 8px!important;
    border-radius: 4px;
    background:linear-gradient(white, lightgray);
    border: none;
    color: black;
  }

  .loading-content {
    height: calc(100vh - 270px);
    width: 100%;
    align-content: center;
    text-align: center;
    vertical-align: center;
  }
</style>
