<template>
  <div class="button">
    <label for="ckbox_lock">
      <input
        id="ckbox_lock"
        class="ckbox"
        type="checkbox"
        :checked="boolValue"
        @change="$emit('change', $event.target.checked)"
      >

      <div class="icon">
        <img class="off" src="@/assets/img/icon-lock_off.png" alt="">
        <img class="on" src="@/assets/img/icon-lock_on.png" alt="">
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "ButtonLock",
  model: {
    prop: "boolValue",
    event: "change",
  },
  props:{
    boolValue:{
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
  .button{
    position:relative;
    display: inline-block;
    vertical-align: middle;
    width:32px;
    height:32px;
    label{
      width:inherit;
      height:inherit;
    }
    pointer-events: auto;
  }
  .icon{
    display: inline-block;
    width:32px;
    height:32px;
    img {
      width:inherit;
      height:inherit;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .ckbox {
    position:absolute;
    visibility: hidden;
  }
  #ckbox_lock + .icon {
     .off {
        display: inline;
      }
      .on {
        display: none;
      }
  }
  #ckbox_lock:checked + .icon {
     .off {
        display: none;
      }
      .on {
        display: inline;
      }
  }
</style>
