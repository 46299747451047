<template>
  <div class="overflow-y-auto">
    <v-container>
      <v-row>
        <v-col v-if="is_panorama" class="text-left"><h3>パノラマ：{{item_name}}</h3></v-col>
        <v-col v-if="!is_panorama" class="text-left"><h3>モデル：{{item_name}}</h3></v-col>
      </v-row>
      <v-row>
        <v-col class="text-left">位置情報</v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              label="緯度"
              v-model.number="_latitude"
              type="number"
              step="0.0000001"
              filled
              dense
              suffix="度"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="経度"
              v-model.number="_longitude"
              type="number"
              step="0.0000001"
              filled
              dense
              suffix="度"
          />
        </v-col>
      </v-row>

      <div v-show="false">
      <v-row>
        <v-col class="text-left"><h5>直交座標</h5></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
              label="座標系"
              v-model="_planeRectCoordSystemObj"
              :menu-props="{ top: true, offsetY: true }"
              :items="planeRectCoordSystems"
              item-text="name"
              item-value="key"
              filled
              dense
              return-object
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              label="X"
              v-model.number="_north"
              type="number"
              step="0.001"
              filled
              dense
              suffix="m"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="Y"
              v-model.number="_east"
              type="number"
              step="0.001"
              filled
              dense
              suffix="m"
          />
        </v-col>
      </v-row>
      </div>

      <v-row>
        <v-col class="text-left">オフセット</v-col>
        <v-spacer/>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              label="北"
              v-model.number="_offsetNorth"
              type="number"
              step="0.001"
              filled
              dense
              suffix="m"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="東"
              v-model.number="_offsetEast"
              type="number"
              step="0.001"
              filled
              dense
              suffix="m"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              label="高さ"
              v-model.number="_offsetHeight"
              type="number"
              step="0.001"
              filled
              dense
              suffix="m"
          />
        </v-col>
        <v-col>
        <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              label="ロール"
              v-model.number="_roll"
              type="number"
              step="0.01"
              filled
              dense
              suffix="°"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="ピッチ"
              v-model.number="_pitch"
              type="number"
              step="0.01"
              filled
              dense
              suffix="°"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="ヨー"
              v-model.number="_yaw"
              type="number"
              step="0.01"
              filled
              dense
              suffix="°"
          />
        </v-col>
      </v-row>
      <v-row v-if="!is_panorama" >
        <v-col>
          <v-text-field
              label="拡大率"
              v-model.number="_scale"
              type="number"
              step="0.01"
              filled
              dense
          />
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-checkbox label="XY交換" v-model="_reverseCoordinate" dense></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-col>
          <v-btn @click="save">保存</v-btn>
          <v-btn @click="close">キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { proj4 , PROJ_LABELS } from '@/util/proj4';
import { nextTick } from "vue";
import _ from "lodash";

const round = (value, base) => {
  const p = Math.pow(10, base);
  return Math.round(value * p) / p;
};

export default {
  name: "PictureProperties",
  model: {
    prop: 'properties',
    event: 'change'
  },
  props: {
    cp_around_photograph_id: Number,
    locationEnable: Boolean,
    item_name: String,
    is_panorama: Boolean,
    properties: Object
  },
  data: () => {
    return {
      values: {
        planeRectCoordSystem: null,
        reverseCoordinate: false,
        longitude: 0.0,
        latitude: 0.0,
        north: 0.0,
        east: 0.0,
        offsetNorth: 0.0,
        offsetEast: 0.0,
        offsetHeight: 0.0,
        offsetRoll: 0.0,
        offsetPitch: 0.0,
        offsetYaw: 0.0,
        scale: 1.0,
      },
      backup: null,
    };
  },
  computed: {
    planeRectCoordSystems() {
      return _.map(PROJ_LABELS,
          (value, key) => { return { key, name: value }; }
      );
    },
    _planeRectCoordSystemObj: {
      get: function() {
        return this.planeRectCoordSystems.find(i => i.key === this.planeRectCoordSystem);
      },
      set(value) {
        this.values.planeRectCoordSystem = value.key;
        if (!this.values.longitude && !this.values.latitude) {
          this.computeLongLat();
        } else {
          this.computeNorthEast();
        }
      },
    },
    _reverseCoordinate: {
      get: function() {
        return this.values.reverseCoordinate;
      },
      set: function(newVal) {
        this.values.reverseCoordinate = newVal;
      }
    },
    _longitude: {
      get: function() {
        return this.values.longitude;
      },
      set: function(newVal) {
        this.values.longitude = round(newVal, 7);
        this.computeNorthEast();
      },
    },
    _latitude: {
      get: function() {
        return this.values.latitude;
      },
      set: function(newVal) {
        this.values.latitude = round(newVal, 7);
        this.computeNorthEast();
      }
    },
    _north: {
      get: function() {
        return this.values.north;
      },
      set: function(newVal) {
        this.values.north = round(newVal, 3);
        this.computeLongLat();
      },
    },
    _east: {
      get: function() {
        return this.values.east;
      },
      set: function(newVal) {
        this.values.east = round(newVal, 3);
        this.computeLongLat();
      },
    },
    _offsetNorth: {
      get: function() {
        return round(this.values.offsetNorth, 3);
      },
      set: function(newVal) {
        this.values.offsetNorth = round(newVal, 3);
      },
    },
    _offsetEast: {
      get: function() {
        return round(this.values.offsetEast, 3);
      },
      set: function(newVal) {
        this.values.offsetEast = round(newVal, 3);
      },
    },
    _offsetHeight: {
      get: function() {
        return round(this.values.offsetHeight, 3);
      },
      set: function(newVal) {
        this.values.offsetHeight = round(newVal, 3);
      },
    },
    _roll: {
      get: function() {
        return round(this.values.offsetRoll, 2);
      },
      set: function(newVal) {
        this.values.offsetRoll = round(newVal, 2);
      },
    },
    _pitch: {
      get: function() {
        return round(this.values.offsetPitch, 2);
      },
      set: function(newVal) {
        this.values.offsetPitch = round(newVal, 2);
      },
    },
    _yaw: {
      get: function() {
        return round(this.values.offsetYaw, 2);
      },
      set: function(newVal) {
        this.values.offsetYaw = round(newVal, 2);
      },
    },
    _scale: {
      get: function() {
        return round(this.values.scale, 2);
      },
      set: function(newVal) {
        this.values.scale = round(newVal, 2);
      },
    }
  },
  watch: {
    properties: function() {
      this.values = _.merge(this.values, this.properties);
    },
    values: {
      handler() {
        let isNeedEmit = false;
        for (const propName in this.values) {
          if (this.values[propName] !== this.properties[propName]) {
            isNeedEmit = true;
            break;
          }
        }
        if (isNeedEmit) {
          this.$emit('change', this.values);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.values = _.merge(this.values, this.properties);
    this.backup = _.clone(this.properties);
  },
  methods: {
    close() {
console.log("change called.");
      this.$emit('change', this.backup);
      nextTick(() => {
console.log("close called.");
        this.$emit('close');
      });
    },
    save() {
      this.$emit("save", this.values);
      nextTick(() => {
        this.$emit('close');
      });
    },
    computeLongLat() {
      const { planeRectCoordSystem, north, east } = this.values;
      if (!planeRectCoordSystem || !north || !east) return;
      const coords = proj4(planeRectCoordSystem, "EPSG:6668", [north, east]);
      if (coords) {
        this.values.longitude = round(coords[0], 7);
        this.values.latitude = round(coords[1], 7);
      }
    },
    computeNorthEast() {
      const { planeRectCoordSystem, longitude, latitude } = this.values;
      if (!planeRectCoordSystem || !longitude || !latitude) return;
      const coords = proj4("EPSG:6668", planeRectCoordSystem, [longitude, latitude]);
      if (coords) {
        this.values.north = round(coords[0], 6);
        this.values.east = round(coords[1], 6);
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/style/config.scss";

div.component {
  position: relative;
  background-color: white;

  div.dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  div.dialog {
    overflow-y: auto;
  }
}
.v-btn.icon-button {
  height: 40px!important;
  width: 40px!important;
  min-width: 40px!important;
  padding: 8px!important;
  border-radius: 4px;
  background:linear-gradient(white, lightgray);
  border: none;
  color: black;
}
</style>
