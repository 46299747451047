<template>
  <div :aria-readonly="disabled || processing" @click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    // A function which returns Promise.
    onClick: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    handleClick(event) {
      if (this.processing || this.disabled) return;
      this.processing = true;
      this.onClick(event).then(() => {
        this.processing = false;
      });
    },
  },
};
</script>
