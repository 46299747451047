<template>
  <div
    :class="{ dragover: isDragover }"
    @dragover.prevent.stop="onDragover"
    @dragleave.prevent.stop="onDragleave"
    @drop.prevent.stop="onDrop"
  >
    <slot></slot>
  </div>
</template>

<script>
  import * as _ from "lodash";

  export default {
    name: "FileDropTarget",
    data: () => {
      return {
        isDragover: false
      }
    },
    props: {
      maxFileSize: {
        type: Number,
        default: 0
      }
    },
    methods: {
      onDragover(ev) {
        this.isDragover = true;
        const isFiles = ev.dataTransfer.types[0] === 'Files';
        if (isFiles) {
          const isAcceptTypeOnly = _.filter(ev.dataTransfer.items, item => {
            return !item.type.startsWith('image/') && !item.type.startsWith('video/') && item.type !== '';
          }).length === 0;
          ev.dataTransfer.dropEffect = isAcceptTypeOnly ? 'all' : 'none'; // noneの場合ドロップしても無視される
        }
      },
      onDragleave() {
        this.isDragover = false;
      },
      onDrop(ev) {
        this.isDragover = false;

        const files = ev.dataTransfer.files;

        const base64Encode = (file) => {
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.onload = (ev) => resolve(ev.target.result);
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(file);
          });
        }

        files.forEach(async file => {
          let base64;
          let error;
          try {
            base64 = await base64Encode(file);
          } catch (e) {
            base64 = null;
            error = e;
          }

          this.$emit('drop', {
            data: base64,
            error: error,
            type: file.type,
            name: file.name,
            size: file.size,
            size_over_flg: !this.maxFileSize || file.size < this.maxFileSize * 1000,
          });
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .dragover {
    background-color: gold;
  }
</style>
