import * as _ from "lodash";
import api from "@/api/api.js";

export const ApFile = {
  namespaced: true,

  state: {
    files: [],
  },
  mutations: {
    SET_FILE(state, payload) {
      const result = state.files.find((file) => {
        return file.id === payload.id;
      });
      //なければ追加
      if (!result) {
        const files = [...state.files];
        files.push(payload);
        state.files = files;
      }
    },
  },
  actions: {
    async postApFile(context, payload) {
      const service = "postApFile";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
      } else {
        res["data"] = response.data;
      }
      return res;
    },
    async deleteApFile(context, payload) {
      const service = "deleteApFile";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.delete(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
      } else {
        res["data"] = response.data;
      }
      return res;
    },
    async getApFileImage(context, payload) {
      const service = "getApFileImage";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("SET_FILE", { ...response.data.data });
      } else {
        res["data"] = response.data;
      }
      return res;
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getFileFromFileId: (state) => (id) => {
      return state.files.find((file) => file.id === id);
    },
  },
};
