<template>
  <div class="checkbox-group">
    <Checkbox
      v-for="(item, index) in list"
      :key="index"
      :disabled="false"
      :styles="styles + (isDisplayNone(index) ? ' display:none;' : '')"
      :val="item.value"
      :defaultValueFlg="isDefaultValue(item.value)"
      :label="item.name"
      :name="name"
      @onChange="setCheckboxGroupVals"
    />
  </div>
</template>
<script>
import Checkbox from "./Checkbox.vue";
import * as _ from "lodash";
import * as Uobj from "@/util/obj.js";
export default {
  components: { Checkbox },
  data: () => {
    return {
      labelId: _.uniqueId("radio"),
      $_defaultValues: null,
      $_displayNoneIdxs: null,
      checkboxGroupVals: [],
    };
  },
  props: {
    list: { type: Array, required: true },
    label: {
      type: [String, Number],
    },
    name: {
      type: [String, Number],
    },
    styles: {
      type: [String],
      default: "",
    },
    // 初期値にしたいindexをArray型で指定
    defaultValues: {
      type: Array,
    },
    // display:noneにしたいindexをArray型で指定
    displayNoneIdxs: {
      type: Array,
    },
  },
  created() {
    this.$watch(
      () => [this.defaultValues, this.displayNoneIdxs],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.$_defaultValues = newValue[0];
          this.$_displayNoneIdxs = newValue[1];
          // CheckboxGroup全体の値
          for (let index = 0; index < this.list.length; index++) {
            if (Uobj.hasList(this.$_defaultValues)) {
              let item = this.$_defaultValues.find(
                (defValue) => defValue == this.list[index].value
              );
              this.checkboxGroupVals.push({
                checked: !_.isNil(item),
                value: this.list[index].value,
              });
            } else {
              this.checkboxGroupVals.push({
                checked: false,
                value: this.list[index].value,
              });
            }
          }
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    isDefaultValue(value) {
      if (Uobj.hasList(this.$_defaultValues)) {
        let ans = this.$_defaultValues.find(
          (defaultValue) => defaultValue == value
        );
        return !_.isNil(ans);
      } else {
        return false;
      }
    },
    isDisplayNone(idx) {
      if (Uobj.hasList(this.$_displayNoneIdxs)) {
        let ans = this.$_displayNoneIdxs.find(
          (displayNoneIdx) => displayNoneIdx == idx
        );
        return !_.isNil(ans);
      } else {
        return false;
      }
    },
    setCheckboxGroupVals(param) {
      let idx = _.findIndex(this.list, (item) => {
        return item.value == param.value;
      });
      this.checkboxGroupVals[idx]["checked"] = param.checked;
      this.$emit("onChange", {
        name: this.name,
        values: this.checkboxGroupVals,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox-group {
  display: flex;
}
</style>
