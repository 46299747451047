import * as _ from "lodash";

export const getNest = (obj, ...keys) => {
  const getData = function(obj, keys) {
    if (_.isNil(obj)) return null;
    if (_.isNil(keys) || keys.length == 0) return obj;
    if (_.isNil(keys[0])) return null;
    return getData(obj[keys[0]], keys.slice(1));
  };
  return getData(obj, keys);
};

export const hasList = (list) => {
  return !_.isNil(list) && list.length > 0;
};

export const hasKey = (obj, ...keys) => {
  if (_.isNil(obj) || _.isNil(keys) || keys.length == 0) return false;
  let chkFlg = false;
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] in obj) {
      chkFlg = true;
    } else {
      chkFlg = false;
      break;
    }
  }
  return chkFlg;
};
