<template>
  <input
    :id="nameId"
    :ref="nameId"
    :key="fileId"
    style="display:none"
    type="file"
    accept=".jpg,.jpeg,.pdf"
    multiple
    @change="encodeFile"
  />
</template>
<script>

// type=fileのリセット
// https://qiita.com/kroton/items/2ea67e1a05eb68403750

import { PANEL_STATUS, MAX_FILE_SIZE } from "@/util/constants";
import _ from "lodash";
export default {
  data: () => {
    return {
      fileId: 0,
    };
  },
  props: {
    nameId: {
      type: String,
    },
    panel_status: {
      type: String,
    },
    //クリック対象のエリア
    target_area_id: {
      type: String,
    },
  },
  watch: {
    panel_status: {
      handler(newVal, oldVal) {
        if (newVal === PANEL_STATUS.FILEUPLOAD && newVal !== oldVal) {
          document
            .getElementById(this.target_area_id)
            .addEventListener("click", this.onClickBody);
          // document.addEventListener('click',this.onClickBody);
        }
        if (!newVal) {
          document
            .getElementById(this.target_area_id)
            .removeEventListener("click", this.onClickBody);
          // document.removeEventListener('click',this.onClickBody);
        }
      },
    },
  },
  methods: {
    onClickBody() {
      console.log(" ----------- panel id", this.target_area_id);
      if (this.panel_status) {
        this.$store.dispatch("PanelFileUploads/setStatus", this.panel_status);
        //モーダル閉じた後にbodyにフォーカスを戻す
        document.body.onfocus = this.onBlur;
        //input[type=file]のイベントを発火
        document.getElementById(this.nameId).click();
      }
    },
    onBlur() {
      console.log("OnBlur");
      this.$nextTick(() => {
        this.$emit("onFileUploadCancelEvent");
      });
      document.body.onfocus = null;
    },
    async encodeFile(e) {
      if (e.target.files.length === 0) return;

      const getBase64 = (file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = (ev) => resolve(ev.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsDataURL(file);
        });
      };

      const chkFileSizeOver = [...e.target.files].map(async (file) => {
        return !file || file.size >= MAX_FILE_SIZE * 1000;
      });
      const chkFileSizeList = await Promise.all(chkFileSizeOver);
      let ansFileSizeOver = _.compact(chkFileSizeList);
      if (ansFileSizeOver.length > 0) {
        alert("ファイルサイズは全て5MB以下にしてください");
        return;
      }

      const promises = [...e.target.files].map(async (file) => {
        let data;
        const regex = /\.([\da-z]+$)/i;
        const extType = file.name.toLowerCase().match(regex)[1];
        const regex2 = /(jpeg|jpg)/;
        const type = extType.match(regex2) ? "jpg" : extType;
        try {
          data = {
            image_type: type,
            name: file.name,
            image: await getBase64(file),
            size: file.size,
          };
        } catch (e) {
          data = null;
        }
        return data;
      });

      console.log("base64encode start");
      const base64s = await Promise.all(promises);
      console.log("base64encode end");
      this.$store.dispatch("PanelFileUploads/setFiles", base64s);
      this.$nextTick(() => {
        this.$emit("onClickPanelEvent");
        ++this.fileId;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
