<template>
  <div class="toast">
    <div class="inner" :class="isShow?'show':null">
      <div class="message">{{message}}</div>
    </div>
  </div>
</template>
<script>

/**
 * this.$store.Toastで操作
 *
 * 呼び出し方
 * this.$store.dispatch("Toast/show",{message,time});
 */
export default {
  computed:{
    isShow(){
      return this.$store.getters["Toast/getIsShow"];
    },
    message(){
      return this.$store.getters["Toast/getMessage"];
    }
  }
}
</script>
<style lang="scss" scoped>
.toast {
  position: fixed;
  z-index: 999;
  bottom:96px;
  width:100%;
  pointer-events: none;

  .inner {
    text-align: center;
    opacity: 0;
    transition: opacity .3s ease-in;
    &.show{
      opacity: 1;
    }
  }

  .message {
    display: inline-block;
    font-size:12px;
    padding:4px 8px;
    background-color: rgba(255,255,255,.5);
    border-radius: 8px;
  }
}
</style>
