/**
 *インジケーター表示
 */
export const Indicater = {
  namespaced: true,
  state: {
    isShow: false,
  },
  mutations: {
    START(state, { isShow }) {
      state.isShow = isShow;
    },
    STOP(state, { isShow }) {
      state.isShow = isShow;
    },
  },
  actions: {
    start(context) {
      context.commit("START", { isShow: true });
    },
    stop(context) {
      context.commit("STOP", { isShow: false });
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getIsShow: (state) => {
      return state.isShow;
    },
  },
};
