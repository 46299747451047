<template>
  <div class="select-wrapper input">
    <select
      class="select"
      :class="select_color_class"
      :name="name"
      :disabled="isDisabled"
      @change="
        setSelectColor($event.target.selectedIndex);
          $emit('onChange', {
            name,
            value: items[getIndex($event.target.selectedIndex)],
            idx: getIndex($event.target.selectedIndex),
          })
      "
    >
      <option v-if="defaultOption === 1 || defaultOption === 2" value hidden>{{
        placeholder
      }}</option>
      <option v-if="defaultOption === 3 || defaultOption === 2" value></option>

      <option
        v-for="(item, index) in items"
        :key="index"
        :value="item"
        :selected="index === selectedIdx"
        >{{ item[itemName] }}</option
      >
    </select>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: [String, Number],
      required: false,
    },
    items: {
      // セレクトボックス
      type: [Object, Array],
      default: null,
    },
    itemName: {
      // 表示選択項目
      type: [String],
      required: true,
    },
    selectedIdx: {
      // 表示選択項目のindex
      type: [String, Number],
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    defaultOption: {
      // オプション
      /// 0:初期挙動(itemsをそのまま表示する)
      /// 1:placeholderを使用する
      /// 2:placeholderを使用する(選択可能) ※選択した場合、value=undefinedとなるので取得側で要対応
      /// 3:先頭に空項目を設定する ※選択した場合、value=undefinedとなるので取得側で要対応
      type: Number,
      default: 0,
    },
    placeholder: {
      // placeholderを使用する場合は、defaultOption=1または2を設定
      type: String,
    },
    isFirstOptionGray: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      editItems: [],
      item: [],
      select_color_class: "",
    };
  },
  created() {
    this.setSelectColor(this.selectedIdx);

    this.$watch(
      () => [this.items, this.selectedIdx],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.$nextTick(() => {
            let items = newValue[0];
            let selectedIdx = newValue[1];
            if (items && items.length > 0 && selectedIdx >= 0) {
              this.item = _.cloneDeep(items[selectedIdx]);
            } else {
              this.item = [];
            }
          });
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    getIndex(idx) {
      let _idx = 0;
      if (this.defaultOption === 1 || this.defaultOption === 3) {
        // 空のoption分、idxを減算する。
        _idx = idx - 1;
      } else if (this.defaultOption === 2) {
        _idx = idx - 2;
      } else {
        _idx = idx;
      }
      return _idx;
    },
    setSelectColor(idx) {
      if (
        (this.defaultOption === 1 ||
          this.defaultOption === 2 ||
          this.isFirstOptionGray) &&
        idx <= 0
      ) {
        this.select_color_class = "select-placeholder-color";
      } else {
        this.select_color_class = "select-option-color";
      }
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";
@import "../../style/icon.scss";
.select-wrapper {
  position: relative;
  display: inline-block;
  padding-bottom: $padding-20;
  width: 100%;
  &:last-child {
    padding-bottom: 0;
  }
  .select {
    appearance: none;
    border: none;
    min-width: 72px;
    height: 22px;
    border-radius: 8px;
    font-size: $font-size-30;
    padding: 2px 6px;
    margin: 0 2px;
    outline: none;
    color: #666;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  }

  .select-option-color {
    color: $color-blue-10;
  }

  .select-placeholder-color {
    color: $color-base-25;
  }

  // 三角部分
  .select {
    //デフォルトの矢印を消す
    -webkit-appearance: none;
    -moz-appearance: none;
    // // //三角の描画
    // // background-image: linear-gradient(
    // //     45deg,
    // //     transparent 50%,
    // //     $color-blue-20 50%
    // //   ),
    // //   linear-gradient(135deg, $color-blue-20 50%, transparent 50%);
    // // background-position: calc(100% - 20px) calc(1em + 2px),
    // //   calc(100% - 15px) calc(1em + 2px);
    // // background-size: 5px 5px, 5px 5px;
    // // background-repeat: no-repeat;
    // // &:focus {
    // //   background-image: linear-gradient(
    // //       45deg,
    // //       $color-blue-20 50%,
    // //       transparent 50%
    // //     ),
    // //     linear-gradient(135deg, transparent 50%, $color-blue-20 50%);
    // //   background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em;
    // //   background-size: 5px 5px, 5px 5px;
    // //   background-repeat: no-repeat;
    // }
  }
  ::-ms-expand {
    display: none;
  }
}
</style>
