import { render, staticRenderFns } from "./PictureSelector.vue?vue&type=template&id=58ab3ca0&scoped=true&"
import script from "./PictureSelector.vue?vue&type=script&lang=js&"
export * from "./PictureSelector.vue?vue&type=script&lang=js&"
import style0 from "./PictureSelector.vue?vue&type=style&index=0&id=58ab3ca0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ab3ca0",
  null
  
)

export default component.exports