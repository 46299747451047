<template>
  <div id="app">
    <v-app>
<!--      <v-app-bar app elevation="0">-->
<!--        <v-app-bar-nav-icon @click="drawer=true"></v-app-bar-nav-icon>-->
<!--      </v-app-bar>-->
<!--      <v-navigation-drawer-->
<!--         app-->
<!--         v-model="drawer"-->
<!--         absolute-->
<!--         temporary>-->
<!---->
<!--      </v-navigation-drawer>-->

<!--      <v-toolbar>-->
<!--      </v-toolbar>-->
<!--      <v-main>-->
<!--        <v-container>-->
          <!-- top of router-view -->
          <router-view />
          <!-- bottom of router-view -->
<!--          <main-view ></main-view>-->
          <PopupInfo ref="popupInfo" />
          <PopupInput ref="popupInput" />
          <PopupTxt ref="popupTxt" />
          <PopupLanLng ref="popupLanLng" />
          <ProgressBar />
          <Toast />
          <ImageViewer />
          <Tooltip />
<!--        </v-container>-->
<!--      </v-main>-->
    </v-app>
  </div>
</template>

<script>
import PopupInfo from "@/components/popup/PopupInfo.vue";
import PopupInput from "@/components/popup/PopupInput.vue";
import PopupTxt from "@/components/popup/PopupTxt.vue";
import PopupLanLng from "@/components/popup/PopupLanLng.vue";
import ProgressBar from "@/components/progressbar/ProgressBar";
import Toast from "@/components/toast/Toast";
import ImageViewer from "@/components/imageViewer/ImageViewer";
import Tooltip from "@/components/tooltip/Tooltip";
// import MainView from "@/views/MainView.vue";

export default {
  name: "app",
  components: {
    // MainView,
    PopupInfo,
    PopupInput,
    PopupTxt,
    PopupLanLng,
    ProgressBar,
    Toast,
    ImageViewer,
    Tooltip,
  },
  data() {
    return {
      // drawer: false,

    };
  },
  /**
   * クラス作成時
   * @returns {Promise<void>}
   */
  async created() {

    const queryParams = this.$route.query;
    if (queryParams) {
      // 現場＝ファイルキャビネット（チームアイテム）
      // 図面＝フォルダに相当
      await this.$store.dispatch("BpLinkage/setLink", {
        tmItemId: Number.parseInt(queryParams.tm_itm.toString()) || 0,
        folderId: Number.parseInt(queryParams.folder.toString()) || 0,
        handleName: queryParams.handle_name,
      });
    }

  //   const bpLink = this.$store.getters["BpLinkage/getState"];
  //
  //   // 仮ログイン
  //   await this.$store.dispatch("LoginApi/login", {
  //     login_id: "admin1",
  //     password: "test1234",
  //   });
  //   const apiToken = this.$store.getters[`LoginApi/getApiToken`];
  //
  //   // 現場リスト更新
  //   await this.$store.dispatch(
  // "ConstructionSitesApi/getConstructionSites",
  // { api_token: apiToken }
  //   );
  //
  //   // 現場特定
  //   const constructionSites = this.$store.getters[`ConstructionSitesApi/getStateConstructionSites`];
  //   const constructionSite = constructionSites.find(c => c.bp_id === bpLink.tmItemId);

  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

//#nav {
//  // padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}

</style>
