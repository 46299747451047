import * as _ from "lodash";

export const makeMail = (prm) => {
  if (!prm) return false;

  let len = "mailto:";
  len += _.isNil(prm["to"]) ? "?" : prm["to"];
  if (!_.isNil(prm["cc"])) len += "?cc=" + prm["cc"];
  // BCCはコメントアウト。MACのMail.appではデフォルト非表示のため設定できない
  // if (!_.isNil(prm["bcc"])) len += "?bcc=" + prm["bcc"];
  if (!_.isNil(prm["subject"])) len += "&subject=" + prm["subject"];
  // encodeURIで改行可能となる
  if (!_.isNil(prm["body"])) len += "&body=" + encodeURI(prm["body"]);
  location.href = len;

  return true;
};
