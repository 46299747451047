<template>
  <div v-if="maps" class="maps">
    <div class="maps-body">
      <div class="maps-body_button-area">
        <ButtonFileUpload
          id="map"
          :type="BTN_OPTION.SQUARE_TYPE.POSITIVE_BLUE"
          :label="'図面を追加'"
          accept="image/jpg"
          @onClick="addMap"
        />
      </div>
      <ul v-for="(item, idx) in maps" :key="idx">
        <li>
          <div class="maps-body_list">
            <div class="maps-body_img-wrapper">
              <div>
                <img
                  class="maps-body_img"
                  :src = "ICON_IMAGE.MAP"
                  @click="toggleCtrl('idx_' + idx)"
                />
              </div>
              <div
                class="maps-body_txt"
                @click="
                  toggleCtrl('idx_' + idx, true),
                    $emit('show', { item: maps[idx] })
                "
              >
                {{ item.name }}
              </div>
            </div>
            <div class="maps-body_img-wrapper maps-body_img-del-wrapper">
              <img
                class="maps-body_img-del"
                :src="icon.del"
                @click="delMap(maps[idx])"
              />
            </div>
          </div>

          <span :ref="'idx_' + idx" class="togglHidden">
            <ul v-for="(cItem, cIdx) in item['captcha_points']" :key="cIdx">
              <li>
                <file-drop-target @drop="addPhotos({ map_id: maps[idx].id, id: cItem.id, ...$event })">
                  <div class="maps-body_list">
                    <div
                      class="maps-body_img-wrapper maps-body_img-wrapper--captcha-points"
                      :class="{ over: true }"
                      @click="
                        $emit('selVRImg', { map_id: maps[idx].id, id: cItem.id })
                      "
                    >
                      <div class="maps-body_count">
                        {{ cItem.valid_around_photograph_cnt }}
                      </div>
                      <img
                        class="maps-body_img"
                        :src="ICON_IMAGE.PICTURE_POINT"
                      />
                      <div class="maps-body_txt">{{ cItem.name }}</div>
                    </div>
                    <div class="maps-body_img-wrapper maps-body_img-del-wrapper">
                      <!-- TODO 撮影ポイントの削除は要望があり次第実装 -->
                      <!-- <img class="maps-body_img-del" :src="icon.del" @click="$emit('delPoint', { map_id: maps[idx].id, id: cItem.id})"/> -->
                    </div>
                  </div>
                </file-drop-target>
              </li>
            </ul>
          </span>
        </li>
      </ul>

      <div class="maps-bottom" />
    </div>
  </div>
</template>

<script>

import * as _ from "lodash";
import { double_arrow, del } from "@/util/icon.js";
import { SQUARE_TYPE } from "@/components/button/ButtonFileUpload.vue";
import ButtonFileUpload from "@/components/button/ButtonFileUpload.vue";
import FileDropTarget from "../fileDropTarget/FileDropTarget";
import { v1 as uuidv1 } from "uuid";
import { base64ToArrayBuffer } from "@/util/img.js"
import * as EXIF from "exif-js";

export default {
  components: {
    ButtonFileUpload, FileDropTarget
  },
  data: () => {
    return {
      icon: { double_arrow, del },
      ICON_IMAGE: {
        MAP: require("@/assets/img/map.png"),
        PICTURE_POINT: require("@/assets/img/dot-circle-def.png"),
      },
      BTN_OPTION: { SQUARE_TYPE },
      maps: [],
      dragover: undefined,
    };
  },
  props: {
    items: {
      type: [Object, Array],
    },
  },
  created() {
    this.$watch(
      () => [this.items],
      async (newValue, oldValue) => {
        if (newValue[0] && newValue !== oldValue) {
          this.$nextTick(async () => {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
              this.maps = _.cloneDeep(newValue[0]);
            }
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    toggleCtrl(refId, showForceFlg) {
      let className = this.$refs[refId][0].className;
      !showForceFlg && _.isEmpty(className)
        ? (this.$refs[refId][0].className = "togglHidden")
        : (this.$refs[refId][0].className = "");
    },
    async addMap(param) {
      let res = await this.postZumen(param);
      if (res["status"] != 200) return;
      this.$emit("add");
    },
    async delMap(param) {
      if (!window.confirm("図面を削除しますか？")) return;
      let res = await this.deleteZumen(param);
      if (res["status"] != 200) return;
      this.$emit("del");
    },
    async addPhotos(param) {
      let res = await this.postPhotos(param);
      if (res["status"] != 200) return;
      this.$emit("add");
    },

    // API 登録:図面
    async postZumen(param) {
      if (_.isNil(param.data)) return false;
      let login = this.$store.getters[`LoginApi/getStateLogin`];
      let cs = this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      const params = {
        api_token: login["api_token"],
        name: param.name.split(".")[0],
        construction_site_id: cs[0]["id"], // TOOD 見直し:現場選択実装
        image: param.data.split(",")[1],
        image_type: "jpg",
      };
      return await this.$store.dispatch("ZumensApi/postZumen", params);
    },
    // API 削除:図面
    async deleteZumen(param) {
      let login = this.$store.getters[`LoginApi/getStateLogin`];
      let cs = this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];
      const params = {
        api_token: login["api_token"],
        construction_site_id: cs[0]["id"], // TOOD 見直し:現場選択実装
        zumen_id: param.id,
      };
      return await this.$store.dispatch("ZumensApi/deleteZumen", params);
    },

    // API 撮影ポイントへ写真を追加
    async postPhotos(param) {
      if (_.isNil(param.data)) return false;
      let login = this.$store.getters[`LoginApi/getStateLogin`];
      let cs = this.$store.getters["ConstructionSitesApi/getStateConstructionSites"];

      const binary = await base64ToArrayBuffer(param.data);
      const exif = await EXIF.readFromBinaryFile(binary);

      const params = {
        api_token: login["api_token"],
        name: param.name.split(".")[0],
        construction_site_id: cs[0]["id"],
        zumen_id: param.map_id,
        captcha_point_id: param.id,
        image: param.data.split(",")[1],
        image_id: uuidv1(),
        image_type: param.name.split(".")[1],
        shot_at: exif ? exif.DateTimeOriginal : undefined,
      };
      return await this.$store.dispatch("CaptchaPointApi/postCpAroundPhotograph", params);
    }
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";

.maps {
  width: 382px;
  height: 100%;
  overflow-y: auto;
  .togglHidden {
    display: none;
  }

  .maps-body_count {
    padding: 2px 4px 0px 0px;
  }

  .maps-body {
    text-align: left;
    li {
      list-style: none;
    }
    .maps-body_button-area {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      border-bottom: 1px solid $color-base-25;
      margin: 0 $margin-30;
    }
    .maps-body_list {
      display: flex;
      justify-content: space-between;
      .maps-body_img-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: $margin-5;
        .maps-body_img {
          top: 3px;
          width: auto;
          height: 16px;
          cursor: pointer;
          margin: 4px $margin-20 0 0;
        }
        .maps-body_txt {
          padding: 3px $padding-20 0 0;
          line-height: 1.4;
          word-break: break-all;
          cursor: pointer;
        }
        .maps-body_img-del {
          position: absolute;
          top: 3px;
          width: auto;
          height: 18px;
          cursor: pointer;
        }
      }
      .maps-body_img-wrapper--captcha-points {
        cursor: pointer;
        width: 100%;
      }
      .maps-body_img-del-wrapper {
        right: 20px;
        margin: 0 40px 0 20px;
      }
    }
  }
  .maps-bottom {
    height: 120px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transform: translate(0px, 0px);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .fade-enter,
  .fade-leave-to {
    transform: translateY(-100vh) translateY(0px);
  }

}
</style>
