import * as _ from "lodash";
import api from "@/api/api.js";
import { get360ImageLoader } from "@/util/img";

export const ViewImageApi = {
  namespaced: true,

  state: {
    _360Image: {},
    _360ImageSingle: {},
    zumenImage: {},
    apFileImage: {},
    _3dModelData: {}
  },
  mutations: {
    GET_360IMAGE(state, payload) {
      state._360Image[payload.file_id] = _.cloneDeep(payload.data);
      state._360ImageSingle = _.cloneDeep(payload.data);
    },
    GET_ZUMEN_IMAGE(state, payload) {
      state.zumenImage = _.cloneDeep(payload);
    },
    GET_AP_FILE_IMAGE(state, payload) {
      state.apFileImage = _.cloneDeep(payload);
    },
    GET_3D_MODEL_DATA(state, payload) {
      state._3dModelData = _.cloneDeep(payload);
    },
  },
  actions: {
    async get360Image(context, payload) {
      const service = "get360Image";
      console.log("- API:", service, " prm:", payload);

      //360画像はローディングバー用にprogress必要なのでget360ImageLoader使う
      const response = await get360ImageLoader.load({ params: payload });
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status === 200) {
        res["data"] = response.data.data;
        context.commit("GET_360IMAGE", {
          file_id: payload.file_id,
          data: response.data.data,
        });
      } else {
        res["data"] = response.data;
      }
      console.log("- API:", service, "res:", res);
      return res;
    },
    async getZumenImage(context, payload) {
      const service = "getZumenImage";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status === 200) {
        res["data"] = response.data.data;
        context.commit("GET_ZUMEN_IMAGE", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async getApFileImage(context, payload) {
      const service = "getApFileImage";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status === 200) {
        res["data"] = response.data.data;
        context.commit("GET_AP_FILE_IMAGE", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async get3dModelData(context, payload) {
      const service = "get3DModelData";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status === 200 && response.data.data) {
        res["data"] = JSON.parse(response.data.data);
        context.commit("GET_3D_MODEL_DATA", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    }
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getState360Image: (state) => (file_id) => {
      return state._360Image[file_id];
    },
    getState360ImageSingle: (state) => {
      return state._360ImageSingle;
    },
    getStateZumenImage: (state) => {
      return state.zumenImage;
    },
    getStateApFileImage: (state) => {
      return state.apFileImage;
    },
  },
};
