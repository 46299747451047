import api from "@/api/api.js";

export const CpAroundObject = {
    namespaced: true,
    state: {
        list: {},
        error: undefined
    },
    mutations: {
        setList(state, payload) {
            state.list = payload;
        },
        addListItem(state, payload) {
            state.list.put(payload.id, payload);
        },
        modifyListItem(state, payload) {
            state.list.put(payload.id, payload);
        },
        removeListItem(state, payload) {
            state.list.remove(payload);
        },
        setError(state, payload) {
            state.error = payload;
        },
        reset(state) {
            state.list.clear();
            state.error = undefined;
        }
    },
    actions: {
        async load(context, payload) {
            const service = "getCpAroundObjectList";
            const apiToken = payload["api_token"];
            delete payload.api_token;

            context.commit("setError", undefined);
            return api.get(service, payload, apiToken)
                .then(res => {
                    if (res.status === 200) {
                        context.commit("setList", res.data.data);
                    } else {
                        context.commit("setError", `${res.statusText}(${res.status})`);
                    }
                })
                .catch(err => {
                    context.commit("setError", err);
                });
        },
        async add(context, payload) {
            const service = "postCpAroundObject";
            const apiToken = payload["api_token"];
            delete payload.api_token;

            context.commit('setError', undefined);
            api.post(service, payload, apiToken)
                .then(res => {
                    if (res.status === 200) {
                        context.commit("addListItem", payload);
                    } else {
                        context.commit("setError", `${res.statusText}(${res.status})`);
                    }
                })
                .catch(err => {
                    context.commit("setError", err);
                });
        },
        async modify(context, payload) {
            const service = "patchCpAroundObject";
            const apiToken = payload["api_token"];
            delete payload.api_token;

            context.commit('setError', undefined);
            api.post(service, payload, apiToken)
                .then(res => {
                    if (res.status === 200) {
                        context.commit("modifyListItem", payload);
                    } else {
                        context.commit("setError", `${res.statusText}(${res.status})`);
                    }
                })
                .catch(err => {
                    context.commit("setError", err);
                });
        },
        async remove(context, payload) {
            const service = "patchCpAroundObject";
            const apiToken = payload["api_token"];
            delete payload.api_token;

            context.commit('setError', undefined);
            api.post(service, payload, apiToken)
                .then(res => {
                    if (res.status === 200) {
                        context.commit("removeListItem", payload);
                    } else {
                        context.commit("setError", `${res.statusText}(${res.status})`);
                    }
                })
                .catch(err => {
                    context.commit("setError", err);
                });
        },
    },
    getters: {
        list(state) {
            return state.list;
        }
    }
}
