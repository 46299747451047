<template>
  <v-container fluid class="fill-height">
    <v-row class="fill-height">
      <v-col ref="view">

        <picture360 id="" />

        <event-panel />

        <!-- 移動・回転・拡縮 -->
        <trans-mode-panel
            v-show="transItem"
            v-model="transMode"
        />

        <!-- フッター -->
        <div class="">
          <date-time-picker
              v-model="itemId"
              :items="items"
              dateColumn="shot_at"
              @change="v=>$emit('item-change', v)"
          />
          <div class="footer-button-panel">
            <tooltip-btn label="拡大">
              <button class="footer-btn" @click="$emit('increase-zoom')">
                <v-icon color="white" >fa-regular fa-plus</v-icon>
              </button>
            </tooltip-btn>
            <tooltip-btn label="縮小">
              <button class="footer-btn" @click="$emit('decrease-zoom')">
                <v-icon color="white" >fa-regular fa-minus</v-icon>
              </button>
            </tooltip-btn>
            {{ zoom }}%
          </div>
        </div>
      </v-col>
      <v-col ref="panel">
        <!-- 指摘一覧 -->
        <indication-List v-if="state.value==='indications'" :indications="indications"></indication-List>
        <!-- ファイル一覧 -->
        <file-list v-if="state.value==='files'" :file-list="files"></file-list>
        <!-- 3Dモデル一覧 -->
        <model-list v-if="state.value==='models'" v-model="item.models" :items="models"></model-list>
        <!-- 設定 -->
        <picture-properties v-if="transItem" v-model="transItem.properties"></picture-properties>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {useMachine} from 'xstate-vue2';
import {createMachine, sendParent} from 'xstate';
import {computed, ref} from "vue";
import TransModePanel from "../eventPanel/TransModePanel.vue";
import PictureProperties from "../popup/PictureProperties.vue";
import ModelList from "../popup/ModelList.vue";
import IndicationList from "../popup/IndicationList.vue";
import DateTimePicker from "../datetimepicker/DateTimePicker.vue";
import TooltipBtn from "../tooltip/TooltipBtn.vue";
import FileList from "../fileList/FileList.vue";
import EventPanel from "../eventPanel/EventPanel.vue";
import Picture360 from "../picture/Picture360.vue";
import { TRANS_MODE } from "@/util/constants";

const machine = createMachine({
  id: 'viewer',
  initial: 'initialize',
  states: {
    initialize: {

    },
    idle: {
      entry: 'activate',
      exit: 'inactivate',
      on: {
        'ADD_INDICATION': 'indication',
        'LIST_INDICATIONS': 'indications',
        'ADD_ATTACHMENT': 'attachment',
        'LIST_ATTACHMENTS': 'attachments',
        'LIST_MODELS': 'models',
        'SETTING': 'setting',
      }
    },
    indication: {
      on: {
        'ABORT': 'idle',
        'CLOSE': 'idle'
      },
      initial: 'point',
      states: {
        point: {
          on: {
            'SELECT': 'edit'
          }
        },
        edit: {
          on: {
            'OK': 'save',
          }
        },
        save: {
          invoke: {
            src: 'save-indication',
            onDone: { actions: [sendParent("CLOSE")] },
            onError: { actions: [sendParent("ERROR")] },
          },
        }
      }
    },
    indications: {
      on: {
        'CLOSE': 'idle'
      },
      initial: 'list',
      states: {
        list: {
          on: { 'SELECT': 'detail' }
        },
        detail: {
          on: {
            'OK': 'save',
            'CANCEL': 'list'
          }
        },
        save: {
          invoke: {
            src: 'save-indication',
            onDone: { actions: [sendParent("CLOSE")] },
            onError: { actions: [sendParent("ERROR")] },
          },
        }
      }
    },
    attachment: {
      on: {
        'CLOSE': 'idle'
      },
      initial: 'point',
      states: {
        point: {
          on: {
            'SELECT': 'select'
          }
        },
        select: {
          'OK': 'save',
          'CANCEL': { actions: [sendParent("CLOSE")] }
        },
        save: {
          invoke: {
            src: 'save-attachment',
            onDone: { actions: [sendParent("CLOSE")] },
            onError: { actions: [sendParent("ERROR")] },
          },
        }
      }
    },
    attachments: {
      on: {
        'CLOSE': 'idle'
      },
      initial: 'list',
      states: {
        list: {
          on: { 'SELECT': 'detail' }
        },
        detail: {
          on: {
            'DELETE': 'delete',
            'CANCEL': 'list'
          }
        },
        delete: {
          invoke: {
            src: 'delete-attachment',
            onDone: { actions: [sendParent("CLOSE")] },
            onError: { actions: [sendParent("ERROR")] },
          },
        }
      }
    },
    models: {
      on: {
        'CLOSE': 'idle'
      },
      initial: 'list',
      states: {
        list: {
          on: { 'SELECT': 'setting' }
        },
        setting: {
          on: {
            'OK': 'save',
            'CANCEL': 'list'
          }
        },
        save: {
          invoke: {
            src: 'save-properties',
            onDone: { actions: [sendParent("CLOSE")] },
            onError: { actions: [sendParent("ERROR")] },
          },
        }
      }
    },
    setting: {
      on: {
        // 'OK': 'save',
        'CANCEL': 'idle'
      },
    },
    error: {
      invoke: {
        src: 'error',
        onDone: 'idle',
        onError: { actions: [sendParent("ERROR")] },
      },
    }
  }
});

export default {
  components: {
    Picture360,
    EventPanel,
    FileList, TooltipBtn, DateTimePicker, IndicationList, TransModePanel, ModelList, PictureProperties},
  props: {
    itemId: Number,
    zoom: Number,
    items: Array,
  },
  setup(props) {
    const { state, send } = useMachine(machine);
    const item = computed(() => props.items.find(i => i.id === props.itemId));

    const zoom = ref(100);

    const transItemId = ref();
    const transItem = computed(() => props.items.find(i => i.id === transItemId.value));
    const transMode = ref(TRANS_MODE.TRANSLATE);

    const indications = ref([]);
    const files = ref([]);
    const models = ref([]);

    return {
      state,
      send,
      item,
      zoom,

      transItem,
      transMode,

      indications,
      files,
      models,
    };
  }

}

</script>

<style scoped lang="scss">

</style>
