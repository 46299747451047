import * as _ from "lodash";
import api from "@/api/api.js";

export const ZumensApi = {
  namespaced: true,

  state: {
    zumens: {},
  },
  mutations: {
    GET_ZUMENS(state, payload) {
      state.zumens = _.cloneDeep(payload);
    },
  },
  actions: {
    async getZumens(context, payload) {
      const service = "getZumens";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      console.log("- API:", service, " response.status:", response.status);
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("GET_ZUMENS", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async postZumen(context, payload) {
      const service = "postZumen";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async deleteZumen(context, payload) {
      const service = "deleteZumen";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.delete(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getStateZumens: (state) => {
      return state.zumens;
    },
  },
};
