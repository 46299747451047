/**
 *ツールチップ表示
 */
export const Tooltip = {
  namespaced: true,
  state: {
    isShow: false,
    message: null,
    pos_x: null,
    pos_y: null,
  },
  mutations: {
    SHOW(state, { isShow, message, pos_x, pos_y }) {
      state.isShow = isShow;
      state.message = message;
      state.pos_x = pos_x;
      state.pos_y = pos_y;
    },
    RESET(state, { isShow }) {
      state.isShow = isShow;
      state.message = null;
      state.pos_x = 0;
      state.pos_y = 0;
    },
  },
  actions: {
    show(context, payload) {
      let param = { ...payload };
      param.isShow = true;
      context.commit("SHOW", param);
    },
    close(context) {
      context.commit("RESET", { isShow: false });
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getIsShow: (state) => {
      return state.isShow;
    },
    getMessage: (state) => {
      return state.message;
    },
    getPosition: (state) => {
      return `left:${state.pos_x}px;top:${state.pos_y}px;`;
    },
  },
};
