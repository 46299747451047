<template>
  <div>
    <slot :slotProps="slotProps" />
  </div>
</template>
<script>

import * as _ from "lodash";

export default {
  // data: () => {
  //   return {
  //     slotProps: {
  //       item: {},
  //     },
  //   };
  // },
  props: {
    id: {
      type: String,
    },
    item_index: {
      type: Number,
      defaultValue: -1,
    },
    items: {
      type: Array,
    },
  },
  computed: {
    // 撮影ポイント
    captchaPoint() {
      return this.$store.getters["CaptchaPointApi/getStateCaptchaPoint"];
    },
    item() {
console.log("item called.");
      return !_.isEmpty(this.items) && !_.isNil(this.item_index)
          ? this.items[this.item_index] : null;
    },
    slotProps() {
      const { item } = this;
      if (item) {
        return { item: {
          cp_around_photograph_id: item.id,
          file_id: item.file_id,
          file_type: item.file_type,
          informations: item.infomation,
          files: item.files,
          models: item.models,
          panorama_flg: item.panorama_flg,
          properties: item.properties,
        }};
      } else {
        return { item : { } };
      }
    }
  },
//   watch: {
//     item_index() {
// console.log("item_index:" + this.item_index);
//       const { item } = this;
//       if (item) {
//         this.slotProps.item = {
//           cp_around_photograph_id: item.id,
//           file_id: item.file_id,
//           file_type: item.file_type,
//           informations: item.infomation,
//           files: item.files,
//           models: item.models,
//           panorama_flg: item.panorama_flg,
//           properties: item.properties,
//         };
//       } else {
//         this.slotProps.item = { };
//       }
// console.log(JSON.stringify(this.item));
//       this.$emit('selected', {
//         id: this.id,
//         slotProps: this.slotProps
//       });
//     },
//
//   },
//   methods: {
//   },
};
</script>
