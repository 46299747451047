import * as _ from "lodash";

// Getパラメータ分割
export const getGetParamMap = () => {
  let locationSearch = decodeURIComponent(location.search);
  if (locationSearch.length === 0 || locationSearch.indexOf("?") !== 0) {
    return [];
  }
  let str = locationSearch.replace("?", "");
  let params = str.split("&");
  let paramMap = {};
  _.forEach(params, (val) => {
    let param = val.split("=");
    paramMap[param[0]] = param[1];
  });
  return paramMap;
};
