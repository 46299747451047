import * as THREE from 'three';
import { legacyJSON2NewJSON } from "./LegacyJSON2NewJSON";

export class JSONLoader extends THREE.FileLoader {
  load(url, onLoad, onProgress, onError) {

    const fileLoader = new THREE.FileLoader(this.manager);
    fileLoader.load(url, data => {
      try {
          const mesh = this.parse(legacyJSON2NewJSON(data));
          if (onLoad) {
            onLoad(mesh);
          }
      } catch (e) {
        if (onError) {
          onError(e);
        }
      }
    }, onProgress, onError);
  }

  parse(json) {
    const jsonObj = JSON.parse(json);
    const { textures } = jsonObj;
    jsonObj.textures = [];
    const obj = new THREE.ObjectLoader().parse(jsonObj);

    // テクスチャ設定
    const loader = new THREE.TextureLoader();
    if(textures) {
      textures.forEach((t, i) => {
        const texture = loader.load(t);
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

        const indexPart = ('' + (i + 1)).padStart(3,  '0');
        const key = `mesh_T${indexPart}_`;
        console.log('texture key = ' + key);
        obj.traverse(o => {
          if (o.isMesh && o.uuid.substring(0, 10) === key) {
            o.material.map = texture;
            o.updateMorphTargets();
          }
        });
      });
    }

    return obj;
  }
}
