<template>
  <div class="input-popup">
    <div v-show="showFlg" id="input-popup" ref="input-popup" class="background">
      <div class="popup">
        <div>{{ consts.title }}</div>
        <div class="inner">
          <div class="inner-textarea">
            緯度：<input
              type="text"
              class="input"
              ref="input-lat"
              v-model="lat"
              @blur="isValidLat"
            />
          </div>
          <div class="inner-textarea">
            経度：<input
              type="text"
              class="input"
              ref="input-lng"
              v-model="lng"
              @blur="isValidLng"
            />
          </div>
        </div>
        <div class="alert-message">
          <div v-if="messageLat">{{ messageLat }}</div>
          <div v-if="messageLng">{{ messageLng }}</div>
        </div>
        <div>
          <button :id="html.ID_CLOSE" class="button">閉じる</button>
          <button :id="html.ID_ADD" class="button" @click="isValid">
            追加
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
import { clip } from "@/util/icon.js";

export default {
  components: {},
  data: () => {
    return {
      icon: { clip },
      consts: {
        title: "",
      },
      valid: {
        lat: {
          maxLength: 11,
          range: {
            min: 0,
            max: 90,
          },
        },
        lng: {
          maxLength: 12,
          range: {
            min: 0,
            max: 180,
          },
        },
      },
      errMsg: {
        lat: {
          required: "緯度は必須入力です",
          length: "緯度は11文字以下で入力してください",
          format: "緯度は半角数字で入力してください",
          formatDecimal: "緯度の小数点は8位以上で入力してください",
          range: "緯度は0〜90の範囲で入力してください",
        },
        lng: {
          required: "経度は必須入力です",
          length: "経度は12文字以下で入力してください",
          format: "経度は半角数字で入力してください",
          formatDecimal: "経度の小数点は8位以上で入力してください",
          range: "経度は0〜180の範囲で入力してください",
        },
      },
      showFlg: false, // モーダル表示
      watchCntLat: 0,
      watchCntLng: 0,
      messageLat: null,
      messageLng: null,
      lat: null,
      lng: null,
      html: {
        ID_CLOSE: "inputLatLngBtnClose",
        ID_ADD: "inputLatLngBtnAdd",
      },
    };
  },
  props: {},
  mounted() {},
  methods: {
    init() {
      this.messageLat = null;
      this.messageLng = null;
      this.watchCntLat = 0;
      this.watchCntLng = 0;
      this.showFlg = false;
    },
    async call(consts, lat, lng) {
      // 定数、および変数の置換
      let res = { result: false };
      if (!this.vaidReplaceConsts(consts)) return res;
      this.lat = lat;
      this.lng = lng;
      this.showFlg = true;

      // ポップアップのボタンが押下されるまで待機
      const awaitForClick = () => {
        return new Promise((resolve) => {
          const listener = resolve;

          let add = document.getElementById(this.html.ID_ADD);
          add.addEventListener("click", listener, { once: true });

          let cancel = document.getElementById(this.html.ID_CLOSE);
          cancel.addEventListener("click", listener, { once: true });
        });
      };
      let result = await awaitForClick();

      // 入力チェック
      if (result.target.id === this.html.ID_ADD) {
        if (this.isValid()) {
          res.result = true;
          res.lat = _.cloneDeep(this.lat);
          res.lng = _.cloneDeep(this.lng);
          this.init();
        } else {
          this.call(consts, this.lat, this.lng);
        }
      } else {
        res.result = false;
        res.lat = null;
        res.lng = null;
        this.init();
      }
      return res;
    },
    // 定数の置換
    vaidReplaceConsts(consts) {
      if (!this.isNonDiffKeys(consts)) return false;
      this.consts = consts;
      return true;
    },
    isNonDiffKeys(consts) {
      if (_.isNil(consts)) return false;
      const defKeys = Object.keys(this.consts);
      const originKeys = Object.keys(consts);
      return _.xor(defKeys, originKeys).length == 0;
    },
    // バリデート
    isValid() {
      let validLatFlg = this.isValidLat();
      let validLngFlg = this.isValidLng();
      return validLatFlg && validLngFlg;
    },
    // 緯度
    isValidLat() {
      this.messageLat = null;
      if (_.isNil(this.lat) || this.lat.length === 0) {
        this.messageLat = this.errMsg.lat.required;
      }
      if (!this.messageLat && this.lat.length > this.valid.lat.maxLength) {
        this.messageLat = this.errMsg.lat.length;
      }
      if (!this.messageLng && !this.lat.match(/^[-]?([1-9]\d*|0)(\.\d+)?$/)) {
        this.messageLat = this.errMsg.lat.format;
      }
      if (
        !this.messageLat &&
        !this.lat.match(/^[-]?([1-9]\d*|0)(?:\.\d{1,8})?$/)
      ) {
        this.messageLat = this.errMsg.lat.formatDecimal;
      }
      if (
        !this.messageLat &&
        !(
          this.lat >= this.valid.lat.range.min &&
          this.lat <= this.valid.lat.range.max
        )
      ) {
        this.messageLat = this.errMsg.lat.range;
      }
      return !this.messageLat;
    },
    // 経度
    isValidLng() {
      this.messageLng = null;
      if (_.isNil(this.lng) || this.lng.length === 0) {
        this.messageLng = this.errMsg.lng.required;
      }
      if (!this.messageLng && this.lng.length > this.valid.lng.maxLength) {
        this.messageLng = this.errMsg.lng.length;
      }
      if (!this.messageLng && !this.lng.match(/^[-]?([1-9]\d*|0)(\.\d+)?$/)) {
        this.messageLng = this.errMsg.lng.format;
      }
      if (
        !this.messageLng &&
        !this.lng.match(/^[-]?([1-9]\d*|0)(?:\.\d{1,8})?$/)
      ) {
        this.messageLng = this.errMsg.lng.formatDecimal;
      }
      if (
        !this.messageLng &&
        !(
          this.lng >= this.valid.lng.range.min &&
          this.lng <= this.valid.lng.range.max
        )
      ) {
        this.messageLng = this.errMsg.lng.range;
      }
      return !this.messageLng;
    },
  },
  watch: {
    lat: {
      deep: true,
      immediate: false,
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue && this.watchCntLat > 0) {
          this.isValidLat();
        }
        this.watchCntLat++;
      },
    },
    lng: {
      deep: true,
      immediate: false,
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue && this.watchCntLng > 0) {
          this.isValidLng();
        }
        this.watchCntLng++;
      },
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";

.input-popup {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup {
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    // background: white;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }

  .body {
    padding: $padding-20 0;
  }

  .inner {
    // display: flex;
    // justify-content: space-around;
    padding: $padding-20 0;
    .inner-textarea {
      display: flex;
      justify-content: space-around;
    }
  }

  .input {
    font-size: $font-size-30;
    padding: 4px;
    width: 70%;
    border: none;
    transition: all 0.2s ease-in;
    /// nomal mode
    // border-bottom: 1px solid #ccc;
    /// dark mode
    background: $color-base-10;
    border-bottom: 1px solid $color-base-00;
    color: $color-base-50;
    &:focus {
      /// nomal mode
      // background-color: #f8f8f8;
      // border-bottom-color: black;
      /// dark mode
      background-color: $color-base-00;
    }
  }

  .button {
    border: none;
    background: none;
    font-size: 14px;
    color: $col-btn-positive;
    margin-left: 8px;
    min-width: 120px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: $padding-10;
  }

  .alert-message {
    color: $col-alert;
    font-size: $font-size-20;
    padding: 0 $padding-10 $padding-10;
    line-height: 1.4;
  }
}
</style>
