<template>
  <div class="input-popup">
    <div v-show="showFlg" id="input-popup" ref="input-popup" class="background">
      <div class="popup">
        <div>{{constants.title}}</div>
        <div class="inner">
          <input type="text" class="input" ref="input" v-model="value" @blur="isValid" />
        </div>
        <div v-if="message" class="alert-message">{{message}}</div>
        <div>
          <button :id="html.ID_CLOSE" class="button">閉じる</button>
          <button :id="html.ID_ADD" class="button" @click="isValid">追加</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
import { MAX_FILE_SIZE } from "@/util/constants.js";
import { clip } from "@/util/icon.js";

export default {
  data: () => {
    return {
      icon: { clip },
      constants: {
        title: "",
        keyword: "",
        maxLength: 20,
      },
      VALIDATE_FILE: {
        required: {
          isErr: false,
          message: "ファイルが選択されていません",
        },
        size: {
          isErr: true,
          value: MAX_FILE_SIZE,
          message: "ファイルサイズは2MB以下にしてください",
        },
      },
      defErrMsg: {
        required: "$keywordは必須入力です",
        length: "$keywordは$maxLength文字以下で入力してください",
      },
      errMsg: {
        required: "",
        length: "",
      },
      showFlg: false, // モーダル表示
      options: { showAddFileFlg: false }, // 表示オプション
      watchCnt: 0,
      message: null,
      value: null,
      fileData: null,
      html: {
        ID_CLOSE: "inputPopupBtnClose",
        ID_ADD: "inputPopupBtnAdd",
      },
    };
  },
  props: {},
  mounted() {},
  methods: {
    init() {
      this.message = null;
      this.watchCnt = 0;
      this.showFlg = false;
      this.fileData = null;
      this.setOptions({ showAddFileFlg: false });
    },
    async call(constants, value, options) {
      // 定数、および変数の置換
      let res = { result: false };
      if (!this.validReplaceConstants(constants)) return res;
      this.setOptions(options);
      this.value = value;
      this.showFlg = true;

      // ポップアップのボタンが押下されるまで待機
      const awaitForClick = () => {
        return new Promise((resolve) => {
          const listener = resolve;

          let add = document.getElementById(this.html.ID_ADD);
          add.addEventListener("click", listener, { once: true });

          let cancel = document.getElementById(this.html.ID_CLOSE);
          cancel.addEventListener("click", listener, { once: true });
        });
      };
      let result = await awaitForClick();

      // 入力チェック
      if (result.target.id === this.html.ID_ADD) {
        if (this.isValid()) {
          res.result = true;
          res.value = _.cloneDeep(this.value);
          res.fileData = _.cloneDeep(this.fileData);
          this.init();
        } else {
          return await this.call(constants, this.value);
        }
      } else {
        res.result = false;
        res.text = null;
        this.init();
      }

      return res;
    },
    // 定数の置換
    validReplaceConstants(constants) {
      if (!this.isNonDiffKeys(constants)) return false;
      this.consts = constants;
      const KEY_WORD = "$keyword";
      const MAX_LENGTH = "$maxLength";

      this.errMsg.required = this.defErrMsg.required.replace(
        KEY_WORD,
        this.consts.keyword
      );

      this.errMsg.length = this.defErrMsg.length.replace(
        KEY_WORD,
        this.consts.keyword
      );
      this.errMsg.length = this.errMsg.length.replace(
        MAX_LENGTH,
        this.consts.maxLength
      );
      return true;
    },
    isNonDiffKeys(constants) {
      if (_.isNil(constants)) return false;
      const defKeys = Object.keys(this.consts);
      const originKeys = Object.keys(constants);
      return _.xor(defKeys, originKeys).length === 0;
    },
    setOptions(options) {
      if (_.isNil(options)) return;
      if ("showAddFileFlg" in options)
        this.options.showAddFileFlg = options.showAddFileFlg;
    },
    // バリデート
    isValid() {
      if (_.isNil(this.value) || this.value.length === 0) {
        this.message = this.errMsg.required;
        return false;
      }
      if (this.value.length > this.consts.maxLength) {
        this.message = this.errMsg.length;
        return false;
      }
      this.message = null;
      return true;
    },
    // setFileData(param) {
    //   this.fileData = param;
    // },
  },
  watch: {
    value: {
      deep: true,
      immediate: false,
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue && this.watchCnt > 0) {
          this.isValid();
        }
        this.watchCnt++;
      },
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";

.input-popup {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup {
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    // background: white;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }

  .body {
    padding: $padding-20 0;
  }

  .inner {
    display: flex;
    justify-content: space-around;
    padding: $padding-20 0;
  }

  .input {
    font-size: $font-size-30;
    padding: 4px;
    width: 100%;
    border: none;
    transition: all 0.2s ease-in;
    /// normal mode
    // border-bottom: 1px solid #ccc;
    /// dark mode
    background: $color-base-10;
    border-bottom: 1px solid $color-base-00;
    color: $color-base-50;
    &:focus {
      /// normal mode
      // background-color: #f8f8f8;
      // border-bottom-color: black;
      /// dark mode
      background-color: $color-base-00;
    }
  }
  .add-file_area {
    padding-bottom: $padding-20;
  }
  // .add-file_area {
  //   display: flex;
  //   justify-content: center;
  //   border: 1px dotted gray;
  //   border-radius: 4px;
  //   padding: $padding-5 0;
  //   margin: 0 0 $padding-20;
  //   font-size: $font-size-20;
  //   .add-file_icon-wrapper {
  //     padding: 2px 2px 0px;
  //     .add-file_icon {
  //       width: 16px;
  //       height: auto;
  //     }
  //   }
  // }

  .button {
    border: none;
    background: none;
    font-size: 14px;
    color: $col-btn-positive;
    margin-left: 8px;
    min-width: 120px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: $padding-10;
  }

  .alert-message {
    color: $col-alert;
    font-size: $font-size-20;
    padding: $padding-10;
  }
}
</style>
