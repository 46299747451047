<template>

  <v-container fluid class="fill-height">

    <picture-selector v-if="showSelector" :items="items" v-model="viewProps" :show-flg="true"/>

    <v-row v-else v-for="r in divideLayoutDef.rows" :key="r" class="fill-height">
      <v-col v-for="c in divideLayoutDef.cols" :key="c">
        <viewer v-for="p in [viewProps[divideLayoutDef.cols * (r - 1) + c - 1]]"
                :item-id="p.itemId"
                :zoom="p.zoom"
                :items="items"
                @itemChange="v=>p.itemId=v" />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import {computed, ref, watchEffect} from "vue";
import Viewer from "../components/viewer/Viewer.vue";
import PictureSelector from "../components/popup/PictureSelector.vue";

// const divideCounts = [1,2,4,8,16];
// 分割数とレイアウトのマップ
const divideLayoutDefs = {
  1: {rows: 1, cols: 1},
  2: {rows: 1, cols: 2},
  4: {rows: 2, cols: 2},
  8: {rows: 2, cols: 4},
  16: {rows: 4, cols: 4}
};

// 分割数のリスト
const divideCounts = Object.keys(divideLayoutDefs);

export default {
  components: {PictureSelector, Viewer},
  props: {
    zumenId: { type: Number, require: true },
    captchaPointId: { type: Number, require: true },
  },
  setup(props, ctx) {

    // ロード中
    const loading = ref(false);

    // ファイルセレクタ
    const showSelector = ref(true);

    // 分割数
    const divideCount = ref(divideCounts[1]);

    // レイアウト
    const divideLayoutDef = computed(() => divideLayoutDefs[divideCount.value]);
    const viewProps = ref([]);

    console.log('ctx:' + JSON.stringify(ctx));
    // 画像/3Dモデル等ファイル ロード結果の格納されているストアから取得
    const items = computed(() => ctx.root.$store.getters["CaptchaPointApi/getStateCaptchaPoint"] || {} );

    // 撮影ポイント変更監視
    watchEffect( async () => {
      loading.value = true;
      try {
        // ロード
        await ctx.root.$store.dispatch("CaptchaPointApi/getCaptchaPoint", {
          captcha_point_id: props.captchaPointId
        });
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    });
    
    // 分割数変更監視
    watchEffect(async () => {
      for (let i = 0; i < divideCount.value; i++) {
        if (!viewProps.value[i]) {
          viewProps.value[i] = {
            itemId: null,
            camera: {
              matrix: [ 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1 ],
              zoom: 100
            }
          }
        }
      }
    });

    return {
      showSelector,
      divideCounts,
      divideCount,
      divideLayoutDef,
      items,
      viewProps
    }
  },
}
</script>

<style scoped lang="scss">

</style>