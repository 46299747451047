<template>
  <div class="component" v-if="showFlg" @click.self="close">
    <div class="dialog">
      <div class="wrapper">
        <Indication :indications="indications"
                    :construction_site_id="construction_site_id"
                    :zumen_id="zumen_id"
                    :captcha_point_id="captcha_point_id"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Indication from "@/components/sidemenu/Indication";

export default {
  name: "IndicationList",
  components: {
    Indication,
  },
  props: {
    showFlg: {
      type: Boolean,
      required: true,
    },
    indications: {
      type: Array,
      required: true
    },
    construction_site_id: {
      type: Number,
      default: 0,
      required: true,
    },
    zumen_id: {
      type: Number,
      default: 0,
      required: true,
    },
    captcha_point_id: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
}
</script>

<style scoped lang="scss">
  @import "@/style/config.scss";

  div.component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);

    div.dialog {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .wrapper {
        position: absolute;
        top:16px;
        right: 0;
        bottom: 0;
        left: 0;
      }
      background-color: white;
    }

  }
</style>
