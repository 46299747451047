<template>
  <div class="jobsite">
    <div class="jobsite-layout">
      <!-- ヘッダー -->
      <div class="jobsite_header" v-if="!isLinkBasepage">
        <div class="header-image-wrapper">
          <img class="" src="@/assets/img/sample-header.png" alt="header-image" />
        </div>
      </div>
      <!-- 本体 -->
      <div class="jobsite_body">
        <div class="base_left" v-if="!isLinkBasepage">
          <!-- 左メニュー -->
          <div class="jobsite_left-side-menu">
            <div
              v-if="leftSideMenuStatus <= LEFT_SIDE_MENU_STATUS.OPEN"
              :class="
                hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)
                  ? 'left-side-menu_open'
                  : 'left-side-menu_close'
              "
              @mouseover="setLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)"
              @mouseleave="setLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.CLOSE)"
            >
              <div class="menu-default">
                <div class="menu-top">
                  <div
                    class="menu"
                    @click="setLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.MAPS)"
                  >
                    <div>
                      <img class="btn-navi__icon" src="@/assets/img/icon-camera@2x.png" alt="" />
                    </div>
                    <div
                      v-if="hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)"
                      class="btn-navi__label"
                    >
                      図面
                    </div>
                  </div>
                  <div
                    class="menu"
                    @click="setLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.POINTED_OUT)"
                  >
                    <div>
                      <img class="btn-navi__icon" src="@/assets/img/icon-footprint@2x.png" alt="" />
                    </div>
                    <div
                      v-if="hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)"
                      class="btn-navi__label"
                    >
                      指摘
                    </div>
                  </div>
                  <!-- TODO:Phase3まで機能実装 -->
                  <div class="menu">
                    <div>
                      <img class="btn-navi__icon" src="@/assets/img/icon-clock@2x.png" alt="" />
                    </div>
                    <div
                      v-if="hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)"
                      class="btn-navi__label"
                    >
                      予定
                    </div>
                  </div>

                  <div class="menu">
                    <div>
                      <img class="btn-navi__icon" src="@/assets/img/icon-clock@2x.png" alt="" />
                    </div>
                    <div
                      v-if="hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)"
                      class="btn-navi__label"
                    >
                      実績
                    </div>
                  </div>
                </div>

                <div class="menu-default-bottom">
                  <SingleSubmit :onClick="mailContact">
                    <div class="menu">
                      <div>
                        <img class="btn-navi__icon" src="@/assets/img/icon-question@2x.png" alt="" />
                      </div>
                      <div
                        v-if="hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.OPEN)"
                        class="btn-navi__label btn-navi__label"
                      >
                        お問い合わせ
                      </div>
                    </div>
                  </SingleSubmit>
                </div>
              </div>
              <div class="menu-detail">
                <div
                    v-if="leftSideMenuStatus > LEFT_SIDE_MENU_STATUS.OPEN"
                    class="menu-detail-header"
                >
                  <div class="menu-detail-header_img-wrapper">
                    <img class="menu-detail-header_img"
                         :src="icon.double_arrow" @click="setLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.CLOSE)" alt="閉じる" />
                  </div>
                </div>

                <!-- 図面一覧 -->
                <div
                    v-if="hasLeftSideMenuStatus(LEFT_SIDE_MENU_STATUS.MAPS)"
                    class="menu-detail_maps"
                >
                  <Maps
                      :items="maps"
                      @selVRImg="selVRImg"
                      @add="loadMaps"
                      @show="showModalMap"
                      @del="loadMaps"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- メインパネル -->
        <div class="base_main">
          <!-- メイン -->
          <div id="jobsite_picture" class="jobsite_picture">

            <div id="jobsite_picture360" class="jobsite_picture360">

              <!-- 左側 -->
              <picture360-controller class="picture360-controller"
                :id="FLOOR_TYPE.FLOOR1"
                :item_index="floor1_item_index"
                :items="items"
                v-slot="{ slotProps }"
                v-show="!hideLeftPanel"
              >
                <!-- ビューパネル -->
                <div class="view-panel">

                  <!-- 3Dビュー -->
                  <picture360 ref="picture360-FLOOR1"
                    :id="FLOOR_TYPE.FLOOR1"
                    :zumen_id="zumen_id"
                    :captcha_point_id="captcha_point_id"
                    :item="slotProps.item"
                    :camera_attr="floor1_camera"
                    :zoom_value="floor1_zoom_value"
                    :panel_status="floor1_panel_status"
                    :camera_control="cameraControlType[0]"
                    :models="floor1_models"
                    :transTargetId="floor1_transTargetId"
                    :transMode="floor1_transMode"
                    :transProps="floor1_transProps"
                    @onChange="syncCameraPosition"
                    @onClickPanelEvent="dispatchPanelEvent"
                    @onViewTypeChange="setFloorViewType"
                    @onChangePanelStatus="onChangePanelStatus($event, FLOOR_TYPE.FLOOR1)"
                    @onZoom="setFloor1Zoom"
                    @onTrans="onFloor1Trans"
                    @onVideoStateChange="syncVideoIfNeeds($event, FLOOR_TYPE.FLOOR1)"
                  />

                  <!-- UIパネル -->
                  <EventPanel
                    v-show="floor1_showEventPanel"
                    :isViewPlane="isViewPlane(floor1_view_type)"
                    @onDelete="deleteCpAroundPhotograph(slotProps.item.cp_around_photograph_id)"
                    @openIndicationList="isOpenFileList1 = false; floor1_showModelList = false; floor1_showIndicationList = true"
                    @openFileList="isOpenFileList1 = true; floor1_showModelList = false; floor1_showIndicationList = false"
                    @openModelList="isOpenFileList1 = false; floor1_showIndicationList = false; floor1_showModelList = true"
                    @onChangePanelStatus="onChangePanelStatus($event, FLOOR_TYPE.FLOOR1)"
                  />

                  <!-- 移動・回転・拡縮 -->
                  <TransModePanel
                    v-if="floor1_showPictureProperties"
                    v-model="floor1_transMode"
                  />

                  <!-- フッター -->
                  <div class="jobsite_footer-menu">
                    <div class="jobsite_diff">
                      <div v-if="items" class="jobsite_diff-wrapper">
                        <DateTimePicker
                            v-model="floor1_item_index"
                            :items="items"
                            dateColumn="shot_at"
                        />
                        <div class="footer-button-panel">
                          <tooltip-btn label="拡大">
                            <button class="footer-btn" @click="setFloor1Zoom(floor1_zoom_value + 50)">
                              <v-icon color="white">fa-regular fa-plus</v-icon>
                            </button>
                          </tooltip-btn>

                          <tooltip-btn label="縮小">
                            <button class="footer-btn" @click="setFloor1Zoom(floor1_zoom_value - 50)">
                              <v-icon color="white" >fa-regular fa-minus</v-icon>
                            </button>
                          </tooltip-btn>
                          {{ floor1_zoom_value }}%

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 汎用パネル -->
                <div class="general-panel px-2" v-if="hideRightPanel">

                  <!-- 指摘一覧 -->
                  <indication-list
                      class="panel"
                      :showFlg="floor1_showIndicationList"
                      :indications="slotProps.item.informations"
                      :construction_site_id="construction_site_id"
                      :zumen_id="zumen_id"
                      :captcha_point_id="captcha_point_id"
                      @close="floor1_showIndicationList = false"
                  />

                  <!-- ファイルリスト -->
                  <FileList
                      class="panel"
                      :isOpen="isOpenFileList1"
                      :item="slotProps.item"
                      @onClose="isOpenFileList1 = false"
                  />

                  <!-- 3Dモデル一覧 -->
                  <model-list
                      class="panel"
                      v-model="floor1_modelIds"
                      :show-flg="floor1_showModelList"
                      :base-item="floor1_cp_around_photo"
                      :items="items"
                      @close="floor1_showModelList = false"
                      @edit="floor1_transTargetId=$event.id"
                  />

                  <!-- 座標入力 -->
                  <v-slide-x-transition>
                    <picture-properties
                        class="panel"
                        v-if="floor1_showPictureProperties"
                        v-model="floor1_transProps"
                        :item_name="getItem(floor1_transTargetId)?.file_name"
                        :is_panorama="floor1_transTargetId===floor1_cp_around_photo_id&&floor1_cp_around_photo?.panorama_flg"
                        @close="floor1_transTargetId=null;floor1_panel_status=floor1_showModelList?floor1_panel_status:null;"
                        @save="patchCpAroundPhotograph(floor1_transTargetId, floor1_transProps)"
                    />
                  </v-slide-x-transition>
                </div>

              </picture360-controller>

              <!-- セパレータ -->
              <div class="separator" v-if="dualFlag && !hideRightPanel"></div>

              <!-- 右側 -->
              <picture360-controller class="picture360-controller"
                :id="FLOOR_TYPE.FLOOR2"
                :item_index="floor2_item_index"
                :items="items"
                v-slot="{ slotProps }"
                v-if="dualFlag"
                v-show="!hideRightPanel"
              >
                <!-- ビューパネル -->
                <div class="view-panel">

                  <!-- 3Dビュー -->
                  <picture360 ref="picture360-FLOOR2"
                    :id="FLOOR_TYPE.FLOOR2"
                    :zumen_id="zumen_id"
                    :captcha_point_id="captcha_point_id"
                    :zoom_value="floor2_zoom_value"
                    :camera_attr="floor2_camera"
                    :panel_status="floor2_panel_status"
                    :camera_control="cameraControlType[1]"
                    :models="floor2_models"
                    :transTargetId="floor2_transTargetId"
                    :transMode="floor2_transMode"
                    :transProps="floor2_transProps"
                    :item="slotProps.item"
                    :sync_camera="sync_camera"
                    @onChange="syncCameraPosition"
                    @onClickPanelEvent="dispatchPanelEvent"
                    @onViewTypeChange="setFloorViewType"
                    @onChangePanelStatus="onChangePanelStatus($event, FLOOR_TYPE.FLOOR2)"
                    @onZoom="setFloor2Zoom"
                    @onTrans="onFloor2Trans"
                    @onVideoStateChange="syncVideoIfNeeds($event, FLOOR_TYPE.FLOOR2)"
                  />

                  <!-- UIパネル -->
                  <EventPanel
                    v-show="floor2_showEventPanel"
                    :isViewPlane="isViewPlane(floor2_view_type)"
                    @onDelete="deleteCpAroundPhotograph(slotProps.item.cp_around_photograph_id)"
                    @openIndicationList="isOpenFileList2 = false; floor2_showModelList = false; floor2_showIndicationList=true"
                    @openFileList="isOpenFileList2 = true; floor2_showModelList = false; floor2_showIndicationList=false"
                    @openModelList="isOpenFileList2 = false; floor2_showIndicationList = false; floor2_showModelList = true"
                    @onChangePanelStatus="onChangePanelStatus($event, FLOOR_TYPE.FLOOR2)"
                  />

                  <!-- 移動・回転・拡縮 -->
                  <TransModePanel
                    v-show="floor2_showPictureProperties"
                    v-model="floor2_transMode"
                  />

                  <!-- フッター -->
                  <div class="jobsite_footer-menu">
                    <div class="jobsite_diff">
                      <div class="jobsite_diff-wrapper">
                        <DateTimePicker
                            v-model="floor2_item_index"
                            :items="items"
                            dateColumn="shot_at"
                        />
                        <div class="footer-button-panel">
                          <tooltip-btn label="拡大">
                            <button class="footer-btn" @click="setFloor2Zoom(floor2_zoom_value + 50)">
                              <v-icon color="white" >fa-regular fa-plus</v-icon>
                            </button>
                          </tooltip-btn>

                          <tooltip-btn label="縮小">
                            <button class="footer-btn" @click="setFloor2Zoom(floor2_zoom_value - 50)">
                              <v-icon color="white" >fa-regular fa-minus</v-icon>
                            </button>
                          </tooltip-btn>

                          {{ floor2_zoom_value }}%

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 汎用パネル -->
                <div class="general-panel px-2" v-if="hideLeftPanel">

                  <!-- 指摘一覧 -->
                  <IndicationList
                      :show-flg="floor2_showIndicationList"
                      :indications="slotProps.item.informations"
                      :construction_site_id="construction_site_id"
                      :zumen_id="zumen_id"
                      :captcha_point_id="captcha_point_id"
                      @close="floor2_showIndicationList = false"
                  />

                  <!-- ファイルリスト -->
                  <FileList
                      class="panel"
                      :isOpen="isOpenFileList2"
                      :item="slotProps.item"
                      @onClose="isOpenFileList2 = false"
                  />

                  <!-- 3Dモデル一覧 -->
                  <model-list
                      v-model="floor2_modelIds"
                      :show-flg="floor2_showModelList"
                      :base-item="floor2_cp_around_photo"
                      :items="items"
                      @close="floor2_showModelList=false"
                      @edit="floor2_transTargetId=$event.id"
                  />

                  <!-- 座標入力 -->
                  <picture-properties
                      class="panel"
                      v-if="floor2_showPictureProperties"
                      :item_name="getItem(floor2_transTargetId)?.file_name"
                      :is_panorama="floor2_transTargetId===floor2_cp_around_photo_id&&floor2_cp_around_photo?.panorama_flg"
                      v-model="floor2_transProps"
                      @close="floor2_transTargetId=null;floor2_panel_status=floor2_showModelList?floor2_panel_status:null;"
                      @save="patchCpAroundPhotograph(floor2_transTargetId, floor2_transProps)"
                  />
                </div>
              </picture360-controller>
            </div>

            <!-- 画像選択アイコン -->
            <v-btn class="show_photo_selector"
                   @click.stop="showPictureSelector = true; floor1_showIndicationList = false; isOpenFileList1 = false; floor1_showModelList = false; floor1_panel_status = null; floor1_transTargetId = null; floor2_showIndicationList = false; isOpenFileList2 = false; floor2_showModelList = false; floor2_panel_status = null; floor2_transTargetId = null;">
              <v-icon size="20">fa-regular fa-list-radio</v-icon>
            </v-btn>

            <!-- 鍵アイコン -->
            <v-container class="jobsite_picture360__lock d-flex">
              <v-row justify="center" class="align-center flex-fill">
<!--                <ButtonLock v-if="showLock" v-model="sync_camera" @change="syncLeftToRight"/>-->

                <!-- 鍵アイコン　ロック状態 -->
                <v-btn v-if="showLock && sync_camera" color="white" fab outlined elevation="0" small
                       class="button orange" @click="syncCameraStop">
<!--                  <v-icon>mdi-lock-outline</v-icon>-->
                  <v-icon size="20">fa-regular fa-lock-keyhole</v-icon>
                </v-btn>

                <!-- 鍵アイコン　アンロック状態 -->
                <v-btn v-else-if="showLock && !sync_camera" fab outlined elevation="0" small
                       class="button white-button" @click="syncCameraStart">
<!--                  <v-icon>mdi-lock-open-outline</v-icon>-->
                  <v-icon size="20">fa-regular fa-unlock-keyhole</v-icon>
                </v-btn>

                <!-- 右パネル閉じるボタン -->
                <v-btn v-if="floor1_showIndicationList || isOpenFileList1 || floor1_showModelList || floor1_showPictureProperties" fab outlined elevation="0" small
                       class="button white-button"
                       @click="floor1_showIndicationList = false; isOpenFileList1 = false; floor1_showModelList = false; floor1_panel_status = null; floor1_transTargetId = null; saveModelSelection(floor1_cp_around_photo_id, floor1_modelIds)">
                  <v-icon>fa-regular fa-angle-left</v-icon>
                </v-btn>

                <!-- 左パネル閉じるボタン -->
                <v-btn v-if="floor2_showIndicationList || isOpenFileList2 || floor2_showModelList || floor2_showPictureProperties" fab outlined elevation="0" small
                       class="button white-button"
                       @click="floor2_showIndicationList = false; isOpenFileList2 = false; floor2_showModelList = false; floor2_panel_status = null; floor2_transTargetId = null; saveModelSelection(floor2_cp_around_photo_id, floor2_modelIds)">
                  <v-icon>fa-regular fa-angle-right</v-icon>
                </v-btn>
              </v-row>
            </v-container>

          </div>

        </div>
      </div>
    </div>

    <!-- モーダル -->
    <ModalMap
        :showFlg="showModalMapFlg"
        :maps="maps"
        :map_id="select_map_id"
        @add="loadMaps"
        @sel="selVRImg"
        @del="loadMaps"
        @close="closeModalMap"
    />

    <PictureSelector
        :showFlg="showPictureSelector"
        :loading="captcha_point_loading"
        :items="items"
        @close="onPictureSelectorClose($event); showPictureSelector=false"
    />
  </div>
</template>

<script>

import {pic_point_detail} from "@/json/jsons.js";
import * as Uobj from "@/util/obj.js";
import * as Uapi from "@/util/api.js";
import {makeMail} from "@/util/mail.js";
import {MAIL_CONTACT} from "@/util/constmail.js";
import {add_file, camera, clipboard, double_arrow, filepdf,} from "@/util/icon.js";
import Picture360 from "@/components/picture/Picture360.vue";
import Picture360Controller from "@/components/picture/Picture360Controller.vue";
import Indication from "@/components/sidemenu/Indication.vue";
import Maps from "@/components/sidemenu/Maps.vue";
import ModalMap from "@/components/popup/ModalMap.vue";
import SingleSubmit from "@/components/controller/SingleSubmit";
import DateTimePicker from "@/components/datetimepicker/DateTimePicker";
import ButtonLock from "@/components/buttonLock/ButtonLock";
import ButtonFrame from "@/components/buttonFrame/ButtonFrame";
import EventPanel from "@/components/eventPanel/EventPanel";
import {Extensions, FLOOR_TYPE, PANEL_STATUS, TRANS_MODE, ViewType} from "@/util/constants";
import FileList from "@/components/fileList/FileList";
import * as _ from "lodash";
import PictureSelector from "@/components/popup/PictureSelector";
import PictureProperties from "@/components/popup/PictureProperties";
import ModelList from "@/components/popup/ModelList";
import IndicationList from "@/components/popup/IndicationList";
import TransModePanel from "@/components/eventPanel/TransModePanel";
import TooltipBtn from "@/components/tooltip/TooltipBtn";
import {nextTick} from "vue";
// import PopupModelList from "@/components/popup/PopupModelList";

// 表示ファイルの種別とカメラ制御タイプのマップ
// [左カメラ制御タイプ, 右カメラ制御タイプ, 同期可否]
// カメラ制御タイプ　 平面: 0  パノラマ: 1  3Dモデル: 2
const CAMERA_CONTROL_MAP = [
  // Sync Off
  // 左PLANE: 0,  左PANORAMA: 1,  左CAD2D: 2,    左CAD3D: 3
  [0, 0, true ], [1, 0, false], [0, 0, true ], [2, 0, false], // 右PLANE: 0
  [0, 1, false], [1, 1, true ], [0, 1, false], [2, 1, true ], // 右PANORAMA: 1
  [0, 0, true ], [1, 0, false], [0, 0, true ], [2, 0, false], // 右CAD2D: 2
  [0, 2, false], [1, 2, true ], [0, 2, false], [2, 2, true ], // 右CAD3D: 3

  // Sync On
  // 左PLANE: 0,  左PANORAMA: 1,  左CAD2D: 2,    左CAD3D: 3
  [0, 0, true ], [1, 0, false], [0, 0, true ], [2, 0, false], // 右PLANE: 0
  [0, 1, false], [1, 1, true ], [0, 1, false], [1, 1, true ], // 右PANORAMA: 1
  [0, 0, true ], [1, 0, false], [0, 0, true ], [2, 0, false], // 右CAD2D: 2
  [0, 2, false], [1, 1, true ], [0, 2, false], [2, 2, true ], // 右CAD3D: 3
];

export default {
  name: "JobSite",
  components: {
    TransModePanel,
    ModelList,
    PictureProperties,
    Picture360,
    Picture360Controller,
    Indication,
    Maps,
    ModalMap,
    SingleSubmit,
    DateTimePicker,
    ButtonLock,
    ButtonFrame,
    EventPanel,
    FileList,
    PictureSelector,
    IndicationList,
    TooltipBtn
  },
  data: function() {
    return {
      jsons: { pic_point_detail },
      icon: { add_file, double_arrow, clipboard, camera, filepdf },
      LEFT_SIDE_MENU_STATUS: {
        CLOSE: 0,
        OPEN: 1,
        MAPS: 2,
        POINTED_OUT: 3,
        SELECT_DUAL: 4,
      },

      // items: [], // 撮影画像／3Dモデル等
      leftSideMenuStatus: 0, // 状態:左メニュー
      dualFlag: false, // 360度画像の比較フラグ

      // 地図画面詳細
      showModalMapFlg: false,
      select_map_id: null,
      // API関連
      api_token: null,
      //状態管理
      FLOOR_TYPE,

      // 現場ID
      construction_site_id: 0,
      // 図面id
      zumen_id: -1,
      // 撮影ポイントid
      captcha_point_id: -1,

      captcha_point_loading: true,

      select_floor_id: FLOOR_TYPE.FLOOR1, // 選択中の360度画像
      floor1_camera: {
        position: { x: 0, y: 1.8, z: 0.1 },
        target: { x: 0, y: 1.8, z: 0 },
      },
      floor2_camera: {
        position: { x: 0, y: 1.8, z: 0.1 },
        target: { x: 0, y: 1.8, z: 0 },
      },
      floor1_item_id: null,
      floor2_item_id: null,
      floor1_item_index: null,
      floor2_item_index: null,
      floor1_zoom_value: 100, //ズーム
      floor2_zoom_value: 100, //ズーム
      floor1_view_type: 0,
      floor2_view_type: 0,

      sync_camera: false, //左右のビューの回転の同期
      showedIndicationFlg: false, // GETパラメータによる指摘・連絡事項表示
      //パネルメニューの状態
      floor1_panel_status: null, //PANEL_STATUS1
      floor2_panel_status: null, //PANEL_STATUS2
      //ファイル一覧の状態
      isOpenFileList1: false,
      isOpenFileList2: false,

      floor1_showIndicationList: false,
      floor1_showModelList: false,
      floor1_modelIds: [],
      floor1_transTargetId: null,
      floor1_transMode: TRANS_MODE.TRANSLATE,

      floor2_showIndicationList: false,
      floor2_showModelList: false,
      floor2_modelIds: [],
      floor2_transTargetId: null,
      floor2_transMode: TRANS_MODE.TRANSLATE,
      selectedMenuItem: null,

      linkedCp: null,
    };
  },
  computed: {
    // // 現場
    // constructionSites() {
    //   return this.$store.getters["ConstructionSitesApi/getStateConstructionSites"]
    // },
    // // 図面
    // zumens() {
    //   return this.$store.getters[""]
    // },
    // 図面一覧
    maps() {
      return this.$store.getters["ZumensApi/getStateZumens"] || [];
    },
    // 撮影ポイント
    captchaPoint() {
      return this.$store.getters["CaptchaPointApi/getStateCaptchaPoint"] || {};
    },
    // 撮影ポイントの写真/3Dモデル
    items() {
      const items = this.captchaPoint["around_photographs"] || [];
      const extensions = [...Extensions.MOVIE, ...Extensions.IMAGE, ...Extensions.MOVIE, ...Extensions.IMAGE, ...Extensions.CAD,...Extensions.FBX, ...Extensions.GLTF, ...Extensions.LAS,
        ...Extensions.GLTF,
        ...Extensions.LAS]
      return items
        .filter((item) => {
          const fileType = (item.file_type || '').toLowerCase();
          return !item["delete_flg"] && extensions.includes(fileType) && (item["file_id"] || (this.isLinkBasepage && item["bp_id"]));
        })
        .map((item) => {
          let history = { ...item };
          let updatedAt = item["updated_at"].substring(0, 19);
          history["display_date"] = updatedAt.replace(/T/, " ");
          return history;
        })
        .reverse();
    },
    // //パネルファイルアップロード
    // updatedPanelFiles() {
    //   return this.$store.getters["PanelFileUploads/getState"];
    // },
    // 360度画像
    getState360ImageSingle() {
      return this.$store.getters["ViewImageApi/getState360ImageSingle"];
    },

    // BP連携情報
    bpLinkage() {
      return this.$store.getters["BpLinkage/getState"];
    },
    // BP連携有無
    isLinkBasepage() {
      return this.bpLinkage.isLink;
    },
    floors_item_index: {
      get() {
        return {
          floor1: this.floor1_item_index,
          floor2: this.floor2_item_index,
        };
      },
      set(value) {
        this.floor1_item_index = value.floor1;
        this.floor2_item_index = value.floor2;
      }
    },
    cameraControlType() {
      const s = this.sync_camera ? 1 : 0;
      const index = s * 16
          + (this.floor2_view_type ?? 0) * 4
          + (this.floor1_view_type ?? 0);
      return CAMERA_CONTROL_MAP[index];
    },
    canSync() {
      return this.cameraControlType[2];
    },
    showLock() {
      return this.dualFlag && this.canSync
          && !this.floor1_showIndicationList && !this.floor2_showIndicationList
          && !this.isOpenFileList1 && !this.isOpenFileList2
          && !this.floor1_showModelList && !this.floor2_showModelList
          && !this.floor1_showPictureProperties && !this.floor2_showPictureProperties;
    },
    floor1_models() {
      return this.items.filter(d => this.floor1_modelIds.includes(d.id));
    },
    floor2_models() {
      return this.items.filter(d => this.floor2_modelIds.includes(d.id));
    },
    showPictureSelector: {
      get() {
        return this.hasLeftSideMenuStatus(this.LEFT_SIDE_MENU_STATUS.SELECT_DUAL);
      },
      set(value) {
        this.floor1_panel_status = null;
        this.floor2_panel_status = null;
        if (value) {
          this.setLeftSideMenuStatus(this.LEFT_SIDE_MENU_STATUS.SELECT_DUAL);
        } else {
          this.setLeftSideMenuStatus(this.LEFT_SIDE_MENU_STATUS.CLOSE);
        }
      }
    },
    floor1_showPictureProperties() {
      return !_.isNil(this.floor1_transTargetId);
    },
    floor2_showPictureProperties() {
      return !_.isNil(this.floor2_transTargetId);
    },
    floor1_showEventPanel() {
      return !this.floor1_panel_status
          && !this.floor1_showIndicationList
          && !this.floor2_showIndicationList
          && !this.isOpenFileList1
          && !this.isOpenFileList2
          && !this.floor1_showModelList
          && !this.floor2_showModelList
          && !this.floor1_showPictureProperties
          && !this.floor2_showPictureProperties;
    },
    floor2_showEventPanel() {
      return !this.floor2_panel_status
          && !this.floor1_showIndicationList
          && !this.floor2_showIndicationList
          && !this.isOpenFileList1
          && !this.isOpenFileList2
          && !this.floor1_showModelList
          && !this.floor2_showModelList
          && !this.floor1_showPictureProperties
          && !this.floor2_showPictureProperties;
    },
    floor1_cp_around_photo() {
      return this.items ? this.items[this.floor1_item_index] : null;
    },
    floor2_cp_around_photo() {
      return this.items ? this.items[this.floor2_item_index] : null;
    },
    floor1_cp_around_photo_id() {
      return this.floor1_cp_around_photo?.id;
    },
    floor2_cp_around_photo_id() {
      return this.floor2_cp_around_photo?.id;
    },
    floor1_transProps: {
      get() {
        return this.getItemProperties(this.floor1_transTargetId);
      },
      set(value) {
        this.setItemProperties(this.floor1_transTargetId, value);
      }
    },
    floor2_transProps: {
      get() {
        return this.getItemProperties(this.floor2_transTargetId);
      },
      set(value) {
        this.setItemProperties(this.floor2_transTargetId, value);
      }
    },
    floor1_modelSelection: {
      get() {
        return this.getItemProperties(this.floor1_transTargetId).modelSelection;
      },
      set(value) {
        const properties = this.getItemProperties(this.floor1_transTargetId);
        properties.modelSelection = value;
        this.setItemProperties(this.floor1_transTargetId, properties);
      }
    },
    floor2_modelSelection: {
      get() {
        return this.getItemProperties(this.floor2_transTargetId).modelSelection;
      },
      set(value) {
        const properties = this.getItemProperties(this.floor2_transTargetId);
        properties.modelSelection = value;
        this.setItemProperties(this.floor2_transTargetId, properties);
      }
    },
    hideLeftPanel() {
      return this.floor2_showIndicationList || this.isOpenFileList2 || this.floor2_showModelList || this.floor2_showPictureProperties || this.floor2_showIndicationList || this.isOpenFileList2;
    },
    hideRightPanel() {
      return this.floor1_showIndicationList || this.isOpenFileList1 || this.floor1_showModelList || this.floor1_showPictureProperties || this.floor1_showIndicationList || this.isOpenFileList1;
    }
  },
  /**
   * 生成時
   */
  created() {
    this.dualFlag = this.isLinkBasepage || false;
  },
  /**
   * マウント時
   */
  async mounted() {

    this.showPictureSelector = true;

    //ここで日付の変更を検知
    this.$watch(
      () => [
        this.zumen_id,
        this.captcha_point_id,
        this.captchaPoint,
        this.getState360ImageSingle,
      ],
      async (newValue, oldValue) => {
        const diff = (index) => {
          return (
            JSON.stringify(newValue[index]) !== JSON.stringify(oldValue[index])
          );
        };

        // const informationDiff = () => {
        //   return (
        //     JSON.stringify(newValue[2]["around_photographs"]) !==
        //     JSON.stringify(oldValue[2]["around_photographs"])
        //   );
        // };

        const zumenDiff = diff(0);
        const pointDiff = diff(1);
        // const captchaDiff = diff(2);
        const _360ImageDiff = diff(3);
        const zumen_id = newValue[0];
        // const captchaPointId = newValue[1];
        // const captchaPoint = newValue[2];

        if (zumenDiff) {
          await this.getCaptchaPoints(zumen_id);
          if (this.isLinkBasepage && this.linkedCp) {
            this.captcha_point_id = this.linkedCp.id;
          }
        }

        //撮影ポイント変更されたら反映
        if (pointDiff) {
          this.captcha_point_loading = true;
          await this.getCaptchaPoint();
          this.captcha_point_loading = false;
        }

        if (_360ImageDiff) {
          // 表示：指摘・連絡事項
          let getParam = Uapi.getGetParamMap();
          if (getParam["getCaptchaPoint"] === "true" && !this.showedIndicationFlg) {
            this.showIndication(getParam).then(r => this.showedIndicationFlg = r);
            this.showedIndicationFlg = true;
          }
        }
      },
      {
        deep: true,
      }
    );

    let loginSuccessFlg = await this.getTmpLogin();
    if (loginSuccessFlg) {
      await this.init();
      console.log("init() finished.");
    }
  },
  // watch: {
  //   floor1_item_index() {
  //     this.floor1_transTargetId = null;
  //     this.floor1_transMode = TRANS_MODE.TRANSLATE;
  //     this.floor1_zoom_value = 100;
  //
  //     const properties = this.getItemProperties(this.floor1_cp_around_photo_id);
  //     this.floor1_modelIds = properties.modelSelection || [];
  //   },
  //   floor2_item_index() {
  //     this.floor2_transTargetId = null;
  //     this.floor2_transMode = TRANS_MODE.TRANSLATE;
  //     this.floor2_zoom_value = 100;
  //
  //     const properties = this.getItemProperties(this.floor2_cp_around_photo_id);
  //     this.floor2_modelIds = properties.modelSelection || [];
  //   },
  // },
  methods: {
    // TODO ユーザーと現場を固定でログインする。(ログインと現場選択が実装されたら当メソッドを削除する)
    /**
     * 仮ログイン
     * @returns {Promise<boolean>}
     */
    async getTmpLogin() {
      let login = await this.$store.dispatch("LoginApi/login", {
        login_id: "admin1",
        password: "test1234",
      });
      if (login["status"] !== 200) return false;

      let loginData = login["data"];
      this.api_token = loginData["api_token"];
      let cs = await this.$store.dispatch(
        "ConstructionSitesApi/getConstructionSites",
        { api_token: this.api_token }
      );
      if (cs["status"] !== 200) return false;

      let csData = cs["data"];
      if (this.isLinkBasepage) {
        const site = _.find(csData, {'bp_id' : this.bpLinkage.tmItemId});
        if (!site) {
          return false;
        }
        this.construction_site_id = site["id"];
      } else {
        // TODO 現場選択実装時、getConstructionSitesを使用している各コンポーネントを見直すこと。
        this.construction_site_id = csData[0]["id"];
      }
      return true;
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async init() {

      // 取得:図面一覧
      const zumens = await this.loadMaps();
      if (zumens.length === 0) return;

      // 取得：指摘・連絡事項
      let getParam = Uapi.getGetParamMap();
      if (getParam["getCaptchaPoint"] === "true") {
        if (await this.isShowIndicationMap(zumens, getParam)) {
          // 指定された指摘・連絡事項に紐づく図面等が正常に取得できた場合
          return;
        }
      }

      // BP連携時
      if (this.isLinkBasepage) {
        if (this.bpLinkage.folderId) {
          this.linkedCp = await this.getBpLinkageCp(this.bpLinkage.folderId);
          this.zumen_id = this.linkedCp.zumen_id;
        } else {
          const zumen = _.find(zumens, { 'bp_id' : 0 });
          this.zumen_id = zumen["id"];
          const cp = _.find(await this.getCaptchaPoints(this.zumen_id), { 'bp_id' : 0 });
          this.captcha_point_id = cp["id"];
        }
      } else {
        // 取得：最新の図面と関連する360度写真
        let latest_zumen = zumens[zumens.length - 1];
        this.zumen_id = latest_zumen["id"];
        const latest_cp = this.getLatestCp(latest_zumen["captcha_points"]);

        // 取得：撮影ポイント詳細
        if (_.isNil(latest_cp)) return;
        this.captcha_point_id = latest_cp["id"];
      }

      //await this.getCaptchaPoint(this.zumen_id, this.captcha_point_id);
    },

    // 取得:図面一覧
    async loadMaps() {
      await this.getZumens();
      return this.maps;
      // if (zumens && zumens.length > 0) {
      //   this.maps = _.cloneDeep(zumens);
      //   return this.maps;
      // } else {
      //   return [];
      // }
    },

    // 表示：指摘・連絡事項
    // - (メールに記載の)GETパラメータで指定された地図、撮影ポイントを選択する
    // - return true:表示可能, false:表示不可
    async isShowIndicationMap(zumens, getParam) {
      let construction_site_id = getParam["construction_site_id"];
      let zumen_id = getParam["zumen_id"];
      let captcha_point_id = getParam["captcha_point_id"];
      let zumen = zumens.find(
        (item) =>
          item.construction_site_id === construction_site_id &&
          item.id === zumen_id
      );
// console.log("zumens.length" + zumens.length);
      if (zumen && Uobj.hasList(zumen["captcha_points"])) {
        this.zumen_id = Number(zumen_id);
        this.captcha_point_id = Number(captcha_point_id);
        return true;
      }

      alert("この指摘・連絡事項の撮影ポイントは存在しません");
      return false;
    },

    // 表示：指摘・連絡事項
    // - (メールに記載の)GETパラメータで指定された指摘・連絡事項を表示する
    // - return true:表示可能, false:表示不可
    async showIndication(getParam) {
console.log("showIndication called.");
//       let cp = this.$store.getters["CaptchaPointApi/getStateCaptchaPoint"];
//       if (cp && Uobj.hasList(cp["around_photographs"])) {
      if (this.items) {
        let ap = this.items.reverse();

        for (let index = 0; index < ap.length; index++) {
          if (ap[index]["id"] !== getParam["cp_around_photograph_id"]) continue;
          let informations = ap[index]["infomation"];
          if (!Uobj.hasList(informations)) break;
          let information = informations.find(
            (item) => item.id === getParam["ap_infomation_id"]
          );

          if (!information) break;

          // 表示：指摘・連絡事項
          if (this.floor1_item_index === index) {
            // 現在開いている360度画像に存在する場合
            await this.$root.$children[0].$refs["popupInfo"].call(information, {
              construction_site_id: this.construction_site_id,
              zumen_id: this.zumen_id,
              captcha_point_id: this.captcha_point_id,
            });

          } // 上記以外の場合
          else {
            this.setFloor1Picture(index);
          }

          this.$watch(
            () => [this.getState360ImageSingle],
            async (newValue, oldValue) => {
              const diff = (index) => {
                return (
                  JSON.stringify(newValue[index]) !==
                  JSON.stringify(oldValue[index])
                );
              };
              const _360ImageDiff = diff(0);
              if (_360ImageDiff) {
                await this.$root.$children[0].$refs["popupInfo"].call(
                  information,
                  {
                    construction_site_id: this.construction_site_id,
                    zumen_id: this.zumen_id,
                    captcha_point_id: this.captcha_point_id,
                  }
                );
              }
            }
          );
          return true;
        }
      }

      alert("この指摘・連絡事項は存在しません");
      return false;
    },
    /**
     * API 取得:図面一覧
     * @returns {Promise<*[]>}
     */
    // API 取得:図面一覧
    async getZumens() {
      let zumens = await this.$store.dispatch("ZumensApi/getZumens", {
        api_token: this.api_token,
        construction_site_id: this.construction_site_id,
      });
      if (zumens["status"] !== 200) return null;
      let zumen = [];
      _.forEach(zumens["data"], (val) => {
        if (!_.isNil(val["file_id"]) || this.isLinkBasepage) zumen.push(val);
      });
      return zumen;
    },
    /**
     * API 取得:図面一覧の撮影ポイント一覧
     * @param zumen_id
     * @returns {Promise<null|*>}
     */
    async getCaptchaPoints(zumen_id) {
      if (_.isNil(zumen_id)) return null;
      let res = await this.$store.dispatch("CaptchaPointApi/getCaptchaPoints", {
        api_token: this.api_token,
        zumen_id,
        construction_site_id: this.construction_site_id,
      });
      if (res["status"] !== 200) return null;
      return res["data"];
    },
    /**
     * API 取得:撮影ポイント詳細
     * @returns {Promise<null|*>}
     */
    async getCaptchaPoint() {
      const { zumen_id, captcha_point_id } = this;
      if (_.isNil(zumen_id) || _.isNil(captcha_point_id)) return null;
      let res = await this.$store.dispatch("CaptchaPointApi/getCaptchaPoint", {
        api_token: this.api_token,
        zumen_id,
        captcha_point_id,
      });
      if (res["status"] !== 200) return null;
      return res["data"];
    },
    /**
     * API 取得：撮影ポイントの撮影画像と関連情報の取得
     */
    async getCpAroundPhotograph(cp_around_photograph_id) {
      const result = await this.$store.dispatch(
          "CaptchaPointApi/getCpAroundPhotograph",
          {
            api_token: this.api_token,
            zumen_id: this.zumen_id,
            captcha_point_id: this.captcha_point_id,
            around_photographs_id: cp_around_photograph_id,
          }
      );
      if (result["status"] !== 200) return false;

    },
    /**
     * API 更新:撮影ポイントの撮影画像/3Dモデルのプロパティ更新
     * @param cp_around_photograph_id 画像/3DモデルID
     * @param properties プロパティ
     * @returns {Promise<boolean>}
     */
    async patchCpAroundPhotograph(cp_around_photograph_id, properties) {
      const result = await this.$store.dispatch(
          "CaptchaPointApi/patchCpAroundPhotograph",
          {
            api_token: this.api_token,
            zumen_id: this.zumen_id,
            captcha_point_id: this.captcha_point_id,
            around_photographs_id: cp_around_photograph_id,
            properties: properties,
            del_flag: 0
          }
      );
      if (result["status"] !== 200) return false;
      // this.floor1_panel_status = null;
      // this.floor2_panel_status = null;
      // //成功したら同じ図面の最新で再描画
      // await this.getCaptchaPoints(this.zumen_id);
      // if (this.captcha_point_id > 0) {
      //   await this.getCaptchaPoint(this.zumen_id, this.captcha_point_id);
      // }
    },
    /**
     * API 更新:撮影ポイントの撮影画像の削除
     * @param cp_around_photograph_id 画像/3DモデルID
     * @returns {Promise<null|boolean>}
     */
    async deleteCpAroundPhotograph({
      cp_around_photograph_id
    }) {

      const res = window.confirm(
          "削除しますか？※この操作は取り消せません。"
      );
      if (!res) return false;

      const { zumen_id, captcha_point_id } = this;
      if (
        _.isNil(zumen_id) ||
        _.isNil(captcha_point_id) ||
        _.isNil(cp_around_photograph_id)
      ) {
        return null;
      }
      console.log("ここで撮影画像を論理削除");
      const result = await this.$store.dispatch(
        "CaptchaPointApi/patchCpAroundPhotograph",
        {
          api_token: this.api_token,
          zumen_id,
          captcha_point_id,
          around_photographs_id: cp_around_photograph_id,
          del_flag: 1,
        }
      );
      if (result["status"] !== 200) return false;

      //成功したら同じ図面の最新で再描画
      // await this.getCaptchaPoints(zumen_id);
      // if (this.captcha_point_id > 0) {
      //   await this.getCaptchaPoint(this.zumen_id, this.captcha_point_id);
      // }
      this.captcha_point_loading = true;
      await this.getCaptchaPoint();
      this.captcha_point_loading = false;
    },
    async saveModelSelection(id, selection) {
      if (this.floor1_cp_around_photo_id === this.floor2_cp_around_photo_id) {
        this.floor2_modelIds = selection;
        this.floor1_modelIds = selection;
      }
      const properties = this.getItemProperties(id);
      properties.modelSelection = selection;
      await this.patchCpAroundPhotograph(id, properties);
    },
    /**
     * basepageのフォルダIDに対応する撮影ポイントを取得
     * @param bpFolderId basepageのフォルダID
     * @returns {Promise<null|*>}
     */
    async getBpLinkageCp(bpFolderId) {
      const res = await this.$store.dispatch("CaptchaPointApi/getBpLinkageCp",
          { api_token: this.api_token, bp_id: bpFolderId });
      if (res["status"] === 200) {
        return res["data"];
      }
      return null;
    },
    // パネルの選択状態を変更
    onChangePanelStatus(status, floor) {
console.log("onChangePanelStatus");
      let index;
      if (floor === FLOOR_TYPE.FLOOR1) {
        this.floor1_panel_status = status;
        index = this.floor1_item_index;
        if (!_.isNil(index) && status === PANEL_STATUS.ADJUST_NORTH) {
          this.floor1_transTargetId = this.items[index].id;
        } else {
          this.floor1_transTargetId = null;
        }
      } else if (floor === FLOOR_TYPE.FLOOR2) {
        this.floor2_panel_status = status;
        index = this.floor2_item_index;
        if (!_.isNil(index) && status === PANEL_STATUS.ADJUST_NORTH) {
          this.floor2_transTargetId = this.items[index].id;
        } else {
          this.floor2_transTargetId = null;
        }
      }

      let message = "";
      if (status === PANEL_STATUS.INDICATION) {
        message = "指摘・連絡事項を追加する箇所をクリックしてください";
      } else if (status === PANEL_STATUS.FILEUPLOAD) {
        message = "ファイルを追加する箇所をクリックしてください";
      } else if (status === PANEL_STATUS.ADJUST_NORTH) {
        //message = "十字を北方向へ合わせてください";
      }
      let exist = false;
      _.forEach(PANEL_STATUS, (val) => {
        if (val === status) exist = true;
      });
      if (exist) this.$store.dispatch("Toast/show", { message, time: 3000 });
    },

    /**
     * ファイル選択クローズ時
     * @param selected 選択されたファイル
     */
    onPictureSelectorClose(selected) {
      if (selected) {
        this.floor1_item_id = selected.left;
        this.floor2_item_id = selected.right;
        this.floor1_item_index = _.findIndex(this.items, i => i.id === selected.left);
        this.floor2_item_index = _.findIndex(this.items, i => i.id === selected.right);
        this.floor1_modelIds = this.getItemProperties(this.floor1_item_id)?.modelSelection || [];
        this.floor2_modelIds = this.getItemProperties(this.floor2_item_id)?.modelSelection || [];
        // console.log(`this.floor1_modelIds: ${this.floor1_modelIds}`);
        // console.log(`this.floor2_modelIds: ${this.floor2_modelIds}`);
      }
    },

    //API ファイルのアップロード
    //POST api/postApFile
    //jpg, pdfのみ許可
    //最大5枚 >> まず1枚
    async dispatchPanelEvent({ cp_around_photograph_id }) {
      console.log("------cp_around_photograph_id------", cp_around_photograph_id);
      //パネルの状態を取得
      const panelState = this.$store.getters["PanelFileUploads/getState"];
      console.log("----------------", panelState);
      if (!panelState || !cp_around_photograph_id) return;

      console.log("-----panelState.status-------", panelState.status);
      //ファイルのアップロード
      if ((panelState.status || '') === PANEL_STATUS.FILEUPLOAD) {
        //ファイルは選択されていない(キャンセル)場合
        if (!panelState.files || panelState.files.length === 0) return;

        const params = panelState.files.map((file) => {
          return {
            api_token: this.api_token,
            cp_around_photograph_id,
            file_name: file.name,
            image: file.image,
            image_type: file.image_type,
            size: file.size,
            point_x: `${panelState.position.x}`,
            point_y: `${panelState.position.y}`,
            point_z: `${panelState.position.z}`,
          };
        });

        //TODO: API投稿
        const postFiles = params.map(async (param) => {
          return this.$store.dispatch("ApFile/postApFile", param);
        });
        const res = await Promise.all(postFiles);
        console.log("files upload result = " + JSON.stringify(res));
        const files = this.getItem(cp_around_photograph_id).files;
        res.forEach(r => {
          files.push(r.data);
        });

        //後処理
        this.floor1_panel_status = null;
        this.floor2_panel_status = null;
        await this.$store.dispatch("PanelFileUploads/reset");

        //CaptchaPointを更新
        // await this.getCaptchaPoint();
      }
    },
    // 取得：最新の図面と関連する360度写真
    getLatestCp(captcha_points) {
      if (_.isNil(captcha_points) || captcha_points.length === 0) return;
      let latest_cp = null;
      _.forEach(captcha_points, (val) => {
        // 最新図面の"最新撮影ポイント※1"の中で、最も新しい撮影ポイント※2を抽出する
        // ※1 "latest_around_photograph"には、各撮影ポイントの最新が格納されている。
        // ※2 "file_id"がnullでない(=撮影済み)の各撮影ポイントを"updated_at"で比較し抽出する
        if (_.isNil(Uobj.getNest(val, "latest_around_photograph", "file_id")))
          return;
        if (_.isNil(latest_cp)) {
          latest_cp = _.cloneDeep(val);
        } else {
          let latestCpUpdateAt = Uobj.getNest(
            latest_cp,
            "latest_around_photograph",
            "file_id"
          );
          let valUpdateAt = Uobj.getNest(
            val,
            "latest_around_photograph",
            "file_id"
          );
          if (!latestCpUpdateAt || latestCpUpdateAt < valUpdateAt) {
            latest_cp = _.cloneDeep(val);
          }
        }
      });
      return latest_cp;
    },
    setLeftSideMenuStatus(status) {
// console.log("setLeftSideMenuStatus:" + status);
      this.leftSideMenuStatus = status;
    },
    hasLeftSideMenuStatus(status) {
      return this.leftSideMenuStatus === status;
    },
    // 左右カメラ同期開始
    syncCameraStart() {
      if (!this.sync_camera) {
        this.sync_camera = true;
        this.floor2_camera.position = _.clone(this.floor1_camera.position);
        this.floor2_camera.target = _.clone(this.floor1_camera.target);
        this.floor2_zoom_value = this.floor1_zoom_value;
      }
    },
    // 左右カメラ同期終了
    syncCameraStop() {
      if (this.sync_camera) {
        this.sync_camera = false;
      }
    },
    // 座標同期
    syncCameraPosition({ position, target, floor }) {
      this.select_floor_id = floor;
      if (this.sync_camera || this.FLOOR_TYPE.FLOOR1 === this.select_floor_id) {
        this.floor1_camera.position = { x: position.x, y: position.y, z: position.z };
        this.floor1_camera.target = { x: target.x, y: target.y, z: target.z };
      }
      if (this.sync_camera || this.FLOOR_TYPE.FLOOR2 === this.select_floor_id) {
        this.floor2_camera.position = { x: position.x, y: position.y, z: position.z };
        this.floor2_camera.target = { x: target.x, y: target.y, z: target.z };
      }
    },
    // // ウィンドウ分割した際のwidth取得
    // editClientWidth(width) {
    //   return this.dualFlag ? width / 2 : width;
    // },
    // setFloor1Picture(index) {
    //   this.floor1_item_index = index;
    // },
    // getFloor2Picture(index) {
    //   this.floor2_item_index = index;
    // },
    // 選択:撮影ポイントに関連付く360度写真
    async selVRImg(param) {
      //let file_id = null;
      let selPoint = null;
      _.forEach(this.maps, (val) => {
        if (val.id === param.map_id) {
          let points = val["captcha_points"];
          for (let index = 0; index < points.length; index++) {
            let point = points[index];
            if (point.id === param.id) {
              selPoint = _.cloneDeep(point);
              // file_id = Uobj.getNest(
              //   selPoint,
              //   "latest_around_photograph",
              //   "file_id"
              // );
              return;
            }
          }
        }
      });
      if (selPoint != null) {
        this.zumen_id = selPoint["zumen_id"];
        this.captcha_point_id = selPoint["id"];
      }
    },
    // 図面操作
    showModalMap(param) {
      let map = this.maps.find((item) => item.id === param.item.id);
      if (map) {
        this.select_map_id = param.item.id;
        this.showModalMapFlg = true;
      }
    },
    closeModalMap() {
      this.showModalMapFlg = false;
      this.select_map_id = null;
    },
    setFloor1Zoom(value) {
      this.floor1_zoom_value = value;
      if (this.sync_camera) {
        this.floor2_zoom_value = value;
      }
    },
    setFloor2Zoom(value) {
      this.floor2_zoom_value = value;
      if (this.sync_camera) {
        this.floor1_zoom_value = value;
      }
    },
    setFloorViewType(value) {
      console.log("setFloorViewType:" + JSON.stringify(value));
      if (value.id === FLOOR_TYPE.FLOOR1) {
        this.floor1_view_type = value.viewType;
      } else if (value.id === FLOOR_TYPE.FLOOR2) {
        this.floor2_view_type = value.viewType;
      }
      this.sync_camera = this.sync_camera && this.canSync;
    },
    // メール:お問い合わせ
    mailContact() {
      return new Promise((resolve) => {
        setTimeout(() => {
          makeMail(MAIL_CONTACT);
          resolve(); // これを書かないと２回目以降が実装できない
        }, 1000);
      });
    },
    isViewPlane(viewType) {
      return [ViewType.PLANE, ViewType.CAD2D].includes(viewType);
    },
    onFloor1Trans(val) {
      this.floor1_transProps = val;
console.log("onFloor1Trans:" + JSON.stringify(val));
    },
    onFloor2Trans(val) {
      this.floor2_transProps = val;
console.log("onFloor2Trans:" + JSON.stringify(val));
    },
    getItem(id) {
      return this.items.find(i => i.id === id);
    },
    getItemProperties(id) {
      if (this.items) {
        for (let item of this.items) {
          if (item && item.id === id && item['properties']) {
            return JSON.parse(item['properties']);
          }
        }
      }
      return {};
    },
    setItemProperties(id, value) {
      if (this.items) {
        for (let item of this.items) {
          if (item && item.id === id) {
            item['properties'] = JSON.stringify(value);
            break;
          }
        }
      }
    },
    syncVideoIfNeeds(ev, floor) {
      if (this.sync_camera) {
        if (floor === FLOOR_TYPE.FLOOR1) {
          if (ev["playing"]) {
            this.$refs["picture360-FLOOR2"].videoPlay();
          } else {
            this.$refs["picture360-FLOOR2"].videoPause();
          }
        } else if (floor === FLOOR_TYPE.FLOOR2) {
          if (ev["playing"]) {
            this.$refs["picture360-FLOOR1"].videoPlay();
          } else {
            this.$refs["picture360-FLOOR1"].videoPause();
          }
        }
      }
    },
  },

};
</script>

<style scoped lang="scss">
@import "../style/config.scss";

.jobsite {
  height: 100%;
  width: 100%;

  .panel {
    background-color: white;
  }

  .jobsite-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .jobsite_header {
    height: 64px;
    background: $color-base-50;
    .header-image-wrapper {
      text-align: left;
    }
  }

  .jobsite_body {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    overflow: hidden;
  }

  .base_left {
    position: relative;
    width: 96px;
  }

  .jobsite_left-side-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color:  white;
    z-index: 1;

    .left-side-menu_close {
      width: 96px;
      height: 100%;
    }

    // TODO メニューの開く速度
    .left-side-menu_open {
      width: 264px;
      height: 100%;
    }
  }
  .menu-default {
    font-size: 17px;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 18px;
    height: 100%;

    .menu {
      display: flex;
      height: 48px;
      margin: 0 16px 16px 0;
      padding: 0 8px;
      cursor: pointer;

      .btn-navi__icon {
        width: 32px;
        height: 32px;
        margin: 0 24px;
      }

      .btn-navi__label {
        line-height: 37px;
        font-size: 18px;
        font-weight: 600;
        width: 150px;
        text-align: left;
        vertical-align: middle;
        color: $color-base-00;
      }

      .btn-navi__label-disable {
        color: $color-base-25;
      }
    }
  }

  .menu-top {
    flex-grow: 1;
    flex-shrink: 1;
  }

  //.menu-default-bottom {
  //  padding-bottom: 16px;
  //}

  .menu-detail {
    //position: absolute;
    //width: 382px;
    height: 100%;
    background-color: white;

    .menu-detail-header {
      height: 40px;

      .menu-detail-header_img-wrapper {
        margin: $margin-15 $margin-20 0 0;
        text-align: right;

        .menu-detail-header_img {
          margin-top: 2px;
          padding: 4px 2px 4px 4px;
          width: auto;
          height: 16px;
          transform: rotateY(-180deg);
          cursor: pointer;
        }
      }
    }

    .menu-detail_maps,
    .menu-detail_indication {
      //position: absolute;
      //top: 40px;
      //bottom: 0;
      height: calc(100% - 40px);
      background: $color-base-30;
    }
  }

  .base_main {
    flex-grow: 1;
    flex-shrink: 1;

    .jobsite_picture {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .jobsite_picture360 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }

    .jobsite_footer-menu {
      position: absolute;
      bottom: 0;
      height: 64px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      text-align: center;
      color: $color-base-50;

      .jobsite_diff {
        display: flex;
        flex-direction: row;
        padding: 8px 0 8px;

        .jobsite_diff-checkbox {
          margin: 8px;
        }

        .jobsite_diff-wrapper {
          display: inline-block;
          flex: 1;
          vertical-align: top;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}


.picture360-controller {
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
}

.view-panel {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}
.general-panel {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.jobsite_picture360-dummy {
  width: 100%;
  height: 100%;
}
.jobsite_picture2D {
  min-width: 300px;
  width: 300px;
  height: auto;
}
.jobsite_picture360__lock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  .button {
    pointer-events: auto;
  }

  .white-button {
    background-image: linear-gradient(#ffffff, #eeeeee);
  }
}
.footer-button-panel {
  display: inline-flex;
  vertical-align: top;
  height: 32px;
}
.footer-btn {
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  margin: 0 4px;
  padding: 0;
  outline: none;

  img {
    width: inherit;
    height: inherit;
    opacity: 0.7;
  }

  &:hover {
    cursor: pointer;
    img {
      opacity: 1;
    }
  }
}
.buttonFrame-wrapper {
  display: inline-block;
  margin: 0 8px;
}
//.frame_wrapper {
//  position: relative;
//  height: 100%;
//}
.separator {
  width:1px;
  height:100%;
}

.picture360 {
  width: 100%;
  height: 100%;
  position: relative;
}

.v-btn.show_photo_selector {
  position: absolute;
  top: 12px;
  left: 8px;
  height: 40px!important;
  width: 40px!important;
  min-width: 40px!important;
  padding: 8px!important;
  border-radius: 4px;
  background:linear-gradient(white, lightgray);
  border: none;
  color: black;

}

//
//.indicator-dialog {
//  background-color: white!important;
//  opacity: 0.5!important;
//  height: calc(100% - 100px);
//  width: calc(100% - 100px);
//  .indication-contents {
//    position: relative;
//    width: 100%;
//    height: 100%;
//  }
//}
</style>
