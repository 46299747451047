<template>
  <div class="component" v-if="showFlg" @click.self="close">
      <div class="dialog">
        <div class="content">
          <file-drop-target @drop="onFileDrop">
            <v-data-table
                dense
                :headers="headers"
                :items="prefiltered"
                :items-per-page="10"
                class="elevation-0"
            >
              <template #[`item.selected`]="{ item }">
                <v-switch v-model="selectedIds" :value="item.id" dense />
              </template>

              <template #[`item.detail`]="{ item }">
                <v-btn :disabled="!isSelected(item)" @click="onClickDetail(item)" x-small outlined fab dense>
                  <v-icon color="black" size="20">fa-regular fa-location-dot</v-icon>
                </v-btn>
              </template>

            </v-data-table>
          </file-drop-target>
        </div>
      </div>
  </div>
</template>

<script>

import FileDropTarget from "@/components/fileDropTarget/FileDropTarget";
import * as _ from "lodash";
import {Extensions} from "@/util/constants";

export default {
  name: "ModelList",
  model: {
    prop: 'initial',
    event: 'change'
  },
  components: {
    FileDropTarget
  },
  props: {
    showFlg: {
      type: Boolean,
      required: true,
    },
    baseItem: {
      type: Object
    },
    items: {
      type: Array,
      required: true,
    },
    initial: Array,
  },
  data: function() {
    return {
      search: null,
      headers: [
        { text: '表示', align: 'center', sortable: true, value: 'selected', width: '64px'},
        { text: 'ファイル', align: 'start', sortable: true, value: 'file_name'},
        { text: '', align: 'start', sortable: false, value: 'detail', width: '64px'},
      ],
      selectedIds: this.initial,
    };
  },
  computed: {
    prefiltered() {
      return _.filter(this.items, f => {
        const fileType = (f.file_type || '').toLowerCase();
        return f.id !== this.baseItem.id && [ ...Extensions.CAD, ...Extensions.FBX, ...Extensions.LAS, ...Extensions.GLTF ].includes(fileType);
      });
    },
  },
  watch: {
    initial: function(newVal) {
      this.selectedIds = newVal;
    },
    selectedIds: function(newVal) {
      this.$emit("change", newVal);
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onClickDetail(item) {
      this.$emit("edit", item);
    },
    isSelected(item) {
      return this.selectedIds.includes(item.id);
    },
    onFileDrop(ev) {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = {
        api_token,
        name: ev.name.split(".")[0],
        captcha_point_id: ev.id,
        image: ev.data.split(",")[1],
        image_type: ev.name.split(".")[1],
      };
      this.$store.dispatch("CpAroundObject/add", params);
    },
  },
}
</script>

<style scoped lang="scss">
  @import "@/style/config.scss";

  div.component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);

    div.dialog {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 32px 16px 16px 16px;
      //padding: 16px;
      background-color: white;
      //box-shadow: 1px 1px 1px 1px gray;
    }
  }
</style>
