import * as THREE from 'three-r110';
import { LegacyJSONLoader } from 'three-r110/examples/jsm/loaders/deprecated/LegacyJSONLoader';
export function legacyJSON2NewJSON(json) {

  const legacyLoader = new LegacyJSONLoader();
  const jsonObj = JSON.parse(json);
  let geometries = [];
  for (let jsonGeo of jsonObj.geometries) {
    if (!jsonGeo.data || !jsonGeo.data.vertices || jsonGeo.data.vertices.length === 0) {
      continue;
    }
    const geometry = legacyLoader.parse(jsonGeo, undefined).geometry;
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();

    const bufferGeo = new THREE.BufferGeometry();
    if (geometry.faces && 0 < geometry.faces.length) {
      bufferGeo.fromGeometry(geometry);
    } else {
      bufferGeo.setFromPoints(geometry.vertices);
    }
    const newGeo = bufferGeo.toJSON();
    newGeo.uuid = jsonGeo.uuid;
    newGeo.layerType = jsonGeo.layerType;
    geometries.push(newGeo);

    Object.keys(jsonGeo).forEach(k => delete jsonGeo[k])
    geometry.dispose();
    bufferGeo.dispose();
  }
  jsonObj.geometries = geometries;
  return JSON.stringify(jsonObj);
}

