/**
 * basepage連携状態
 */
export const BpLinkage = {
  namespaced: true,
  state: {
    tmItemId: 0,
    folderId: 0,
    handleName: "",
    isLink: false,
  },
  mutations: {
    SET_LINK(state, { tmItemId, folderId, handleName, isLink }) {
      state.tmItemId = tmItemId;
      state.folderId = folderId;
      state.handleName = handleName;
      state.isLink = isLink;
    },
  },
  actions: {
    setLink(context, { tmItemId, folderId, handleName }) {
console.log(`tmItemId:${tmItemId}`);
console.log(`folderId:${folderId}`);
console.log(`handleName:${handleName}`);
      context.commit("SET_LINK", {
        tmItemId: Number(tmItemId),
        folderId: Number(folderId),
        handleName: handleName,
        isLink: tmItemId || folderId
      });
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
  },
};
