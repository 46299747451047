import * as THREE from 'three';
import { parse as glParse } from '@loaders.gl/core';
import { setLoaderOptions } from '@loaders.gl/core';
import { LASLoader as GLLASLoader} from '@loaders.gl/las';

setLoaderOptions({
  worker: false,
  CDN: '/js/node-dist/node-modules/@loaders.gl'
});

export class LASLoader extends THREE.Loader {

  noScaling = true;

  load(url, onLoad, onProgress, onError) {
    glParse(fetch(url), GLLASLoader, {las: { colorDepth: 'auto' }}).then(
      data => {
        const mesh = this.toMesh(data);
        // mesh.matrixAutoUpdate = true;
        onLoad(mesh);
      }
    ).catch(err => onError(err));
  }

  toMesh(data) {
    const geometry = new THREE.BufferGeometry();
    if (data && data.attributes) {
      for (const key in data.attributes) {
        let { value, size } = data.attributes[key];
        let name;
        if (key === 'COLOR_0') {
          name = 'color';
          value = new Float32Array(value);
          for (let i = 0; i < value.length; i++) {
            value[i] /= 255.0;
          }
        } else {
          name = key.toLowerCase();
        }

        const bufferAttribute = new THREE.BufferAttribute( value, size );
        geometry.setAttribute( name, bufferAttribute);
      }
    }
    const material = new THREE.PointsMaterial({ vertexColors: true, size: 1 });
    const mesh = new THREE.Points(geometry, material);
    const { x, y, z } = data.loaderData.scale;
    if (x) mesh.scale.x = 1 / x;
    if (y) mesh.scale.y = 1 / y;
    if (z) mesh.scale.z = 1 / z;
    // mesh.matrixAutoUpdate = false;
    // // XY反転
    // mesh.matrix.multiply(new THREE.Matrix4().set(
    //   0,1,0,0,
    //   1,0,0,0,
    //   0,0,1,0,
    //   0,0,0,1
    // ));
    return mesh;
  }
}

