import * as _ from "lodash";
import api from "@/api/api.js";

export const ApDetailApi = {
  namespaced: true,

  state: {
    postApInfo: [],
    postUpdateApInfo: [],
    deleteApInfo: [],
    apPhoto: [],
  },
  mutations: {
    POST_AP_INFO(state, payload) {
      state.postApInfo = _.cloneDeep(payload);
    },
    POST_UPDATE_AP_INFO(state, payload) {
      state.postUpdateApInfo = _.cloneDeep(payload);
    },
    DELETE_AP_INFO(state, payload) {
      state.deleteApInfo = _.cloneDeep(payload);
    },
    POST_AP_PHOTO(state, payload) {
      state.apPhoto = _.cloneDeep(payload);
    },
  },
  actions: {
    async postApInfo(context, payload) {
      const service = "postApInfo";
      console.log("- API:", service, " prm:", payload);

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("POST_AP_INFO", response.data.data);
      } else {
        res["data"] = response.data;
      }
      console.log("- API:", service, "res:", res);
      return res;
    },
    async postUpdateApInfo(context, payload) {
      // PATCH api/patchApInfoは使用せず、POST api/postUpdateApInfoを使用する。
      const service = "postUpdateApInfo";
      console.log("- API:", service, " prm:", payload);

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("POST_UPDATE_AP_INFO", response.data.data);
      } else {
        res["data"] = response.data;
      }
      console.log("- API:", service, "res:", res);
      return res;
    },
    async deleteApInfo(context, payload) {
      const service = "deleteApInfo";
      console.log("- API:", service, " prm:", payload);

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.delete(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("DELETE_AP_INFO", response.data.data);
      } else {
        res["data"] = response.data;
      }
      console.log("- API:", service, "res:", res);
      return res;
    },
    async postApPhoto(context, payload) {
      const service = "postApPhoto";
      console.log("- API:", service, " prm:", payload);

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("POST_AP_PHOTO", response.data.data);
      } else {
        res["data"] = response.data;
      }
      console.log("- API:", service, "res:", res);
      return res;
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getStatePostApInfo: (state) => {
      return state.postApInfo;
    },
    getStatePostUpdateApInfo: (state) => {
      return state.postUpdateApInfo;
    },
    getStateDeleteApInfo: (state) => {
      return state.deleteApInfo;
    },
    getStateApPhoto: (state) => {
      return state.apPhoto;
    },
  },
};
