/**
 *トースト表示
 */
export const Toast = {
  namespaced: true,
  state: {
    isShow: false,
    message: null,
  },
  mutations: {
    SHOW(state, { isShow, message }) {
      state.isShow = isShow;
      state.message = message;
    },
    RESET(state, { isShow }) {
      state.isShow = isShow;
      state.message = null;
    },
  },
  actions: {
    show(context, payload) {
      let param = { ...payload };
      param.isShow = true;
      context.commit("SHOW", param);
      setTimeout(() => {
        context.commit("RESET", {});
      }, param.time);
    },
    close(context) {
      context.commit("RESET", { isShow: false });
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getIsShow: (state) => {
      return state.isShow;
    },
    getMessage: (state) => {
      return state.message;
    },
  },
};
