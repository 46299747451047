import * as _ from "lodash";
import {getUnixTime} from "date-fns";

//キャッシュする時間
const cache_sec = 1;

// //キャッシュフォーマット
// const cache_format = {
//   url: null,
//   params: {},
//   response: {},
//   update_time: null,
// };

//objectをパラメータに変換
const getURLParams = (data) => {
  if (!data) return null;
  return Object.keys(data)
    .map((val) => {
      return encodeURIComponent(val) + "=" + encodeURIComponent(data[val]);
    })
    .join("&");
};

//キャッシュ時間内か比較
const inTime = (sec, updateTime) => {
  const now = getUnixTime(new Date());
  const diff = +now - +updateTime;
  return diff <= sec;
};

//ロード管理
//ロード中の場合は完了をまって返す
const _cacheLoading = () => {
  let isLoadings = {};
  let isLoadeds = {};
  let responses = {};
  let callbacks = {};

  const setCallback = ({ id, callback }) => {
    callbacks[id] = callback;
  };

  const update = ({ id, res }) => {
    responses[id] = res;
    Object.keys(callbacks).forEach((key) => {
      callbacks[key](responses[id]);
    });
    isLoadeds[id] = true;
  };

  const getResponse = ({ id }) => {
    return new Promise((resolved) => {
      //あれば返す
      if (responses[id]) resolved(responses[id]);
      //なければupdateされた返す
      setCallback({
        id,
        callback: (res) => {
          resolved(res);
        },
      });
    });
  };

  const isLoading = ({ id }) => {
    return id in isLoadings ? isLoadings[id] : false;
  };
  const isLoaded = ({ id }) => {
    return id in isLoadeds ? isLoadeds[id] : false;
  };

  const setLoading = ({ id }) => {
    isLoadings[id] = true;
  };
  const setLoaded = ({ id }) => {
    isLoadeds[id] = true;
  };

  return {
    isLoading,
    isLoaded,
    setLoading,
    setLoaded,
    getResponse,
    update,
  };
};

const _apiCache = () => {
  let cache = {};

  //キャッシュから取得
  const get = (url, params, cacheSec = cache_sec) => {
    const url_param = getURLParams(params);
    const key = url.concat("?" + url_param);
    return new Promise((resolved) => {
      if (_.has(cache, key) && inTime(cacheSec, cache[key].update_time)) {
        resolved(cache[key]);
        return;
      }
      resolved(false);
    });
  };

  //キャッシュに登録
  const set = (url, response, params) => {
    const url_param = getURLParams(params);
    const key = url_param ? url.concat("?" + url_param) : url;
    const now = getUnixTime(new Date());
    cache[key] = {
      url,
      params,
      response,
      update_time: now,
    };
  };

  return {
    get,
    set,
  };
};

const asyncApi = _cacheLoading();
const apiCache = _apiCache();
export { getURLParams, asyncApi, apiCache };
