<template>
  <button
    :class="'square--' + type"
    :disabled="isDisabled"
    @click="$emit('onClick', {})"
  >
    {{ label }}
  </button>
</template>

<script>
export const SQUARE_TYPE = {
  NEGATIVE: "negative",
  POSITIVE_BLUE: "positive--blue",
  POSITIVE_RED: "positive--red",
};

export default {
  props: {
    label: {
      type: String,
      default: "ButtonSquare",
      required: true,
    },
    type: {
      type: String,
      default: "positive",
    },
    isDisabled: {
      type: Boolean,
    },
    allValid: {
      type: Boolean,
      default: true,
    },
    onClick: {},
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";
@import "./button.scss";
@import "./button-square.scss";

.square--negative {
  @include global-button;
  @include square-button($color-base-25);
}

.square--positive--blue {
  @include global-button;
  @include square-button($col-btn-positive);
}

.square--positive--red {
  @include global-button;
  @include square-button($col-btn-advice);
}

.disabled {
  opacity: 0.5;
}
</style>