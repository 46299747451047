<template>
  <div class="ProgressBar" :class="isProgress?'progressing':''">
    <div class="frame">
      <div class="gage" :style="styleGage"></div>
    </div>
  </div>
</template>
<script>
import {get360ImageLoader} from "@/util/img";
export default {
  data:()=>{
    return {
      progress:0,
    }
  },
  mounted(){
    get360ImageLoader.setCallback((progress) => {
      this.progress = progress;
      // console.log(this.progress/100);
    })
  },
  computed:{
    isProgress(){
      return this.progress/100 < 1;
    },
    styleGage(){
      return `transform:scaleX(${this.progress/100})`;
    }
  }
}
</script>
<style lang="scss" scoped>
.ProgressBar {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height:4px;
  z-index: 999;
  display: none;

  &.progressing{
    display: block;
  }

  .frame {
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    height:100%;
    background-color: rgb(108, 147, 172);
  }
  .gage {
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    height:100%;
    transition: all .3s ease-in;
    transform-origin: left top;
    transform:scaleX(0);
    background-color: rgb(233, 140, 48);
  }
}
</style>
