<template>
  <div class="checkbox" :style="styles">
    <div class="checkbox-button">
      <div class="check-item">
        <label :for="labelId" class="check-item__label">
          <input
            type="checkbox"
            :value="val"
            name="image"
            :id="labelId"
            v-model="checked"
            @change="$emit('onChange', { name, checked, value: val })"
          />
          <span class="check-item__text">{{ label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      labelId: _.uniqueId("checkbox"),
      value: "0",
      checked: false,
    };
  },
  props: {
    val: {},
    label: {
      type: [String, Number],
    },
    name: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
    },
    styles: {
      type: [String],
      default: "width: 120px;",
    },
    defaultValueFlg: {
      type: Boolean,
    },
  },
  created() {
    this.$watch(
      () => [this.defaultValueFlg],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.checked = newValue[0];
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";
$radio-size: 16px;
$radio-size--checked: 8px;
$checkbox-size: 16px;
.checkbox {
  .checkbox-button {
    .check-item {
      padding-right: 12px;
    }
    .check-item:hover {
      cursor: pointer;
    }
    .check-item input[type="radio"] {
      display: none;
    }
    .check-item input[type="checkbox"] {
      display: none;
    }
    .check-item .check-item__text {
      display: inline-block;
      background: none;
      border-radius: 16px;
      border: 1px solid #848484;
      font-size: 12px;
      color: #121212;
      padding: 6px 8px;
    }
    .check-item .check-item__text:hover {
      cursor: pointer;
    }
    .check-item input[type="radio"]:checked + .check-item__text {
      background-color: #e5f8ff;
      border: 1px solid #e5f8ff;
    }
    .check-item input[type="checkbox"]:checked + .check-item__text {
      background-color: #e5f8ff;
      border: 1px solid #e5f8ff;
    }
  }
}
</style>
