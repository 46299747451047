<template>
  <v-card v-if="item" class="card-indication rounded" @click="showIndication">
    <div class="card-wrapper">
      <ul class="card">
        <li class="card-item">
          <div>記入者名：</div>
          <div>緊急度：{{ getNameUrgency(item['urgency']) }}</div>
        </li>
        <li class="card-item">
          <div>登録日：{{ getDateFormat(item['created_at']) }}</div>
          <div>ステータス：{{ getNameCorrect(item['correct']) }}</div>
        </li>

        <li class="card-img">
          <div>図面名：{{ getTextOmission(mapName, 17) }}</div>
          <div class="card-img-area">
            <template v-if="showImgFlg">
              <v-img :contain=true :max-width=298 :min-width=298 :max-height=238 :min-height=238 :src="image"/>
<!--              <img-->
<!--                :class="-->
<!--                  imgWidthGtHeightFlg-->
<!--                    ? 'card-img-item&#45;&#45;leading-width'-->
<!--                    : 'card-img-item&#45;&#45;leading-height'-->
<!--                "-->
<!--                :src="image"-->
<!--              />-->
            </template>
            <div v-else class="card-img-item--noimage">No Image</div>
          </div>
        </li>
        <li class="card-note">
          <div>内容</div>
          <div>{{ item.comment }}</div>
        </li>
      </ul>
    </div>
  </v-card>
</template>

<script>
import * as _ from "lodash";
import { format } from "date-fns";
import * as Uimg from "@/util/img.js";
import * as Uobj from "@/util/obj.js";
import * as Ustr from "@/util/str.js";
import {
  INDICATION_URGENCY,
  INDICATION_SHORT_CORRECT,
} from "@/util/constants.js";

export default {
  components: {},
  data: () => {
    return {
      CONST: {
        IMAGE: {
          WIDTH: 300,
          HEIGHT: 240,
        },
        INDICATION_URGENCY,
        INDICATION_SHORT_CORRECT,
      },
      image: "",
      showImgFlg: false,
      imgWidthGtHeightFlg: true,
    };
  },
  props: {
    item: {
      type: Object,
    },
    mapName: {
      type: String,
      default: "",
    },
    construction_site_id: {
      type: Number,
      default: 0,
      required: true,
    },
    zumen_id: {
      type: Number,
      default: 0,
      required: true,
    },
    captcha_point_id: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  async created() {
    this.image = await this.getImage(this.item.file_ids);
  },
  methods: {
    // 取得：日付
    getDateFormat(date) {
      return format(new Date(date), "yyyy-MM-dd");
    },
    // 編集：文字列省略
    getTextOmission(text, upperLimit) {
      return Ustr.getTextOmission(text, upperLimit);
    },
    // 取得：緊急度（論理名）
    getNameUrgency(urgency) {
      let item = this.CONST.INDICATION_URGENCY.find(
        (item) => item.value == urgency
      );
      return item ? item.name : "";
    },
    // 取得：ステータス（論理名）
    getNameCorrect(indication) {
      indication;
      let item = this.CONST.INDICATION_SHORT_CORRECT.find(
        (item) => item.value == indication
      );
      return item ? item.name : "";
    },
    // 取得：画像
    async getImage(file_ids) {
      if (!Uobj.hasList(file_ids)) return "";
      let res = await this.getApFileImage(file_ids[0], );
      if (res) {
        let image = res["image"];
        this.imgWidthGtHeightFlg = await Uimg.isWidthGtHeight(
          image,
          this.CONST.IMAGE.WIDTH,
          this.CONST.IMAGE.HEIGHT
        );
        this.showImgFlg = true;
        return image;
      } else {
        return "";
      }
    },
    // API取得：画像
    async getApFileImage(file_id, cp_around_photograph_id) {
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const params = { api_token, file_id, cp_around_photograph_id };
      let res = await this.$store.dispatch("ViewImageApi/getApFileImage", params);
      if (res["status"] != 200) return "";
      return res["data"];
    },
    // 表示：指摘・連絡事項
    async showIndication() {
      await this.$root.$children[0].$refs["popupInfo"].call(this.item, {
        construction_site_id: this.construction_site_id,
        zumen_id: this.zumen_id,
        captcha_point_id: this.captcha_point_id,
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";

.card-indication {
  margin: $padding-20;
  color: #000;
  width: 334px;
  cursor: pointer;
  .card-wrapper {
    .card {
      width: 334px;
      padding-inline-start: 0px;
      padding: 16px;
      margin: 0;
      //border-radius: 12px;
      //border: 1px solid $color-base-25;
      background: #fff;
      font-size: $font-size-30;
      list-style: none;

      .card-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .card-img {
        text-align: left;
        padding: $padding-10 0;
        .card-img-area {
          position: relative;
          width: 300px;
          height: 240px;
          border: 1px solid $color-base-25;
          background: $color-base-30;

          .card-img-item--leading-width {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: auto;
          }
          .card-img-item--leading-height {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            height: 100%;
            width: auto;
          }
          .card-img-item--noimage {
            color: $color-base-20;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-50%);
          }
        }
      }
      .card-note {
        height: 80px;
        padding: 2px;
        overflow-y: auto;
        text-align: left;
        border: 1px solid $color-base-25;
        border-radius: 4px;
      }
    }
  }
}
</style>
