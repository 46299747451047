import Vue from 'vue'
import Vuex from 'vuex'
import { ApDetailApi } from "./modules/api/ApDetailApi.js";
import { ApFile } from "./modules/api/ApFile.js";
import { LoginApi } from "./modules/api/LoginApi.js";
import { ConstructionSitesApi } from "./modules/api/ConstructionSitesApi.js";
import { ZumensApi } from "./modules/api/ZumensApi.js";
import { CaptchaStartEndPointApi } from "./modules/api/CaptchaStartEndPointApi.js";
import { CaptchaPointApi } from "./modules/api/CaptchaPointApi.js";
import { ViewImageApi } from "./modules/api/ViewImageApi.js";
import { PanelFileUploads } from "./modules/PanelFileUploads";
import { Toast } from "./modules/Toast";
import { FileViewer } from "./modules/FileViewer";
import { Tooltip } from "./modules/Tooltip";
import { Indicater } from "./modules/Indicater";
import { CpAroundObject } from "./modules/api/CpAroundObject";
import {BpLinkage} from "@/store/modules/BpLinkage";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    ApDetailApi,
    ApFile,
    LoginApi,
    ConstructionSitesApi,
    ZumensApi,
    CaptchaStartEndPointApi,
    CaptchaPointApi,
    ViewImageApi,
    PanelFileUploads,
    Toast,
    FileViewer,
    Tooltip,
    Indicater,
    CpAroundObject,
    BpLinkage,
  }
});

export default store;
