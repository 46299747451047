<template>
  <div class="fileitem">
    <img :src="src" :alt="file_id">
    <div class="name">{{name}}</div>
  </div>
</template>
<script>

import { POST_IMAGE_TYPE } from "@/util/constants";

export default {
  data:()=>{
    return {
      src:null,
    }
  },
  props:{
    file_id:{
      type:Number
    },
    name:{
      type:String,
      default:""
    },
    cp_around_photograph_id: {
      type:Number
    }
  },
  async mounted() {
    await this.getImage(this.file_id);
  },
  methods:{
    async getImage(file_id){
      const api_token = this.$store.getters[`LoginApi/getApiToken`];
      const { cp_around_photograph_id } = this;
      const result = await this.$store.dispatch("ApFile/getApFileImage",{api_token,file_id,cp_around_photograph_id});
      const file = {...result.data};
      if ( result.status == 200) {
        this.src = file.image;
        if (file.image_type === POST_IMAGE_TYPE.PDF.value) {
          this.src = '@/assets/img/icon-pdf.svg';
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fileitem {
  width:100%;
  height:160px;
  overflow:hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width:100%;
  }
  .name{
    font-size:12px;
    text-align: center;
    width: 100%;
    padding:2px 4px;
    text-overflow: ellipsis;
  }
}
</style>
