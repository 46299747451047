import * as _ from "lodash";
import sanitizeHTML from "sanitize-html";
import { OMISSION } from "./constants";

// バインド変数の置換
export const bind = (detail, binds) => {
  if (!binds) return detail;
  let tmpDetail = _.cloneDeep(detail);
  _.forEach(binds, function(value, key) {
    tmpDetail = tmpDetail.replace(key, sanitizeHTML(value));
  });
  return tmpDetail;
};

/**
 * バインド変数の置換(ネスト構造に対応)
 * ・置換対象(details)がMap構造であること
 * ・detailsのkeyがbindsのkeyに対応している場合、置換可能
 **/
export const bindNest = (details, binds) => {
  if (!binds) return details;
  let tmpDetails = _.cloneDeep(details);
  _.forEach(tmpDetails, function(value, key) {
    let _bind = binds[key];
    if (_.isNil(_bind)) return;
    tmpDetails[key] = bind(value, _bind);
  });
  return tmpDetails;
};

// 名称省略対象確認
export const isTextOmission = (text, upperLimit) => {
  if (!upperLimit || !text) return false;
  return text.length > upperLimit;
};

// 名称省略
export const getTextOmission = (text, upperLimit) => {
  if (!text) return "";
  if (!upperLimit) return text;

  var textOmission = isTextOmission(text, upperLimit)
    ? text.slice(0, upperLimit - 1) + OMISSION
    : text;
  return textOmission;
};
