import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { WebIO } from '@gltf-transform/core';
import { KHRONOS_EXTENSIONS } from '@gltf-transform/extensions';
import { metalRough } from '@gltf-transform/functions';

export class GLTFLoaderEx extends GLTFLoader {

  async load(url, onLoad, onProgress, onError) {

    // Load model in glTF Transform.
    const io = new WebIO().registerExtensions(KHRONOS_EXTENSIONS);
    const document = await io.read(url);

    // Convert materials.
    await document.transform(metalRough());

    // Write back to GLB.
    const glb = await io.writeBinary(document);

    this.parse(glb.buffer, '', (gltf) => {
      gltf.scene.animations = gltf.animations;
      onLoad(gltf.scene);
    }, onError);

  }

}
