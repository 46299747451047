import * as _ from "lodash";
import api from "@/api/api.js";

export const CaptchaPointApi = {
  namespaced: true,

  state: {
    zumen_id: null,
    captcha_point_id: null,
    captchaPoint: {},
    captchaPoints: {},
    cpAroundObjects: {},
    bpLinkageCp: {}
  },
  mutations: {
    SET_ZUMEN_ID(state, payload) {
      state.zumen_id = payload;
    },
    SET_CAPTCHA_POINT_ID(state, payload) {
      state.captcha_point_id = payload;
    },
    GET_CAPTCHAPOINTS(state, payload) {
      state.captchaPoints = _.cloneDeep(payload);
    },
    GET_CAPTCHAPOINT(state, payload) {
      state.captchaPoint = _.cloneDeep(payload);
    },
    UPDATE_CAPTCHAPOINT(state, payload) {
      let captchaPoint = state.captchaPoint;
      const around_photographs = captchaPoint.around_photographs.map(
        (graph) => {
          if (graph.id === payload.id) {
            return _.assign(graph, payload);
          }
          return graph;
        }
      );
      console.log("UPDATE", payload, state.captchaPoint, captchaPoint);
      captchaPoint.around_photographs = around_photographs;
      state.captchaPoint = captchaPoint;
    },
    GET_BPLINKAGECP(state, payload) {
      state.bpLinkageCp = _.cloneDeep(payload);
    }
  },
  actions: {
    async postCaptchaPoint(context, payload) {
      const service = "postCaptchaPoint";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status === 200) {
        res["data"] = response.data.data;
        context.commit("SET_ZUMEN_ID", payload.zumen_id);
        context.commit("GET_CAPTCHAPOINTS", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async patchCaptchaPoint(context, payload) {
      const service = "patchCaptchaPoint";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.patch(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("SET_ZUMEN_ID", payload.zumen_id);
        context.commit("GET_CAPTCHAPOINTS", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async deleteCaptchaPoint(context, payload) {
      const service = "deleteCaptchaPoint";
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.delete(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("GET_CAPTCHAPOINTS", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async getCaptchaPoints(context, payload) {
      const service = "getCaptchaPoints";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("GET_CAPTCHAPOINTS", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    //撮影ポイント取得
    async getCaptchaPoint(context, payload) {
      const service = "getCaptchaPoint";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("SET_ZUMEN_ID", payload.zumen_id);
        context.commit("SET_CAPTCHA_POINT_ID", payload.captcha_point_id);
        context.commit("GET_CAPTCHAPOINT", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },

    /**
     * 撮影ポイントを更新 = 情報を追加、削除したあとに呼び出す
     * @param {*} context
     * @param {*} payload {api_token}
     */
    async updateCaptchaPoint(context, payload) {
      const service = "getCaptchaPoint";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;

      //ステートで保持している現在のidを取得
      const param = {
        zumen_id: context.state.zumen_id,
        captcha_point_id: context.state.captcha_point_id,
      };
      console.log("- API:", service, " prm:", param);

      let response = await api.get(service, param, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
        // context.commit("GET_CAPTCHAPOINT", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    async getCpAroundPhotograph(context, payload) {
      const service = "getCpAroundPhotograph";
      console.log("- API:", service, " prm:", payload);

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };
      if (response.status == 200) {
        res["data"] = response.data.data;
      } else {
        res["data"] = response.data;
      }
      console.log("- API:", service, "res:", res);
      return res;
    },
    /**
     * 写真の追加
     * @returns {Promise<void>}
     */
    async postCpAroundPhotograph(context, payload) {
      const service = "postCpAroundPhotograph";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;

      let response = await api.post(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
        // context.commit("GET_CAPTCHAPOINT", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    //写真の論理削除
    async patchCpAroundPhotograph(context, payload) {
      const service = "patchCpAroundPhotograph";

      console.log("- API:", service, " prm:", payload);
      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;

      let response = await api.patch(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    },
    //
    async getBpLinkageCp(context, payload) {
      const service = "getBpLinkageCp";

      const apiToken = _.cloneDeep(payload["api_token"]);
      delete payload.api_token;
      console.log("- API:", service, " prm:", payload);

      let response = await api.get(service, payload, apiToken);
      let res = {
        result: response.result,
        status: response.status,
        statusText: response.statusText,
      };

      if (response.status == 200) {
        res["data"] = response.data.data;
        context.commit("GET_BPLINKAGECP", response.data.data);
      } else {
        res["data"] = response.data;
      }

      console.log("- API:", service, "res:", res);
      return res;
    }
  },
  getters: {
    getState: (state) => {
      return state;
    },
    getStateCaptchaPoints: (state) => {
      return state.captchaPoints;
    },
    getStateCaptchaPoint: (state) => {
      return state.captchaPoint;
    },
    getBpLinkageCp: (state) => {
      return state.bpLinkageCp;
    }
  },
};
