<template>
  <div class="popup-txt">
    <div v-show="showFlg" id="input-popup" ref="input-popup" class="background">
      <div class="popup">
        <div class="header">
          <div class="close-btn_wrapper">
            <TooltipBtn label="閉じる">
              <div :id="html.ID_CLOSE" class="close-btn">
                <img class="close-btn_image" :src="icon.close" alt="close" />
              </div>
            </TooltipBtn>
          </div>
        </div>
        <div class="inner">
          <div class="inner-txt">{{ items.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { close, download, file } from "@/util/icon.js";
import TooltipBtn from "@/components/tooltip/TooltipBtn.vue";

export default {
  components: { TooltipBtn },
  data: () => {
    return {
      icon: { close, download, file },
      DEF_ITEMS: {
        value: "",
        file: null,
        id: null,
        point_x: null,
        point_y: null,
        point_z: null,
      },
      items: {
        value: "",
        file: null,
        id: null,
        point_x: null,
        point_y: null,
        point_z: null,
      },
      showFlg: false, // モーダル表示
      html: {
        ID_CLOSE: "PopupTxtBtnClose",
      },
    };
  },
  props: {},
  mounted() {},
  methods: {
    init() {
      this.items = _.cloneDeep(this.DEF_ITEMS);
      this.showFlg = false;
    },
    async call(items) {
      // 定数、および変数の置換
      let res = { result: false };
      if (!this.vaidReplaceItems(items)) return res;
      this.showFlg = true;

      // ポップアップのボタンが押下されるまで待機
      const awaitForClick = () => {
        return new Promise((resolve) => {
          const listener = resolve;

          let cancel = document.getElementById(this.html.ID_CLOSE);
          cancel.addEventListener("click", listener, { once: true });
        });
      };
      await awaitForClick();
      this.init();

      return res;
    },
    // 定数の置換
    vaidReplaceItems(items) {
      if (!this.isNonDiffKeys(items)) return false;
      this.items = items;
      return true;
    },
    isNonDiffKeys(items) {
      if (_.isNil(items)) return false;
      const defKeys = Object.keys(this.items);
      const originKeys = Object.keys(items);
      return _.xor(defKeys, originKeys).length == 0;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: false,
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.isValid();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/config.scss";

.popup-txt {
  .background {
    display: flex;
    justify-content: center;
    position: fixed;
    align-items: center;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    .popup {
      min-width: 600px;
      padding: $padding-20 0 $padding-40;
      background: white;
      border-radius: 4px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.8);
      .header {
        .close-btn_wrapper {
          margin: 0 0 0 auto;
          width: 60px;
          .close-btn {
            border: none;
            height: 22px;
            padding-top: $padding-10;
            .close-btn_image {
              width: 16px;
              height: auto;
              filter: invert(1) sepia(1) saturate(1) hue-rotate(50deg);
            }
          }
        }
      }
      .inner {
        padding: $padding-20 0;
        .inner-file {
          display: flex;
          justify-content: space-around;

          color: $color-base-50;
          .file {
            // margin-bottom: $margin-5;
            .image {
              min-width: 640px;
              max-width: 640px;
              height: auto;
            }
            .def-file_wrapper {
              display: flex;
              .def-file_block {
                margin: 2px $margin-10 0;
                .def-file {
                  width: 16px;
                  height: auto;
                  filter: invert(1) sepia(1) saturate(1) hue-rotate(50deg);
                }
              }
            }
          }
        }
        .file_download {
          width: 16px;
          height: auto;
          padding: $padding-10;
          filter: invert(1) sepia(1) saturate(1) hue-rotate(50deg);
        }
        .inner-txt {
          text-align: left;
          padding: $padding-10 $padding-20;
          color: $color-base-50;
          font-size: $font-size-30;
          font-weight: bold;
          line-height: 1.4;
          word-break: break-all;
        }
      }
    }
  }

  .editable-txt {
    .editable-txt__input {
      color: white;
      font-size: $font-size-30;
      padding: $padding-5 $padding-20;
      background-color: #333;
      width: 100%;
      box-sizing: border-box;
      border: none;
      outline: none;
    }
    .editable-txt__message {
      padding: $padding-5 $padding-20;
      width: 100%;
      color: red;
      font-size: $font-size-20;
      text-align: center;
    }
    .editable-txt__navi {
      display: flex;
      justify-content: space-between;
      padding: $padding-10;
    }
  }
}
</style>
