import { FLOOR_TYPE } from "@/util/constants";

/**
 * 360°画像に配置する画像データを保持する
 */
export const PanelFileUploads = {
  namespaced: true,
  state: {
    status: null,
    files: null,
    position: null,
    side: FLOOR_TYPE.FLOOR1, //分割の左右どちらか
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status = payload;
    },
    SET_FILES(state, payload) {
      state.files = payload;
    },
    SET_POSITION(state, payload) {
      state.position = payload;
    },
    SET_SIDE(state, payload) {
      state.side = payload;
    },
    RESET(state) {
      state.status = null;
      state.files = null;
      state.position = null;
      state.side = FLOOR_TYPE.FLOOR1;
    },
  },
  actions: {
    setStatus(context, payload) {
      context.commit("SET_STATUS", payload);
    },
    setFiles(context, payload) {
      context.commit("SET_FILES", payload);
    },
    setPanelSide(context, payload) {
      context.commit("SET_SIDE", payload);
    },
    setPosition(context, payload) {
      context.commit("SET_POSITION", payload);
    },
    reset(context) {
      context.commit("RESET");
    },
  },
  getters: {
    getState: (state) => {
      return state;
    },
  },
};
