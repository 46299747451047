<template>
  <div class="imageViewer" :class="isShow ? 'enable' : null">
    <div class="background" v-if="isShow" />
    <div ref="imageView" class="imageView" :class="showin ? 'show' : null">
      <div class="header">
        <div class="filename">
          {{ file_name }}
        </div>
        <!-- 閉じる -->
        <TooltipBtn label="閉じる">
          <button class="close btn" @click="close()">
            <img class="btn_image" :src="icon.close" alt="close" />
          </button>
        </TooltipBtn>
      </div>
      <div class="main">
        <div v-if="loadingFlg" class="loading">
          <vue-loading
            type="spin"
            color="#ccc"
            :size="{ width: '100px', height: '100px' }"
          >
          </vue-loading>
        </div>
        <!-- 複数画像 -->
        <div v-else class="image">
          <div
            class="image"
            v-if="
              files[file_index] &&
                files[file_index].type === POST_IMAGE_TYPE.JPG.value
            "
          >
            <img :src="files[file_index].src" @load="onLoadEmbed" />
          </div>
          <div
            class="pdf"
            v-if="
              isShowPDF(files[file_index]) &&
                files[file_index] &&
                files[file_index].type === POST_IMAGE_TYPE.PDF.value
            "
          >
            <embed
              class="pdf-file"
              :src="files[file_index].src"
              @load="onLoadEmbed"
            />
          </div>
          <div
            class="pdf-msg"
            v-if="
              files[file_index] &&
                files[file_index].type === POST_IMAGE_TYPE.PDF.value &&
                !isShowPDF(files[file_index])
            "
          >
            {{ onLoadEmbed() }}
            <div class="description">
              ファイルサイズが大きすぎるため、プレビューできません<br />
              ファイルをダウンロードしてご確認ください
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <!-- prev -->
        <TooltipBtn label="前">
          <button
            class="prev btn"
            :class="getLeftArrowClass"
            @click="prev"
            :disabled="disableClick"
          >
            <img
              class="btn_image"
              src="@/assets/img/icon-arrow-left.png"
              alt="prev"
            />
          </button>
        </TooltipBtn>
        <!-- 画像ダウンロード -->
        <TooltipBtn label="ダウンロード">
          <a
            v-if="
              files[file_index] &&
                files[file_index].type === POST_IMAGE_TYPE.JPG.value
            "
            :href="files[file_index].src"
            download="image.jpg"
            class="download btn"
          >
            <img class="btn_image" :src="icon.download" alt="download" />
          </a>
        </TooltipBtn>
        <!-- PDF ダウンロード -->
        <TooltipBtn label="ダウンロード">
          <a
            v-if="
              files[file_index] &&
                files[file_index].type === POST_IMAGE_TYPE.PDF.value
            "
            :href="files[file_index].src"
            download="doc.pdf"
            class="download btn"
          >
            <img class="btn_image" :src="icon.download" alt="download" />
          </a>
        </TooltipBtn>
        <!-- 削除 -->
        <TooltipBtn label="削除">
          <button class="delete btn" @click="del">
            <img
              class="btn_image"
              src="@/assets/img/icon-panel-delete.png"
              alt="delete"
            />
          </button>
        </TooltipBtn>
        <!-- next -->
        <TooltipBtn label="次">
          <button
            class="next btn"
            :class="getRightArrowClass"
            @click="next"
            :disabled="disableClick"
          >
            <img
              class="btn_image"
              src="@/assets/img/icon-arrow-right.png"
              alt="next"
            />
          </button>
        </TooltipBtn>
      </div>
    </div>
  </div>
</template>
<script>

/**
 * this.$store.FileViewerで操作
 *
 * 呼び出し方
 * this.$store.dispatch("FileViewer/showImage",{image_type:string,image_id:string});
 *
 * 閉じ方
 * this.$store.dispatch("FileViewer/close");
 */

import {VueLoading} from "vue-loading-template";
import {close, download, file} from "@/util/icon.js";
import {POST_IMAGE_TYPE, VIEW_UPPER_LIMIT_PDF_SIZE,} from "@/util/constants";
import {sleep} from "@/util/sleep.js";
import * as Uobj from "@/util/obj.js";
import TooltipBtn from "../tooltip/TooltipBtn.vue";

export default {
  data: () => {
    return {
      icon: { close, download, file },
      showin: false,
      disableClick: false,
      src: null,
      type: null,
      ap_file_id: null,
      file_id: null,
      file_name: "",
      POST_IMAGE_TYPE,
      loadingFlg: true,
      files: [], //複数ファイル
      file_index: 0, //選択指定ファイルのindex
    };
  },
  components: {
    VueLoading,
    TooltipBtn,
  },
  watch: {
    deep: true,
    isShow: {
      handler(nval, oval) {
        if (nval !== oval && nval) {
          this.disableClick = true;
          this.file_index = 0;
          this.files = [{ src: null }]; //embed.srcをクリアする
          setTimeout(() => {
            this.showin = true;
            this.bindOutsideClickEvent();
          }, 300);
        }
        if (nval !== oval && !nval) {
          this.files = [{ src: null }]; //embed.srcをクリアする
          this.removeOutsideClickEvent();
          this.showin = false;
          this.file_index = 0;
        }
      },
    },
    getState: {
      deep: true,
      async handler(nval) {
        //複数ファイル
        if (nval.files.length) {
          let timeoutFlg = false;
          const files = await Promise.all(
            nval.files
              .map(async (file) => {
                let cnt = 0;
                // 1分以内で表示できない場合はプレビューを諦める
                const getSrc = async (file_id) => {
                  if (!file_id) return null;
                  let tmpSrc = this.$store.getters["ApFile/getFileFromFileId"](
                    file_id
                  );
                  if (tmpSrc) {
                    return tmpSrc;
                  } else if (cnt >= 60) {
                    return null;
                  } else {
                    cnt++;
                    console.log("- getState getSrc cnt", cnt);
                    await sleep(1000);
                    return getSrc(file_id);
                  }
                };
                let src = await getSrc(file.file_id);
                if (!src) {
                  timeoutFlg = true;
                  return null;
                }
                return {
                  src: src.image,
                  type: file.image_type,
                  file_id: file.file_id,
                  ap_file_id: file.ap_file_id,
                  size: file.size,
                };
              })
              .filter((v) => v)
          );

          if (timeoutFlg) {
            alert(
              "回線速度が低下しているため、ファイルが取得できませんでした。時間をおいて再度お試しください"
            );
            this.close();
          } else {
            this.loadingFlg = false;
            this.files = files;
          }
        }
      },
    },
  },
  computed: {
    isShow() {
      return this.$store.getters["FileViewer/getIsShow"];
    },
    getState() {
      return this.$store.getters["FileViewer/getState"];
    },
    getRightArrowClass() {
      return this.files.length === 1
          ? "hide"
          : this.files.length - 1 === this.file_index
              ? "disabled"
              : "undisabled";
    },
    getLeftArrowClass() {
      return this.files.length === 1
          ? "hide"
          : this.file_index === 0
              ? "disabled"
              : "undisabled";
    },
  },
  methods: {
    onLoadEmbed() {
      //ロード完了して描画が終わるまで待つ必要があるが、
      //描画の完了はイベントで取れないので
      //一定時間たったら状態変更する
      setTimeout(() => {
        this.disableClick = false;
      }, 1500);
    },
    close() {
      this.$store.dispatch("FileViewer/close");
    },
    prev() {
      this.file_index = this.file_index === 0 ? 0 : this.file_index - 1;
      const files = [...this.files];
      this.files = [{ src: null }];
      setTimeout(() => {
        this.files = files;
        this.disableClick = this.file_index === 0;
        console.log(
          "- prev files.length - 1",
          this.files.length - 1,
          "file_index",
          this.file_index,
          "this.disableClick",
          this.disableClick
        );
      }, 10);
    },
    next() {
      this.file_index =
        this.files.length - 1 === this.file_index
          ? this.files.length - 1
          : this.file_index + 1;
      const files = [...this.files];
      this.files = [{ src: null }];
      setTimeout(() => {
        this.files = files;
        this.disableClick =
          this.file_index === this.files.length - 1 ? true : false;
        console.log(
          "- next files.length - 1",
          this.files.length - 1,
          "file_index",
          this.file_index,
          "this.disableClick",
          this.disableClick
        );
      }, 10);
    },
    async del() {
      //DELETE api/deleteApFile
      if (window.confirm("削除します。この操作は取り消せません。")) {
        this.$store.dispatch("Indicater/start");
        const api_token = this.$store.getters[`LoginApi/getApiToken`];
        const cp_around_photograph_id = this.getState.cp_around_photograph_id;
        const result = await this.$store.dispatch("ApFile/deleteApFile", {
          api_token,
          cp_around_photograph_id,
          ap_file_id: this.files[this.file_index].ap_file_id,
        });

        if (result.result) {
          const { zumen_id, captcha_point_id } = this.$store.getters[
            "CaptchaPointApi/getState"
          ];
          await this.$store.dispatch("CaptchaPointApi/getCaptchaPoint", {
            api_token,
            zumen_id,
            captcha_point_id,
          });
          this.close();
          await this.$store.dispatch("Indicater/stop");
        } else {
          await this.$store.dispatch("Indicater/stop");
          alert("削除できませんでした。");
        }
      }
    },
    isShowPDF(file) {
      let fileSize = Uobj.getNest(file, "size");
      if (
        fileSize &&
        fileSize > 0 &&
        fileSize <= VIEW_UPPER_LIMIT_PDF_SIZE * 1000
      ) {
        return true;
      } else {
        return false;
      }
    },

    bindOutsideClickEvent() {
      document.body.addEventListener("click", this.hitArea);
    },
    removeOutsideClickEvent() {
      document.body.removeEventListener("click", this.hitArea);
    },
    hitArea(e) {
      const pointerPosition = {
        x: e.clientX,
        y: e.clientY,
      };
      const rect = this.$refs["imageView"].getBoundingClientRect();
      if (
        !(
          rect.left < pointerPosition.x &&
          rect.top < pointerPosition.y &&
          rect.left + rect.width > pointerPosition.x &&
          rect.top + rect.height > pointerPosition.y
        )
      ) {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/config.scss";

.imageViewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: none;

  .background {
    pointer-events: none;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.enable {
    pointer-events: all;
    .background {
      pointer-events: all;
    }
  }
}

.imageView {
  position: absolute;
  z-index: 9999;
  height: 80%;
  width: 80%;
  background-color: black;
  transition: all 0.2s ease-out;
  transform: translateY(-100vh);
  overflow: hidden;

  &.show {
    transform: translateY(0);
  }

  .header {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filename {
      font-size: 14px;
      color: white;
      text-align: left;
      padding: 4px 8px;
    }
  }
  .main {
    height: calc(100% - 128px);
    background-color: white;
    overflow: hidden;
    .loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-base-00;
    }
    .image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
      overflow-x: auto;
      .image_inner {
        display: flex;
        height: 100%;
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    .pdf {
      width: 100%;
      height: 100%;

      .pdf-file {
        width: 100%;
        height: 100%;
      }
    }
    .pdf-msg {
      width: 100%;
      height: 100%;
      font-size: $font-size-40;
      background-color: $color-base-00;
      color: $color-base-50;
      position: relative;
      .description {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit-transform: translateY(-50%) translateX(-50%);
      }
    }
  }
  .footer {
    height: 64px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.btn {
  border: none;
  height: 22px;
  background: none;
  outline: none;
  margin: 8px;
  &:hover {
    cursor: pointer;
  }
  .btn_image {
    width: 16px;
    height: auto;
    filter: invert(1) sepia(1) saturate(1) hue-rotate(50deg);
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.hide {
    pointer-events: none;
    opacity: 0;
  }
}
</style>
