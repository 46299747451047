<template>
  <div class="picture-datetimepicker">
    <Datepicker
      v-model="day"
      format="yyyy/MM/dd"
      :language="lang"
      :highlighted="highlighted"
      input-class="datetimepicker__input"

    />
    <div class="spacer"></div>
    <select class="timepicker" v-model="selectedId">
      <option v-for="item in highlighted.times" :value="item.id">
        {{ getTime(item[dateColumn]) }}
      </option>
    </select>
  </div>
</template>
<script>

import Datepicker from "vuejs-datepicker";
import {ja} from "vuejs-datepicker/dist/locale";
import {format, getHours, getMinutes} from "date-fns";
import * as _ from "lodash";

export default {
  model: {
    prop: 'item_index',
    event: 'change'
  },
  data: () => {
    return {
      lang: ja,
      // day: "-----",
      highlighted: {
        dates: [],
        times: [],
      },
    };
  },
  components: {
    Datepicker,
  },
  props: {
    items: {
      type: Array,
    },
    item_index: {
      type: Number,
    },
    dateColumn: {
      type: String,
      defaultValue: "display_date",
    },
  },
  computed: {
    selectedId: {
      get() {
        const { item_index, items } = this;
        if (items && !_.isNil(item_index) && 0 <= item_index && item_index < items.length) {
          return items[item_index].id;
        } else {
          return null;
        }
      },
      set(value) {
        const { items } = this;
        const index = _.findIndex(items, { 'id': value });
console.log("item_index changed.  " + index);
        this.$emit("change", index);
      },
    },
    day: {
      get() {
console.log('day getter : this.selectedId' + this.selectedId);
        return (_.find(this.items, { 'id': this.selectedId })
            || this.items[0] || { }) [this.dateColumn];
      },
      set(value) {
console.log('day setter : set day ' + value);
        this.onSelectDay(value);
        if (this.highlighted.times.length) {
          this.selectedId = this.highlighted.times[0].id;
        }
      }
    },
  },
  watch: {
    /**
     * 日時の配列
     */
    items(newVal) {

      this.sortitems(newVal);

    },
    /**
     * 選択時
     */
    item_index() {

      this.onSelectDay(this.day);

    }
  },
  methods: {
    sortitems(items) {
      const _items = items.filter(day => {
        return !_.isNil(day[this.dateColumn])
      }).map(day => {
        return new Date(day[this.dateColumn])
      });
      this.highlighted.dates = _items;

      const selectedId = this.selectedId;
      // if (selectedId === null) {
      //   this.day = _items[0] || null;
      // }
    },
    getTime(date) {
      const h = getHours(new Date(date));
      const m = getMinutes(new Date(date));
      return `${("0" + h).slice(-2)}:${("0" + m).slice(-2)}`;
    },
    /**
     * 日付選択時、時刻リストを作成
     * @param selectedDay
     */
    onSelectDay(selectedDay) {
      // 日付でフィルタ
      this.highlighted.times = this.items.filter((day) => {
        const td = format(new Date(day[this.dateColumn]), "yyyy-MM-dd");
        const sd = format(new Date(selectedDay), "yyyy-MM-dd");
        return !_.isNil(day[this.dateColumn]) && td === sd;
      });
    },
  },
};
</script>
<style lang="scss">
.picture-datetimepicker {
  position: relative;
  color: black;
  display: inline-flex;
  align-items: center;

  .timepicker {
    background-color: white!important;
    appearance: none;
    border: none;
    min-width: 72px;
    height: 22px;
    border-radius: 8px;
    font-size: 16px;
    padding: 0 8px;
    margin: 1px 2px;
    outline: none;
    color: #666;
    text-align:-webkit-center;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  }

  .vdp-datepicker {
    height: 22px;
    margin: 1px 2px;
  }

  .vdp-datepicker__calendar {
    bottom: 24px;
    left: 0;
    width: 220px !important;
  }

  .datetimepicker__input {
    background-color: white!important;
    border: none;
    max-width: 96px;
    height: 22px;
    border-radius: 8px;
    font-size: 16px;
    padding: 3px 6px 1px 6px;
    margin: 0 1px;
    outline: none;
    color: #666;
    text-align:-webkit-center;
    line-height: 1.2;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  }

  .placeholder {
    font-size: 8px;
  }

  .spacer {
    display: inline-block;
    width: 8px;
  }
}
</style>
