import * as _ from "lodash";
import * as EXIF from "exif-js";
import { getPoint, aclPath } from "@/api/api";
import { getURLParams, asyncApi, apiCache } from "@/api/apiCache";

export const loadImage = async (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => {
      console.log(e);
      reject(e);
    }
    img.src = src;
  });
};

export const isWidthGtHeight = async (src, limitWidth, limitHeight) => {
  try {
    const res = await loadImage(src);
    if (!_.isNil(limitWidth) && !_.isNil(limitHeight)) {
      // サイズの上限値を設定する場合、
      // 等倍した際に上限値を超えないかチェックする
      let raito = limitWidth / res.width;
      let raitoUpHeight = res.height * raito;
      return limitHeight > raitoUpHeight;
    } else {
      // サイズの上限値を設定しない場合、
      // オリジナルの画像サイズでサイズを比較する
      return res.width > res.height;
    }
  } catch (e) {
    return true;
  }
};

// base64 -> ArrayBuffer
export const base64ToArrayBuffer = async (base64) => {
  let _base64 = base64.replace(/^data:([^;]+);base64,/gim, "");
  const binaryString = atob(_base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

/* Exif：方位取得
 * 方位(GPSImgDirection)が取得できなかった場合、0を返す
 */
export const exifGPSImgDirection = async (base64) => {
  if (!base64) return 0;
  const arrayBuffer = await base64ToArrayBuffer(base64);
  const exif = EXIF.readFromBinaryFile(arrayBuffer);
  return exif && exif.GPSImgDirection
    ? exif.GPSImgDirection.numerator / exif.GPSImgDirection.denominator
    : 0;
};

export const _get360ImageLoader = () => {
  let callbacks = [];
  let progress = 0;
  let total = 0;
  const END_POINT = getPoint(document.domain, `80`) + "/api/";

  const setCallback = (callback) => {
    callbacks.push(callback);
  };

  const xhrRequest = ({ params }) => {
    return new Promise((resolved) => {
      let path = aclPath(params);
      let url = new URL(`${END_POINT}${path}get360Image`);
      url.searchParams.set("file_id", params.file_id);

      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", params.api_token);
      xhr.setRequestHeader("Accept", "application/json");

      //完了
      // eslint-disable-next-line no-unused-vars
      xhr.onload = function(e) {};
      //ロード中
      xhr.onprogress = (e) => {
        progress = Math.round(e.loaded / total * 100);
        callbacks.forEach((callback) => {
          callback(progress);
        });
      };
      //開始
      xhr.onloadstart = () => {
        progress = 0;
      };
      //ステータス変更
      xhr.onreadystatechange = () => {
        total = +xhr.getResponseHeader("File-Size");
        if (xhr.readyState === 4) {
          callbacks.forEach((callback) => {
            callback(100);
          });
          const result = {
            result: xhr.status === 200,
            status: xhr.status,
            statusText: xhr.statusText,
            data: JSON.parse(xhr.response),
          };
          resolved(result);
        }
      };
      xhr.send();
    });
  };

  const load = async ({ params, callback }) => {

    if (callback) callbacks.push(callback);

    //キャッシュがあれば返す
    let path = aclPath(params);
    let isCache = await apiCache.get(`${END_POINT}${path}get360Image`, params, 300);
    if (isCache) {
      return isCache.response;
    }

    //ロード中
    const URL = `${END_POINT}${path}get360Image`;
    const key = URL.concat(`?${getURLParams(params)}`);

    let response;
    if (
      asyncApi.isLoading({
        id: key,
      }) ||
      asyncApi.isLoaded({
        id: key,
      })
    ) {
      //ロード中は完了までまつ
      response = await asyncApi.getResponse({ id: key });
    } else {
      //ロード開始
      asyncApi.setLoading({
        id: key,
      });
      response = await xhrRequest({
        params,
      });
      //成功したらキャッシュに追加
      if (response.result) {
        //キャッシュに追加
        apiCache.set(URL, _.cloneDeep(response), params);
      }
      asyncApi.update({
        id: key,
        res: response,
      });
    }
    return response;
  };
  return {
    load,
    setCallback,
  };
};

export const get360ImageLoader = _get360ImageLoader();
